import { Validators } from '@angular/forms';
import { RealEstateType } from '@ntag-ef/finprocess-enums/finadvisory';

import { HelperService } from '../services';

import { IValidationMap } from './../interfaces/validation-map.interface';
import { IRealEstateModel } from './../models/real-estate.model';
import { RealEstateState } from './../statemanagement/realestate/realestate.state';
import { GlobalSettings } from './../utils/global-settings';
import { DateValidation } from './date.validation';
import { NumberValidation } from './number.validation';
import { PhoneNumberValidation } from './phone.validation';

interface IAreaRange {
    min: number,
    max: number,
}

interface IAreaMap {
    type: RealEstateType,
    baseArea?: IAreaRange,
    livingArea?: IAreaRange,
}

/**
 * Prüfung ob der Wohnbereich gültig ist
 *
 * @param { IRealEstateModel } realestate  RealEstate Model
 * @returns { boolean }  true wenn Wohnbereich gültig ist
 */
export const isLivingAreaValid = (realestate: IRealEstateModel): { valid: boolean, max?: number, min?: number } => {
    if (areaMap.some(({ type }) => type === realestate.type)) {
        const area = areaMap.find(({ type }) => type === realestate.type);
        if (!!area?.livingArea) {
            const areasum =
                HelperService.getValue(realestate.netRoomArea, 0) +
                HelperService.getValue(realestate.areaBasement, 0) +
                HelperService.getValue(realestate.areaGroundFloor, 0) +
                HelperService.getValue(realestate.areaUpperFloor, 0) +
                HelperService.getValue(realestate.areaAdditionalUpperFloor, 0) +
                HelperService.getValue(realestate.areaTopFloor, 0);

            return {
                valid: (areasum >= area.livingArea.min && areasum <= area.livingArea.max),
                max: area.livingArea.max,
                min: area.livingArea.min,
            };
        }
    }
    
    return {
        valid: true,
    };
};

// see http://ntag-jira:8090/display/BAFAPP/LIEBE+Light+Anbindung
const areaMap: IAreaMap[] = [
    {
        type: RealEstateType.Flat,
        livingArea: { min: 20, max: 250 },
    },
    {
        type: RealEstateType.DetachedHouse,
        baseArea: { min: 25, max: 2500 },
        livingArea: { min: 20, max: 500 },
    },
    {
        type: RealEstateType.RowHouse,
        baseArea: { min: 25, max: 1500 },
        livingArea: { min: 20, max: 350 },
    },
    {
        type: RealEstateType.SemiDetachedHouse,
        baseArea: { min: 25, max: 1500 },
        livingArea: { min: 20, max: 350 },
    },
    {
        type: RealEstateType.Lot,
        baseArea: { min: 50, max: 2500 },
    },
    {
        type: RealEstateType.Other,
        livingArea: { min: 20, max: 400 },
    },
]

export const REALESTATE_FORM_VALIDATION_MAP: IValidationMap = {
    realEstateModel: {
        id: {
            required: () => true,
            baseValidator: () => [],
        },
        financingMapId: {
            required: () => true,
            baseValidator: () => [],
        },
        name: {
            required: () => false,
            baseValidator: () => [Validators.maxLength(60)],
        },
        type: {
            required: () => true,
            baseValidator: () => [],
        },
        objectPurpose: {
            required: () => true,
            baseValidator: () => [],
        },
        collateralization: {
            required: (realEstate: IRealEstateModel) => {
                const map = RealEstateState.visibleMap(realEstate);
                return !!map && map.fieldCollateralization;
            },
            baseValidator: () => [],
        },
        willBeRentedOut: {
            required: (realEstate: IRealEstateModel) => {
                const map = RealEstateState.visibleMap(realEstate);
                return !!map && map.fieldWillBeRentedOut;
            },
            baseValidator: () => [],
        },
        landRegisterNumber: {
            required: () => false,
            baseValidator: () => [Validators.maxLength(5)],
        },
        propertyRegisterNumber: {
            required: () => false,
            baseValidator: () => [Validators.minLength(1), Validators.maxLength(5)],
        },
        street: {
            baseValidator: () => [Validators.minLength(3), Validators.maxLength(60)],
            required: () => true,
        },
        streetNumber: {
            baseValidator: () => [Validators.minLength(1), Validators.maxLength(10)],
            required: () => true,
        },
        zip: {
            baseValidator: () => [Validators.minLength(4), Validators.maxLength(4), Validators.pattern(/^\d*$/)],
            required: () => true,
        },
        city: {
            baseValidator: () => [Validators.maxLength(256)],
            required: () => true,
        },
        stairway: {
            baseValidator: () => [],
            required: (realEstate: IRealEstateModel) => {
                const map = RealEstateState.visibleMap(realEstate);
                return !!map && map.isTypeFlat;
            },
        },
        top: {
            baseValidator: () => [],
            required: (realEstate: IRealEstateModel) => {
                const map = RealEstateState.visibleMap(realEstate);
                return !!map && map.isTypeFlat;
            },
        },
        validatedStreet: {
            baseValidator: () => [],
            required: () => false,
        },
        validatedStreetNumber: {
            baseValidator: () => [],
            required: () => false,
        },
        validatedZip: {
            baseValidator: () => [],
            required: () => false,
        },
        validatedCity: {
            baseValidator: () => [],
            required: () => false,
        },
        roofConstruction: {
            baseValidator: () => [],
            required: (realEstate: IRealEstateModel) => {
                const map = RealEstateState.visibleMap(realEstate);
                return !!map && map.cardRoofConstruction;
            },
        },
        areaUpperFloor: {
            required: () => false,
            baseValidator: () => [Validators.min(0), Validators.max(GlobalSettings.currencyMax)],
        },
        areaAdditionalUpperFloor: {
            required: () => false,
            baseValidator: () => [Validators.min(0), Validators.max(GlobalSettings.currencyMax)],
        },
        areaTopFloor: {
            required: () => false,
            baseValidator: () => [Validators.min(0), Validators.max(GlobalSettings.currencyMax)],
        },
        areaLoggia: {
            required: () => false,
            baseValidator: () => [Validators.min(0), Validators.max(GlobalSettings.currencyMax)],
        },
        areaRoofTerrace: {
            required: () => false,
            baseValidator: () => [Validators.min(0), Validators.max(GlobalSettings.currencyMax)],
        },
        areaBalcony: {
            required: () => false,
            baseValidator: () => [Validators.min(0), Validators.max(GlobalSettings.currencyMax)],
        },
        areaGarden: {
            required: () => false,
            baseValidator: () => [Validators.min(0), Validators.max(GlobalSettings.currencyMax)],
        },
        areaTerrace: {
            required: () => false,
            baseValidator: () => [Validators.min(0), Validators.max(GlobalSettings.currencyMax)],
        },
        areaWinterGarden: {
            required: () => false,
            baseValidator: () => [Validators.min(0), Validators.max(GlobalSettings.currencyMax)],
        },
        areaGarage: {
            required: () => false,
            baseValidator: () => [Validators.min(0), Validators.max(GlobalSettings.currencyMax)],
        },
        areaOutbuildings: {
            required: () => false,
            baseValidator: () => [Validators.min(0), Validators.max(GlobalSettings.currencyMax)],
        },
        netRoomArea: {
            required: (realEstate: IRealEstateModel) => {
                const map = RealEstateState.visibleMap(realEstate);
                return !!map && map.fieldNetRoomArea
            },
            baseValidator: () => [Validators.min(20), Validators.max(250)],
        },
        areaBasement: {
            required: () => false,
            baseValidator: () => [Validators.min(0), Validators.max(GlobalSettings.currencyMax)],
        },
        areaGroundFloor: {
            required: (realEstate: IRealEstateModel) => {
                const map = RealEstateState.visibleMap(realEstate);
                return !!map && map.fieldAreaGroundFloor
            },
            baseValidator: () => [Validators.min(0), Validators.max(GlobalSettings.currencyMax)],
        },
        lotSize: {
            required: (realEstate: IRealEstateModel) => {
                const map = RealEstateState.visibleMap(realEstate);
                return !!map && map.fieldLotSize
            },
            baseValidator: (realEstate: IRealEstateModel) => {
                if (
                    realEstate.type === RealEstateType.DetachedHouse ||
                    realEstate.type === RealEstateType.Other
                ) {
                    return [Validators.min(25), Validators.max(2500)];
                }
                else if (
                    realEstate.type === RealEstateType.RowHouse ||
                    realEstate.type === RealEstateType.SemiDetachedHouse
                ) {
                    return [Validators.min(25), Validators.max(1500)];
                }
                else if (
                    realEstate.type === RealEstateType.Lot
                ) {
                    return [Validators.min(50), Validators.max(2500)];
                }
                else {
                    return [Validators.min(0)];
                }
            },
        },
        energyIndex: {
            required: () => false,
            baseValidator: () => [Validators.minLength(1), Validators.maxLength(3)],
        },
        floor: {
            required: () => false,
            baseValidator: () => [Validators.min(0), Validators.max(99)],
        },
        lotNumber: {
            required: () => false,
            baseValidator: () => [Validators.min(1)],
        },
        underConstruction: {
            required: (realEstate: IRealEstateModel) => {
                const map = RealEstateState.visibleMap(realEstate);
                return !!map && map.isTypeNotLot
            },
            baseValidator: () => [],
        },
        yearOfConstruction: {
            required: (realEstate: IRealEstateModel) => {
                const map = RealEstateState.visibleMap(realEstate);
                return !!map && map.isTypeNotLot && map.fieldYearOfConstruction
            },
            baseValidator: () => [NumberValidation.preciseAmountOfNumbers(4)],
        },
        startOfConstruction: {
            required: (realEstate: IRealEstateModel) => {
                const map = RealEstateState.visibleMap(realEstate);
                return !!map && map.isTypeNotLot && map.fieldStartOfConstruction
            },
            baseValidator: (realEstate: IRealEstateModel) => {
                const validators = [];
                const map = RealEstateState.visibleMap(realEstate);

                if (!!map && map.isTypeNotLot && map.fieldStartOfConstruction) {
                    validators.push(DateValidation.minDate(HelperService.addYears(HelperService.today(), -10)));
                }
                return validators;
            },
        },
        endOfConstruction: {
            required: (realEstate: IRealEstateModel) => {
                const map = RealEstateState.visibleMap(realEstate);
                return !!map && map.isTypeNotLot && map.fieldEndOfConstruction
            },
            baseValidator: (realEstate: IRealEstateModel) => {
                const validators = [];
                const map = RealEstateState.visibleMap(realEstate);

                if (!!map && map.isTypeNotLot && map.fieldEndOfConstruction) {
                    validators.push(DateValidation.minDate((!!realEstate.startOfConstruction && realEstate.startOfConstruction > HelperService.today().toISOString()) ? new Date(realEstate.startOfConstruction) : HelperService.today()));
                    validators.push(DateValidation.maxDate(HelperService.addYears(HelperService.today(), 10)));
                }
                return validators;
            },
        },
        basementUsage: {
            required: (realEstate: IRealEstateModel) => {
                const map = RealEstateState.visibleMap(realEstate);
                return !!map && map.isTypeNotLot && map.cardBasement
            },
            baseValidator: () => [],
        },
        basementConstruction: {
            required: () => false,
            baseValidator: () => [],
        },
        terrace: {
            required: (realEstate: IRealEstateModel) => {
                const map = RealEstateState.visibleMap(realEstate);
                return !!map && map.cardUsabilityLocation && map.fieldTerrace
            },
            baseValidator: () => [],
        },
        wintergardenType: {
            required: (realEstate: IRealEstateModel) => {
                const map = RealEstateState.visibleMap(realEstate);
                return !!map && map.cardUsabilityLocation && map.fieldWintergardenType
            },
            baseValidator: () => [],
        },
        annexType: {
            required: (realEstate: IRealEstateModel) => {
                const map = RealEstateState.visibleMap(realEstate);
                return !!map && map.cardUsabilityLocation && map.fieldAnnexType
            },
            baseValidator: () => [],
        },
        refurbishmentInnerBuildungStructure: {
            required: () => false,
            baseValidator: () => [NumberValidation.preciseAmountOfNumbers(4), Validators.min(1900), Validators.max(2099)],
        },
        refurbishmentRoof: {
            required: () => false,
            baseValidator: () => [NumberValidation.preciseAmountOfNumbers(4), Validators.min(1900), Validators.max(2099)],
        },
        refurbishmentOuterBuildingStructure: {
            required: () => false,
            baseValidator: () => [NumberValidation.preciseAmountOfNumbers(4), Validators.min(1900), Validators.max(2099)],
        },
        refurbishmentCommonArea: {
            required: () => false,
            baseValidator: () => [NumberValidation.preciseAmountOfNumbers(4), Validators.min(1900), Validators.max(2099)],
        },
        refurbishmentWindow: {
            required: () => false,
            baseValidator: () => [NumberValidation.preciseAmountOfNumbers(4), Validators.min(1900), Validators.max(2099)],
        },
        refurbishmentHeating: {
            required: () => false,
            baseValidator: () => [NumberValidation.preciseAmountOfNumbers(4), Validators.min(1900), Validators.max(2099)],
        },
        refurbishmentElectricSanitary: {
            required: () => false,
            baseValidator: () => [NumberValidation.preciseAmountOfNumbers(4), Validators.min(1900), Validators.max(2099)],
        },
        refurbishmentWallpapering: {
            required: () => false,
            baseValidator: () => [NumberValidation.preciseAmountOfNumbers(4), Validators.min(1900), Validators.max(2099)],
        },
        refurbishmentInnerDoorsStairsFloorCovering: {
            required: () => false,
            baseValidator: () => [NumberValidation.preciseAmountOfNumbers(4), Validators.min(1900), Validators.max(2099)],
        },
        garageLocation: {
            required: (realEstate: IRealEstateModel) => {
                const map = RealEstateState.visibleMap(realEstate);
                return !!map && map.fieldGarageLocation
            },
            baseValidator: () => [],
        },
        parkingSpace: {
            required: () => false,
            baseValidator: () => [],
        },
        livingRoomOrientation: {
            required: (realEstate: IRealEstateModel) => {
                const map = RealEstateState.visibleMap(realEstate);
                return !!map && map.fieldLivingRoomOrientation
            },
            baseValidator: () => [],
        },
        flatOrientation: {
            required: (realEstate: IRealEstateModel) => {
                const map = RealEstateState.visibleMap(realEstate);
                return !!map && map.fieldFlatOrientation
            },
            baseValidator: () => [],
        },
        mainBathroomFacilities: {
            required: (realEstate: IRealEstateModel) => {
                const map = RealEstateState.visibleMap(realEstate);
                return !!map && map.cardMainBathroomFacilities
            },
            baseValidator: () => [],
        },
        heatingSystem: {
            required: (realEstate: IRealEstateModel) => {
                const map = RealEstateState.visibleMap(realEstate);
                return !!map && map.isTypeNotLot
            },
            baseValidator: () => [],
        },
        heatingSystemType: {
            required: (realEstate: IRealEstateModel) => {
                const map = RealEstateState.visibleMap(realEstate);
                return !!map && map.isTypeNotLot
            },
            baseValidator: () => [],
        },
        additionalHeatingSystem: {
            required: () => false,
            baseValidator: () => [],
        },
        additionalFacilities: {
            required: () => false,
            baseValidator: () => [],
        },
        outsideFacilities: {
            required: () => false,
            baseValidator: () => [],
        },
        trusteeName: {
            required: HelperService.isTrusteeDirty,
            baseValidator: () => [Validators.maxLength(256)],
        },
        trusteePhoneNumber: {
            required: () => false,
            baseValidator: () => [Validators.minLength(5), Validators.maxLength(60), PhoneNumberValidation.validPhoneFormat],
        },
        trusteeFaxNumber: {
            required: () => false,
            baseValidator: () => [Validators.minLength(5), Validators.maxLength(60)],
        },
        trusteeStreet: {
            required: HelperService.isTrusteeAddressDirty,
            baseValidator: () => [Validators.maxLength(256)],
        },
        trusteeStreetNumber: {
            required: HelperService.isTrusteeAddressDirty,
            baseValidator: () => [Validators.maxLength(10)],
        },
        trusteeZip: {
            required: HelperService.isTrusteeAddressDirty,
            baseValidator: () => [Validators.minLength(4), Validators.maxLength(5), Validators.pattern(/^\d*$/)],
        },
        trusteeCity: {
            required: HelperService.isTrusteeAddressDirty,
            baseValidator: () => [Validators.maxLength(256)],
        },
        bathroomCount: {
            required: (realEstate: IRealEstateModel) => {
                const map = RealEstateState.visibleMap(realEstate);
                return !!map && map.cardMainBathroomFacilities
            },
            baseValidator: () => [Validators.min(1), Validators.max(9)],
        },
        garageFacilities: {
            required: () => false,
            baseValidator: () => [],
        },
        prefabricatedConstruction: {
            required: () => false,
            baseValidator: () => [],
        },
        buildingMaterialType: {
            required: () => false,
            baseValidator: () => [],
        },
        longitude: {
            required: () => true,
            baseValidator: () => [],
            ignoreRequireInCheck: true,
        },
        latitude: {
            required: () => true,
            baseValidator: () => [],
            ignoreRequireInCheck: true,
        },
        marketValue: {
            required: (realEstate: IRealEstateModel, isRealEstateValid: boolean) => {
                const map = RealEstateState.visibleMap(realEstate);
                return !!map && map.fieldManuelMarketValue && isRealEstateValid;
            },
            baseValidator: () => [Validators.min(1), Validators.max(GlobalSettings.currencyMax)],
        },
        marketValueType: {
            required: () => false,
            baseValidator: () => [],
        },
    },
};
