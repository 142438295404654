import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

import { CalculationFactoryService } from '../services/calculation-factory/calculation-factory.service';
import { HelperService } from '../services/helper/helper.service';

/**
 * validator class for date validation
 */
export class DateValidation {

    /**
     * Validierung ob Datum einen Wert nicht unterschreitet
     * 
     * @param {Date} minDate mindest Wert
     * @returns {ValidatorFn} ValidatorFn
     */
    public static minDate(minDate: Date): ValidatorFn {
        return (control: AbstractControl) => {
            const inputDate = HelperService.parseAnyDateStringToDate(control.value);

            if (!!inputDate && minDate.getTime() > inputDate.getTime()) {
                control.setErrors({ min: false }, { emitEvent: false });
                return { min: { min: minDate.toLocaleDateString(), actual: inputDate.toLocaleDateString() } };
            }
            else {
                return null;
            }
        };
    }

    /**
     * Validierung ob Datum einen Wert nicht übersteigt
     * 
     * @param {Date} maxDate maximaler Wert
     * @returns {ValidatorFn} ValidatorFn
     */
    public static maxDate(maxDate: Date): ValidatorFn {
        return (control: AbstractControl) => {
            const inputDate = HelperService.parseAnyDateStringToDate(control.value);

            if (!!inputDate && maxDate.getTime() < inputDate.getTime()) {
                control.setErrors({ max: false }, { emitEvent: false });
                return { max: { max: maxDate.toLocaleDateString(), actual: inputDate.toLocaleDateString() } };
            }
            else {
                return null;
            }
        };
    }

    /**
     * Validierung ob Datum in der Zukunft liegt
     * 
     * @param {AbstractControl} ac zu prüfendes Control
     * @returns {ValidatorFn} ValidatorFn
     */
    public static dateInFuture(ac: AbstractControl): ValidationErrors | null {
        const value: string = ac.value;

        if (value !== undefined && value !== null) {
            const date = HelperService.parseAnyDateStringToDate(value);
            const now = new Date();

            if (!!date && date > now) {
                return { invalidFutureDate: true } as ValidationErrors;
            }
        }
        return null;
    }

    /**
     * Validierung das Datum mind. 18 Jahre in der vergangenheit liegt
     * 
     * @param {AbstractControl} ac zu prüfendes Control
     * @returns {ValidatorFn} ValidatorFn
     */
    public static legalAge(ac: AbstractControl): ValidationErrors | null {
        const value: string = ac.value;

        if (value !== undefined && value !== null) {
            const date = HelperService.parseAnyDateStringToDate(value);
            const service = CalculationFactoryService.debtorCalculationService();

            if (!!date) {
                const age = service.ageDurationEnd({
                    birthday: date,
                    assumedDuration: 0,
                });

                if (age !== undefined && age < 18) {
                    return { invalidAge: true } as ValidationErrors;
                }
            }
        }

        return null;
    }
}
