import { Validators } from '@angular/forms';

import { IValidationMap } from '../interfaces/validation-map.interface';
import { IDebtorModel, ILiabilityModel, INewLiabilityModel } from '../models';
import { HelperService } from '../services';
import { GlobalSettings } from '../utils/global-settings';


export const HOUSEHOLD_FORM_VALIDATION_MAP: IValidationMap = {
    debtorModel: {
        id: {
            required: () => true,
            baseValidator: () => [],
        },
        householdId: {
            required: () => true,
            baseValidator: () => [],
        },
        netIncome: {
            required: () => true,
            baseValidator: () => [Validators.min(0), Validators.max(GlobalSettings.currencyMax)],
        },
        fourteenSalariesPerYear: {
            changeByChange: true,
            required: () => false,
            baseValidator: () => [],
        },
        otherIncome: {
            required: () => false,
            baseValidator: () => [Validators.min(0), Validators.max(GlobalSettings.currencyMax)],
        },
        otherIncomeContainsAlimony: {
            changeByChange: true,
            required: () => false,
            baseValidator: () => [],
        },
        notesIncome: {
            required: (debtor: IDebtorModel) => HelperService.getValue(debtor.otherIncome, 0) > 0,
            baseValidator: () => [],
        },
        notesOtherIncome: {
            required: (debtor: IDebtorModel) => HelperService.getValue(debtor.otherIncome, 0) > 0 && HelperService.getValue(debtor.otherIncomeContainsAlimony, false),
            baseValidator: () => [Validators.maxLength(4000)],
        },
    },
    householdModel: {
        id: {
            required: () => true,
            baseValidator: () => [],
        },
        financingMapId: {
            required: () => true,
            baseValidator: () => [],
        },
        operationalCosts: {
            required: () => true,
            baseValidator: () => [Validators.min(0), Validators.max(GlobalSettings.currencyMax)],
        },
        electricityCosts: {
            required: () => true,
            baseValidator: () => [Validators.min(0), Validators.max(GlobalSettings.currencyMax)],
        },
        phoneCosts: {
            required: () => true,
            baseValidator: () => [Validators.min(0), Validators.max(GlobalSettings.currencyMax)],
        },
        broadcastCosts: {
            required: () => true,
            baseValidator: () => [Validators.min(0), Validators.max(GlobalSettings.currencyMax)],
        },
        carCosts: {
            required: () => true,
            baseValidator: () => [Validators.min(0), Validators.max(GlobalSettings.currencyMax)],
        },
        insuranceCosts: {
            required: () => true,
            baseValidator: () => [Validators.min(0), Validators.max(GlobalSettings.currencyMax)],
        },
        livingCosts: {
            required: () => true,
            baseValidator: () => [Validators.min(0), Validators.max(GlobalSettings.currencyMax)],
        },
        otherCosts: {
            required: () => true,
            baseValidator: () => [Validators.min(0), Validators.max(GlobalSettings.currencyMax)],
        },
        notesHousehold: {
            required: () => false,
            baseValidator: () => [Validators.maxLength(4000)],
        },
        liabilities: {
            required: () => false,
            baseValidator: () => [],
        },
        newLiabilities: {
            required: () => false,
            baseValidator: () => [],
        },
        taxOfficeArrears: {
            required: () => false,
            baseValidator: () => [],
        },
        assets: {
            required: () => false,
            baseValidator: () => [],
        },
    },
    liabilityModel: {
        id: {
            required: () => true,
            baseValidator: () => [],
        },
        householdId: {
            required: () => true,
            baseValidator: () => [],
        },
        ibanCreditor: {
            required: () => false,
            baseValidator: () => [],
        },
        initialAmount: {
            required: (liability: ILiabilityModel) => HelperService.isLiabilityDirty(liability),
            baseValidator: () => [Validators.min(0), Validators.max(GlobalSettings.currencyMax)],
        },
        currentAmount: {
            required: (liability: ILiabilityModel) => HelperService.isLiabilityDirty(liability),
            baseValidator: () => [Validators.min(0), Validators.max(GlobalSettings.currencyMax)],
        },
        loanPeriodInMonths: {
            required: (liability: ILiabilityModel) => HelperService.isLiabilityDirty(liability),
            baseValidator: () => [Validators.min(0), Validators.max(999)],
        },
        started: {
            required: (liability: ILiabilityModel) => HelperService.isLiabilityDirty(liability),
            baseValidator: () => [],
        },
        monthlyRate: {
            required: (liability: ILiabilityModel) => HelperService.isLiabilityDirty(liability),
            baseValidator: () => [Validators.min(0), Validators.max(GlobalSettings.currencyMax)],
        },
        covered: {
            changeByChange: true,
            required: () => false,
            baseValidator: () => [],
        },
        securedByLandRegister: {
            changeByChange: true,
            required: () => false,
            baseValidator: () => [],
        },
        isCorporateCredit: {
            required: () => false,
            baseValidator: () => [],
        },
        securedRealEstateId: {
            required: (liability: ILiabilityModel) => !!liability.securedByLandRegister,
            baseValidator: () => [],
        },
    },
    newLiabilityModel: {
        id: {
            required: () => true,
            baseValidator: () => [],
        },
        householdId: {
            required: () => true,
            baseValidator: () => [],
        },
        creditorName: {
            required: (newLiability: INewLiabilityModel) => HelperService.isNewLiabilityDirty(newLiability),
            baseValidator: () => [],
        },
        amount: {
            required: (newLiability: INewLiabilityModel) => HelperService.isNewLiabilityDirty(newLiability),
            baseValidator: () => [Validators.min(0), Validators.max(GlobalSettings.currencyMax)],
        },
        loanPeriodInMonths: {
            required: (newLiability: INewLiabilityModel) => HelperService.isNewLiabilityDirty(newLiability),
            baseValidator: () => [Validators.min(0), Validators.max(999)],
        },
        starts: {
            required: (newLiability: INewLiabilityModel) => HelperService.isNewLiabilityDirty(newLiability),
            baseValidator: () => [],
        },
        monthlyRate: {
            required: (newLiability: INewLiabilityModel) => HelperService.isNewLiabilityDirty(newLiability),
            baseValidator: () => [Validators.min(0), Validators.max(GlobalSettings.currencyMax)],
        },
        securedByLandRegister: {
            changeByChange: true,
            required: () => false,
            baseValidator: () => [],
        },
        securedRealEstateId: {
            required: (newLiability: INewLiabilityModel) => !!newLiability.securedByLandRegister,
            baseValidator: () => [],
        },
    },
    // TaxOfficeArrearModel: {
    //     id: {
    //         required: () => true,
    //         baseValidator: () => [],
    //     },
    //     householdId: {
    //         required: () => true,
    //         baseValidator: () => [],
    //     },
    //     institute: {
    //         required: (taxOfficeArrear: TaxOfficeArrearModel) => HelperService.isTaxOfficeArrearDirty(taxOfficeArrear),
    //         baseValidator: () => [],
    //     },
    //     arrear: {
    //         required: (taxOfficeArrear: TaxOfficeArrearModel) => HelperService.isTaxOfficeArrearDirty(taxOfficeArrear),
    //         baseValidator: () => [],
    //     },
    //     dueDate: {
    //         required: (taxOfficeArrear: TaxOfficeArrearModel) => HelperService.isTaxOfficeArrearDirty(taxOfficeArrear),
    //         baseValidator: () => [DateValidation.format],
    //     },
    // },
    // AssetModel: {
    //     id: {
    //         required: () => true,
    //         baseValidator: () => [],
    //     },
    //     householdId: {
    //         required: () => true,
    //         baseValidator: () => [],
    //     },
    //     type: {
    //         required: (asset: AssetModel) => HelperService.isAssetDirty(asset),
    //         baseValidator: () => [],
    //     },
    //     value: {
    //         required: (asset: AssetModel) => HelperService.isAssetDirty(asset),
    //         baseValidator: () => [Validators.min(0)],
    //     },
    //     verification: {
    //         required: (asset: AssetModel) => HelperService.isAssetDirty(asset),
    //         baseValidator: () => [],
    //     },
    // },
};
