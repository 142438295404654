import { Component, Input, OnInit } from '@angular/core';
import { AbstractControl, FormControl } from '@angular/forms';
import { provideMomentDateAdapter } from '@angular/material-moment-adapter';
import { GlobalSettings } from '@ucba/sdk';

/**
 * Komponente, welche einen Datepicker darstellt
 */
@Component({
    selector: 'cxad-date',
    templateUrl: './date.component.html',
    styleUrls: ['./date.component.scss'],
    providers: [
        provideMomentDateAdapter(GlobalSettings.CUSTOM_DATE_FORMAT),
    ],
})
export class DateComponent implements OnInit {

    @Input() public control!: AbstractControl;
    @Input() public labelText!: string;
    @Input() public comment: string | undefined;

    public isRequired: boolean | undefined;

    /**
     * gibt den aktuellen AbstractControl als FormControl zurück
     *
     * @returns {FormControl} control
     */
    public get formControl(): FormControl {
        return this.control as FormControl;
    }

    /**
     * Angular Hook zum initialisieren
     */
    public ngOnInit(): void {
        // @see https://stackoverflow.com/a/43904237
        const validators = !!this.control && !!this.control.validator ? this.control.validator(new FormControl()) : {};
        this.isRequired = !!validators && ('required' in validators);
    }
}
