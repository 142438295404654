/* eslint-disable no-fallthrough */

import { HttpHeaders } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Params, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Store } from '@ngxs/store';
import { NtagErrorHandlerService } from '@ntag-ef/error-handler';
import { NotificationService } from '@ntag-ef/notifications';
import { NGXLogger } from 'ngx-logger';
import { Observable, combineLatest } from 'rxjs';
import { filter, map, tap } from 'rxjs/operators';

import { ApplicationType, RoutingParams } from '../../enums';
import { IDataType, IGlobalState } from '../../interfaces';
import { IUserStateModel } from '../../statemanagement/user/user.state';
import { APPLICATION_TYPE, BACKEND_URL, DATA_OBSERVABLE, GlobalSettings } from '../../utils';
import { DataService } from '../data/data.service';

import { ApplicationHub } from './application.hub';

enum StartPoint {
    User,
    CustomerList,
    Customer,
    Financing,
}

/**
 * Service welcher verwaltet wann die Hubs aufgebaut, gestartet und beendet werden
 */
@Injectable()
export class HubService {

    private params: Params | undefined;

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    private intervall: any;

    /** Flag ob die Anwendung schon initialisiert wurde nach einem login */
    private isInit = false;

    /**
     * standard Konstruktor
     *
     * @param {Router} router Router injector
     * @param {Store} store Store injector
     * @param {NGXLogger} logger NGXLogger injector
     * @param {NtagErrorHandlerService} errorHandler NtagErrorHandlerService injector
     * @param {ApplicationHub} hub ApplicationHub injector
     * @param {DataService} dataService DataService injector
     * @param {NotificationService} notification NotificationService injector
     * @param {TranslateService} translate TranslateService injector
     * @param {BACKEND_URL} backendUrl BACKEND_URL injector
     * @param {APPLICATION_TYPE} applicationType APPLICATION_TYPE injector
     * @param {DATA_OBSERVABLE} data$ DATA_OBSERVABLE injector
     */
    public constructor(
        private router: Router,
        private store: Store,
        private logger: NGXLogger,
        private errorHandler: NtagErrorHandlerService,
        private hub: ApplicationHub,
        private dataService: DataService,
        private notification: NotificationService,
        private translate: TranslateService,
        @Inject(BACKEND_URL) private backendUrl: string,
        @Inject(APPLICATION_TYPE) private applicationType: ApplicationType,
        @Inject(DATA_OBSERVABLE) private data$: Observable<IDataType>,
    ) { }

    /**
     * baut ein object mit allen parametern, welche in den routen vorhanden sind
     *
     * @param {ActivatedRoute} route aktuelle Route
     * @param {Params} result rekursives Params Object
     * @returns {Params} fertiges Params Object
     */
    private static getRouteParameters(route: ActivatedRoute, result?: Params): Params {
        for (const child of route.children) {
            return HubService.getRouteParameters(child, Object.assign(!!result ? result : {}, child.snapshot.params));
        }

        return !!result ? Object.assign(result, route.snapshot.params) : route.snapshot.params;
    }

    /**
     * initiailisiert den route listener
     */
    public initialize(): void {
        combineLatest([
            this.router.events,
            this.data$,
        ]).pipe(
            filter(([ev]) => ev instanceof NavigationEnd),
            tap(() => {
                this.params = HubService.getRouteParameters(this.router.routerState.root);
            }),
            map(([ev, data]) => ({ url: (ev as NavigationEnd).url, data })),
        )
            .subscribe(navigationParams => {
                this.handleNavigationChange(navigationParams.url, navigationParams.data).catch(e => { throw e; });
            });
    }

    /**
     * verarbeitet ein wechsel der routen und steuert die einzelnen hubs an
     *
     * @param {string} currentUrl die aktuelle URL
     * @param {IDataType} data Anwendungspezifische daten zum auswerten
     */
    private async handleNavigationChange(currentUrl: string, data: IDataType) {
        // this.logger.log(`${currentUrl} ${JSON.stringify(this.params)}`);

        GlobalSettings.token = data.token;

        const currentConfig = this.errorHandler.ngxlogger.getConfigSnapshot();
        currentConfig.customHttpHeaders = new HttpHeaders({
            // eslint-disable-next-line @typescript-eslint/naming-convention
            'Authorization': `Bearer ${GlobalSettings.token}`,
        });

        this.errorHandler.ngxlogger.updateConfig(currentConfig);

        if (data.isLoggendIn) {
            try {

                try {
                    if (!GlobalSettings.hubError) {
                        if (this.hub.isClosed) {
                            this.hub.createConnection(`${this.backendUrl}/hubs/basehub`, GlobalSettings.token);
                        }

                        await this.hub.startConnection();
                    }
                }
                catch {
                    // Hub Exceptions werden im CustomSignalRLogger behandelt
                    if (GlobalSettings.isOnline.getValue()) {
                        this.notification.alert(
                            this.translate.instant('applicationTranslations.noHubHeader'),
                            this.translate.instant('applicationTranslations.noHubNote'),
                        );
                    }
                }

                if (!this.isInit) {
                    await this.dataService.updateMasterdata(true);

                    this.intervall = setInterval(() => {
                        this.dataService.updateMasterdata(true).catch(e => { throw e; });
                    }, 3600000); // alle 60 min

                    if (this.applicationType === ApplicationType.FINADVISORY) {
                        await this.dataService.loadUser();
                    }

                    this.isInit = true;
                }

                await this.checkForData(currentUrl);
            }
            catch (e) {
                this.logger.error(e);
            }
        }
        else {
            if (!!this.intervall) {
                clearInterval(this.intervall);
                this.intervall = undefined;
            }
            await this.unloadData(StartPoint.User);
            this.isInit = false;
        }
    }

    /**
     * Prüft ob Daten geladen/entladen werden müssen
     * 
     * @param {string} currentUrl die aktuelle URL
     */
    private async checkForData(currentUrl: string) {
        if (this.applicationType === ApplicationType.FINADVISORY) {
            const userState = this.store.selectSnapshot<IUserStateModel>((states: IGlobalState) => states.userState);
            const user = userState?.data ?? undefined;
            await this.hub.setCurrentUser(user);
  
            if (currentUrl.includes(`/${RoutingParams.CUSTOMER_MODULE}`)) {
                if (!!this.params && RoutingParams.CUSTOMER_ID in this.params) {
                    await this.hub.setCurrentCustomer(this.params[RoutingParams.CUSTOMER_ID]);
                    await this.dataService.loadFinancingMapList(this.params[RoutingParams.CUSTOMER_ID]);

                    if (RoutingParams.FINANCINGMAP_ID in this.params) {
                        await this.hub.setCurrentFinancingMap(this.params[RoutingParams.FINANCINGMAP_ID]);
                        this.dataService.loadMessages(this.params[RoutingParams.FINANCINGMAP_ID])
                    }
                    else {
                        await this.unloadData(StartPoint.Financing);
                    }
                }
                else {
                    await this.unloadData(StartPoint.Customer);
                }
            }
            else {
                await this.unloadData(StartPoint.CustomerList);
            }
        }

        if (this.applicationType === ApplicationType.CXADVISORY) {
            const applicationState = this.store.selectSnapshot(state => state.applicationState);
            const customerId = !!applicationState ? applicationState.customerId as string : undefined;

            if (!!customerId) {
                await this.hub.setCurrentCustomer(customerId);

                if (currentUrl.includes(`/${RoutingParams.FINANCING_MODULE}`)) {
                    await this.dataService.loadFinancingMapList(customerId);
                    await this.dataService.loadStatusEntryList();

                    if (!!this.params && RoutingParams.FINANCINGMAP_ID in this.params) {
                        await this.hub.setCurrentFinancingMap(this.params[RoutingParams.FINANCINGMAP_ID]);
                        this.dataService.loadMessages(this.params[RoutingParams.FINANCINGMAP_ID]);
                    }
                    else {
                        await this.unloadData(StartPoint.Financing);
                    }
                }
                else {
                    this.dataService.unloadFinancingMapList();
                    this.dataService.unloadStatusEntryList();
                }
            }
            else {
                await this.unloadData(StartPoint.Customer);
            }
        }
    }

    /**
     * entläd nacheinander die listen und entfernt die Connection aus den Gruppen
     *
     * @param {StartPoint} startpoint der start hub ab dem alle anderen hubs gestoppt werden
     */
    private async unloadData(startpoint: StartPoint) {
        switch (startpoint) {

            case StartPoint.User: // Logout
                await this.hub.stopConnection();
                this.dataService.unloadActivities();

            case StartPoint.CustomerList: // Wechel auf Dashboard
                this.dataService.unloadCustomerList();

            case StartPoint.Customer: // Verlassen des Kunden
                await this.hub.setCurrentCustomer(null);
                this.dataService.unloadFinancingMapList();

            case StartPoint.Financing: // Verlassen der Finanzierung
                await this.hub.setCurrentFinancingMap(null);
                this.dataService.unloadMessages();

            default: break;
        }
    }

}
