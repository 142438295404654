import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { AbstractControl, FormControl } from '@angular/forms';
import { provideMomentDateAdapter } from '@angular/material-moment-adapter';
import { GlobalSettings, HelperService, ValidationService } from '@ucba/sdk';
import { Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';

/**
 * Komponete, welche einen Datepicker darstellt
 */
@Component({
    selector: 'ucba-date',
    templateUrl: './date.component.html',
    styleUrls: ['./date.component.scss'],
    providers: [
        provideMomentDateAdapter(GlobalSettings.CUSTOM_DATE_FORMAT),
    ],
})
export class DateComponent implements OnInit, OnDestroy {

    @Input() public control!: AbstractControl;
    @Input() public labelText!: string;
    @Input() public comment: string | undefined;

    /** the mindate as string (YYYY-MM-DD) */
    @Input() public minDate: string | undefined;
    /** the maxdate as string (YYYY-MM-DD) */
    @Input() public maxDate: string | undefined;
    public minDateForDatepicker: Date | undefined;
    public maxDateForDatepicker: Date | undefined;

    public isRequired: boolean | undefined;
    private destroyed$ = new Subject<void>();
    public controlName: string | undefined;

    /**
     * gibt den aktuellen AbstractControl als FormControl zurück
     *
     * @returns {FormControl} control
     */
    public get formControl(): FormControl {
        return this.control as FormControl;
    }

    /**
     * Angular Hook zum initialisieren
     */
    public ngOnInit(): void {
        this.controlName = HelperService.getControlName(this.formControl);
        this.minDateForDatepicker = this.minDate ? new Date(this.minDate) : GlobalSettings.minDateForDatepicker;
        this.maxDateForDatepicker = this.maxDate ? new Date(this.maxDate) : GlobalSettings.maxDateForDatepicker;

        ValidationService.validatorsChangedObservable$.pipe(
            filter(it => it === this.control),
            takeUntil(this.destroyed$),
        ).subscribe(() => {
            this.updateRequired();
        });

        this.updateRequired();
    }

    /**
     * Angular Hook beim verlassen
     */
    public ngOnDestroy(): void {
        this.destroyed$.next();
    }

    /**
     * update das isRequired feld anhand der im control rinterlegten validatoren
     */
    private updateRequired() {
        // @see https://stackoverflow.com/a/43904237
        const validators = !!this.control && !!this.control.validator ? this.control.validator(new FormControl()) : {};
        this.isRequired = !!validators && ('required' in validators);
    }

}
