/* eslint-disable @typescript-eslint/member-ordering */
/* eslint-disable class-methods-use-this */

import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { EmployeeStatus } from '@ntag-ef/finprocess-enums/finadvisory';
import { sort } from 'fast-sort';

import { IFieldCardVisibilityDebtor } from '../../interfaces';
import { IDebtorModel } from '../../models';
import { CalculationFactoryService, HelperService } from '../../services';
import { Dispose, UnLoadCustomer, UnLoadFinancing } from '../actions';

import { SdkAddDebtor, SdkDeleteDebtor, SdkPatchDebtor, SdkUpdateDebtor } from './debtor.actions';

export interface IDebtorStateModel {
    data: IDebtorModel[];
}


/**
 * Klasse des Kreditnehmers States
 */
@State<IDebtorStateModel>({
    name: DebtorState.stateName,
    defaults: DebtorState.defaultData,
})
@Injectable()
export class DebtorState {
    public static readonly stateName = 'debtorState';
    private static readonly defaultData: IDebtorStateModel = {
        data: [],
    };
    /**
     * Aktualisiert den aktuellen Kreditnehmer
     *
     * @param {StateContext} ctx aktueller State Kontext
     * @param {SdkPatchDebtor} action SdkPatchDebtor Action
     */
    @Action(SdkPatchDebtor)
    public patchDebtor(
        { patchState }: StateContext<IDebtorStateModel>,
        { payload }: SdkPatchDebtor,
    ): void {
        patchState({
            data: payload,
        });
    }

    /**
     * Aktualisiert ein spezifischen Kreditnehmer
     *
     * @param {StateContext} ctx aktueller State Kontext
     * @param {SdkPatchDebtor} action SdkUpdateDebtor Action
     */
    @Action(SdkUpdateDebtor)
    public updateDebtor(
        { patchState, getState }: StateContext<IDebtorStateModel>,
        { payload }: SdkUpdateDebtor,
    ): void {
        const current = getState().data;

        if (current.some(({ id }) => id === payload.id)) {
            patchState({
                data: current.filter(({ id }) => id !== payload.id).concat(payload),
            });
        }
    }

    /**
     * fügt ein neuen Kreditnehmer hinzu
     *
     * @param {StateContext} ctx aktueller State Kontext
     * @param {SdkAddDebtor} action SdkAddDebtor Action
     */
    @Action(SdkAddDebtor)
    public addDebtor(
        { patchState, getState }: StateContext<IDebtorStateModel>,
        { payload }: SdkAddDebtor,
    ): void {
        const current = getState().data;

        if (!current.some(({ id }) => id === payload.id)) {
            patchState({
                data: current.concat(payload),
            });
        }
    }

    /**
     * entfernt ein spezifischen Kreditnehmer
     *
     * @param {StateContext} ctx aktueller State Kontext
     * @param {SdkDeleteDebtor} action SdkDeleteDebtor Action
     */
    @Action(SdkDeleteDebtor)
    public deleteDebtor(
        { patchState, getState }: StateContext<IDebtorStateModel>,
        { id: debtorId }: SdkDeleteDebtor,
    ): void {
        const current = getState().data;

        if (current.some(({ id }) => id === debtorId)) {
            patchState({
                data: current.filter(({ id }) => id !== debtorId),
            });
        }
    }

    /**
     * zurücksetzen des States
     *
     * @param {StateContext} ctx aktueller State Kontext
     */
    @Action([Dispose, UnLoadFinancing, UnLoadCustomer])
    public dispose({ setState }: StateContext<IDebtorStateModel>): void {
        setState({ ...DebtorState.defaultData });
    }

    /**
     * gibt alle aktuell selectierten Kreditnehmer zurück
     *
     * @param {IDebtorStateModel} state aktueller State
     * @returns {IDebtorModel} der aktuell selektierte Kunde
     */
    @Selector()
    public static current(state: IDebtorStateModel): IDebtorModel[] {
        return sort(state?.data ?? []).asc(({ position }) => position);
    }

    /**
     * gibt einen spezifischen Kreditnehmer zurück
     *
     * @param {IDebtorStateModel} state aktueller State
     * @returns {(debtorId: string) => IDebtorModel} der spezifischer Kreditnehmer
     */
    @Selector()
    public static currentById(state: IDebtorStateModel): (debtorId: string) => IDebtorModel | undefined {
        /**
         * callback funktion zum filtern
         *
         * @param {string} debtorId Id des Kreditnehmers
         * @returns {IDebtorModel} gefilterter Kreditnehmer
         */
        return (debtorId: string): IDebtorModel | undefined => DebtorState.current(state).find(({ id }) => id === debtorId);
    }

    /**
     * gibt die Ids aller Kreditnehmer zurück, welche der aktuellen Finanzierung zugeordnet sind
     *
     * @param {IDebtorStateModel} state aktueller State
     * @returns {string[]} Ids aller selektierten Kreditnehmer
     */
    @Selector()
    public static currentIds(state: IDebtorStateModel): string[] {
        return DebtorState.current(state).map(({ id }) => id);
    }

    /**
     * gibt alle aktuell Kreditnehmer eines haushaltes zurück
     *
     * @param {IDebtorStateModel} state aktueller State
     * @returns {(householdIds: string[]) => IDebtorModel[]} funktion um Kreditnehmer pro Haushalt zu filtern
     */
    @Selector()
    public static currentByHousholdIds(state: IDebtorStateModel): (householdIds: string[]) => IDebtorModel[] {
        /**
         * callback funktion zum filtern
         *
         * @param {string[]} householdIds ids der Haushalte
         * @returns {IDebtorModel[]} gefilterte Kreditnehmer
         */
        return (householdIds: string[]): IDebtorModel[] =>
            DebtorState.current(state).filter(({ householdId }) => householdIds.includes(householdId));
    }


    /**
     * ist irgendein Kreditnehmer bei der Bank Austria
     *
     * @param {IDebtorStateModel} state aktueller State
     * @returns {boolean} ein KN bei der Bank Austria
     */
    @Selector()
    public static anyDebitorsBA(state: IDebtorStateModel): boolean {
        return DebtorState
            .current(state)
            .some(({ bankAccount }) => bankAccount === 'Bank Austria'); // See Api GET api/BankAccount
    }

    // /**
    //  * gibt die aktuellen Kreditnehmer im Format für den Precheck zurück
    //  *
    //  * @param  {IDebtorStateModel} state aktueller State
    //  * @returns {IDebitorInput[]} Kreditnehmer
    //  */
    // @Selector()
    // public static asInputModel(state: IDebtorStateModel): IDebitorInput[] {
    //     const debtors = DebtorState.current(state);

    //     return debtors.map(debtor => ({
    //         netIncomePerMonth: HelperService.getValue(debtor.netIncome, ModelFactoryService.globalCurrencyDefault),
    //         birthday: !!debtor.birthday ? new Date(debtor.birthday) : new Date(),
    //         childrenCount: debtor.childrenCount ?? 0,
    //         dependentPersonsCount: debtor.dependentPersonsCount ?? 0,
    //         fourteenSalariesPerYear: debtor.fourteenSalariesPerYear ?? false,
    //         otherIncome: debtor.otherIncome ?? 0,
    //     }));
    // }


    //#region Berechnungen

    /**
     * Berechnet das gesamt Einkommen aller Kreditnehmer eines Haushaltes
     *
     * @param {IDebtorStateModel} state aktueller State
     * @returns {(householdId: string, calculationVersion: number) => number} funktion zum berechnen
     */
    @Selector()
    public static commonIncomeSumByHouseHoldId(state: IDebtorStateModel):
        (householdId: string, calculationVersion?: number) => number {
        /**
         * callback funktion zum berechnen des Einkomments
         *
         * @param {string} householdId id des Haushaltes
         * @param {number} calculationVersion version der Berechungslogik
         * @returns {IDebtorModel[]} gefilterte Kreditnehmer
         */
        return (householdId: string, calculationVersion?: number): number => {
            const debtors = DebtorState.currentByHousholdIds(state)([householdId]);
            const debtorCalculationService = CalculationFactoryService.debtorCalculationService(calculationVersion);

            return debtors.reduce<number>(
                (sum, { netIncome, fourteenSalariesPerYear, otherIncome }) => sum + debtorCalculationService.netIncomeTotal({
                    netIncome: HelperService.getValue(netIncome, 0),
                    fourteenSalariesPerYear: HelperService.getValue(fourteenSalariesPerYear, false),
                    otherIncome: HelperService.getValueOrUndefiend(otherIncome),
                }), 0);
        };
    }

    //#endregion

    /**
     * ein visibility objekt welches bestimmt, welche Felder und Karten angezeigt werden
     *
     * @param {IDebtorModel} debtor zu prüfendes Objekt
     * @returns {IFieldCardVisibilityDebtor} die visibility Map
     */
    public static visibleMap(debtor: IDebtorModel): IFieldCardVisibilityDebtor {
        if (!debtor) {
            return {} as IFieldCardVisibilityDebtor;
        }

        return {
            debtorId: debtor.id,
            cardNotesIncome: HelperService.getValue(debtor.otherIncome, 0) > 0,
            cardNotesOtherIncome: HelperService.getValue(debtor.otherIncome, 0) > 0 && debtor.otherIncomeContainsAlimony,
            cardProfession: HelperService.hasValue(debtor.employeeStatus) && [
                EmployeeStatus.Employed,
                EmployeeStatus.Worker,
                EmployeeStatus.PublicServant,
                EmployeeStatus.Probation,
                EmployeeStatus.UnpaidLeave,
            ].includes(debtor.employeeStatus),
            isSelfEmployed: debtor.employeeStatus === EmployeeStatus.SelfEmployed,
            cardCareer: debtor.employeeStatus === EmployeeStatus.SelfEmployed && !debtor.noCareers,
            fieldLineType: HelperService.getValue(debtor.vehicleCount, 0) === 1,
            fieldLineTypeMulti: HelperService.getValue(debtor.vehicleCount, 0) > 1,
        } as IFieldCardVisibilityDebtor;
    }
}
