import { DatePipe } from '@angular/common';
import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Select, Store } from '@ngxs/store';
import { EnumTranslationService } from '@ntag-ef/finprocess-enums';
import { GlobalSettings } from '@ucba/sdk';
import { FinancingMapStatus, RoutingParams } from '@ucba/sdk/enums';
import { IListTuple } from '@ucba/sdk/interfaces';
import { ICustomerModel, IFinancingMapLightModel, IStatusEntry, ITimeLineItem } from '@ucba/sdk/models';
import { DataService, FileService, HelperService } from '@ucba/sdk/services';
import { CustomerState } from '@ucba/sdk/statemanagement/states';
import { sort } from 'fast-sort';
import { Observable, combineLatest, filter, map, shareReplay } from 'rxjs';

import { CardModel } from '../../types';

type FinancingCardDataPipeType = [IFinancingMapLightModel[], Record<string, IStatusEntry[]>];

/**
 * Komponente welche den Status zur Finanzierung anzeigt (Auftragsverfolgung)
 */
@Component({
    selector: 'cxad-status',
    templateUrl: './status.component.html',
    styleUrls: ['./status.component.scss'],
    encapsulation: ViewEncapsulation.None,
})

export class StatusComponent implements OnInit {

    @Select(CustomerState.current)
    public customer$: Observable<ICustomerModel | null> | undefined;
    public financings$?: Observable<CardModel[] | null>;
    public statusList: IListTuple<string>[] | undefined;
    public isEditable = false;

    /**
     * Standard Konstruktor
     *
     * @param {EnumTranslationService} enumTranslate EnumTranslationService injector
     * @param {DataService} dataService DataService injector
     * @param {DatePipe} datePipe DatePipe injector
     * @param { Router } router Router injector
     * @param {TranslateService} translate TranslateService injector
     * @param {Store} store Store injector
     * @param {FileService} fileService FileService injector
     */
    public constructor(
        private enumTranslate: EnumTranslationService,
        private dataService: DataService,
        private datePipe: DatePipe,
        private router: Router,
        private translate: TranslateService,
        private store: Store,
        private fileService: FileService,
    ) { }

    /**
     * Angular Hook um Werte zu initialisieren
     */
    public ngOnInit(): void {
        this.financings$ = combineLatest([
            this.dataService.financingObservable$,
            this.dataService.statusEntryAsDictionary$,
        ])
            .pipe(
                filter(([financings, statusEntries]) => Array.isArray(financings) && !!statusEntries),
                map(values => values as FinancingCardDataPipeType),// durch den vorangegangen filter sollte dies gehen
                map<FinancingCardDataPipeType, FinancingCardDataPipeType>(([financings, statusEntries]) => ([sort(financings).desc(({ created }) => created), statusEntries])),
                map(([financings, statusEntries]) => financings.map<CardModel>(financing => ({
                    financing,
                    buttons: [
                        {
                            icon: 'file_download',
                            tooltip: this.translate.instant('components.welcome.buttons.download'),
                            onClick: (financingMapId: string) => {
                                this.fileService.showFinancingMapFile(
                                    financingMapId,
                                    this.store.selectSnapshot(CustomerState.currentName),
                                ).catch(e => { throw e; });
                            },
                        },
                    ],
                    timelineItems: this.createTimeLineItems(financing, statusEntries[financing.id]),
                }))),
                shareReplay({ bufferSize: 1, refCount: true }),

            );

        this.statusList = Object.values(FinancingMapStatus).map<IListTuple<string>>(t => ({
            value: this.enumTranslate.instant({ type: 'FinancingMapStatus', value: t }) as string,
            label: this.enumTranslate.instant({ type: 'FinancingMapStatus', value: t }) as string,
        }));
    }

    /**
     * Öffnet die Finanzierungsmappe
     *
     * @param {string} id Finanzierungs Id
     * @returns {Promise} routing result Promise
     */
    public openFinancing(id: string): Promise<boolean> {
        const fm = this.dataService.getFinancing(id);

        if (!!fm && HelperService.hasValue(fm.status) && fm.status > FinancingMapStatus.Open) {
            return this.router.navigateByUrl(`${RoutingParams.FINANCING_MODULE}/${id}/bankdocuments`)
        }
        else {
            return this.router.navigateByUrl(`${RoutingParams.FINANCING_MODULE}/${id}`)
        }
    }

    /**
     * Erstellt eine Liste von TimeLine Items abhängig der Historie der finanzierung
     * 
     * @param {IFinancingMapLightModel} financing Finanzierung
     * @param {IStatusEntry} statusEntries IStatusEntry
     * @returns {ITimeLineItem[]} Liste von TimeLine Items
     */
    // eslint-disable-next-line complexity
    private createTimeLineItems(financing: IFinancingMapLightModel, statusEntries: IStatusEntry[]): ITimeLineItem[] {
        const result: ITimeLineItem[] = [];

        if (Array.isArray(statusEntries) && statusEntries.length > 0) {
            for (let i = 0; i < statusEntries.length; i++) {
                const statusEntry = statusEntries[i];

                const item: ITimeLineItem = {
                    position: i + 1,
                    status: statusEntry.status,
                    statusName: this.enumTranslate.instant({ type: 'FinancingMapStatus', value: statusEntry.status }) as string,
                    statusNotes: this.datePipe.transform(statusEntry.created, 'short') ?? '',
                    itemStatus: 'done',
                };

                if (statusEntry.status === FinancingMapStatus.VpcRejected && statusEntries.filter(({ status }) => status === FinancingMapStatus.Open).length > 1) {
                    item.statusName += '\n(automatisch)';
                }

                if (i === statusEntries.length - 1 && statusEntry.status !== FinancingMapStatus.VpcCompleted) { // aktueller Status und ist nicht abgeschlossen (Esis bestätigt)
                    const info = GlobalSettings.defaultFinancingStatusInfos.find(({ status }) => status === statusEntry.status)
                    item.itemStatus = 'edit';
                    item.statusNotes += !!info?.discription ? `\n${info?.discription}` : '';
                }

                if ([FinancingMapStatus.VpcCanceled, FinancingMapStatus.VpcRejected].includes(item.status)) {
                    item.itemStatus = 'error';
                    item.statusNotes += '\nEs ist keine weitere Bearbeitung mehr möglich.';
                }

                if (statusEntry.status === FinancingMapStatus.SampleCalculationRequested && statusEntries.some(({ status }) => status === FinancingMapStatus.SampleCalculationExists)) {
                    item.statusName = 'Alternatives Rechenbeispiel angefordert';
                }

                if (statusEntry.status === FinancingMapStatus.SampleCalculationExists && financing.status === FinancingMapStatus.SampleCalculationRequested) {
                    item.itemStatus = 'error';
                    item.statusNotes += '\nAlternatives Rechenbeispiel angefordert';
                }

                if (statusEntry.status === FinancingMapStatus.EsisExists && financing.status === FinancingMapStatus.EsisRequested) {
                    item.itemStatus = 'error';
                    item.statusNotes += '\nAlternatives ESIS angefordert';
                }

                const existingIdx = result.findIndex(({ status }) => status === item.status);

                // Jeder Eintrag existiert nur einmal
                if (existingIdx !== -1) {
                    result[existingIdx] = item;
                }
                else {
                    result.push(item);
                }
            }
        }

        if (!result.some(({ status }) => status === FinancingMapStatus.Open)) {
            // wenn es keinen Eintrag für Offen gibt, wird dieser nachträglich am Anfang hinzugefügt

            const item: ITimeLineItem = {
                position: 1,
                status: FinancingMapStatus.Open,
                statusName: this.enumTranslate.instant({ type: 'FinancingMapStatus', value: FinancingMapStatus.Open }) as string,
                statusNotes: this.datePipe.transform(financing.created, 'short') ?? '',
                itemStatus: 'done',
            };

            if (financing.status === FinancingMapStatus.Open) { // aktueller Status
                const info = GlobalSettings.defaultFinancingStatusInfos.find(({ status }) => status === FinancingMapStatus.Open)
                item.itemStatus = 'edit';
                item.statusNotes += `\n${info?.discription}` ?? '';
            }

            result.unshift(item);

            // Korrektur bereits enthaltener Einträge
            if (result.length > 1) {
                for (let i = 1; i < result.length; i++) {
                    result[i].position = i + 1;
                }
            }
        }

        let currentStatusIdx = GlobalSettings.defaultFinancingStatusInfos.findIndex(({ status }) => status === result[result.length - 1].status);

        if (currentStatusIdx === -1 && result.length > 1) {
            currentStatusIdx = GlobalSettings.defaultFinancingStatusInfos.findIndex(({ status }) => status === result[result.length - 2].status);
        }

        let lastPosition = result[result.length - 1].position; // um bei altfällen die Position korrekt anzuzeigen

        if (currentStatusIdx !== -1) {
            for (let i = currentStatusIdx + 1; i < GlobalSettings.defaultFinancingStatusInfos.length; i++) {
                const info = GlobalSettings.defaultFinancingStatusInfos[i];
                const item: ITimeLineItem = {
                    position: ++lastPosition,
                    status: info.status,
                    statusName: this.enumTranslate.instant({ type: 'FinancingMapStatus', value: info.status }) as string,
                    statusNotes: '',
                    itemStatus: 'number',
                };

                result.push(item);
            }
        }

        return result;
    }

}
