/* eslint-disable @typescript-eslint/member-ordering */
/* eslint-disable class-methods-use-this */

import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { DebitorCalculationService, IFinancingConfiguration } from '@ntag-ef/finprocess-calculations';
import { ComparisonType, CreditPurpose, DebitRateAdaptionParameter, DocumentType, ObjectPurposeType } from '@ntag-ef/finprocess-enums/finadvisory';
import { sort } from 'fast-sort';

import { DocumentArea, FinancingMapStatus } from '../../enums';
import { ICalculationCalculator, ICalculationFinancingPlan, IDocumentFileItem, IDocumentSectionArea, IInterimResults, IRequiredSectionArea, ISignatureSectionArea } from '../../interfaces';
import { IDebitRateAdaptionModel, IDocumentModel, IFileModel, IFinancingMapModel } from '../../models';
import { CalculationFactoryService, HelperService, ModelFactoryService } from '../../services';
import { GlobalSettings } from '../../utils';
import { DOCUMENT_CARD_VALIDATION_MAP, IDocumentValidation } from '../../validations/document-card.validation-map';
import { Dispose, UnLoadCustomer, UnLoadFinancing } from '../actions';
import { DebtorState, IDebtorStateModel } from '../debtor/debtor.state';
import { DocumentState, IDocumentStateModel } from '../document/document.state';
import { FileState, IFileStateModel } from '../file/file.state';
import { HouseholdState, IHouseholdStateModel } from '../household/household.state';
import { ILiabilityStateModel, LiabilityState } from '../liability/liability.state';
import { IMasterDataStateModel, MasterDataState } from '../masterdata/masterdata.state';
import { INewLiabilityStateModel, NewLiabilityState } from '../new-liability/new-liability.state';
import { IRealEstateStateModel, RealEstateState } from '../realestate/realestate.state';
import { ISignatureStateModel, SignatureState } from '../signature/signature.state';

import { SdkPatchFinancingMap, SdkUpdateStatus } from './financingmap.actions';

/** Calculation instanz */
const debCalculation = new DebitorCalculationService();

export interface IFinancingMapStateModel {
    data: IFinancingMapModel | null;
}

/**
 * Klasse des FinancingMap States
 */
@State<IFinancingMapStateModel>({
    name: FinancingMapState.stateName,
    defaults: FinancingMapState.defaultData,
})

@Injectable()
export class FinancingMapState {
    public static readonly stateName = 'financingMapState';
    private static readonly defaultData: IFinancingMapStateModel = {
        data: null,
    };

    /**
     * Aktualisiert die aktuelle FinaningMap
     *
     * @param {StateContext} ctx aktueller State Kontext
     * @param {SdkPatchFinancingMap} action SdkPatchFinancingMap Action
     */
    @Action(SdkPatchFinancingMap)
    public patchFinancingMap(
        { patchState }: StateContext<IFinancingMapStateModel>,
        { payload }: SdkPatchFinancingMap,
    ): void {
        patchState({
            data: payload,
        });
    }

    /**
     * Aktualisiert die aktuelle FinaningMap
     *
     * @param {StateContext} ctx aktueller State Kontext
     * @param {SdkUpdateStatus} action SdkUpdateStatus Action
     */
    @Action(SdkUpdateStatus)
    public updateStatus(
        { patchState, getState }: StateContext<IFinancingMapStateModel>,
        { payload }: SdkUpdateStatus,
    ): void {
        const state = getState();

        if (state.data && state.data.id === payload.id) {
            patchState({
                data: ({
                    ...state.data,
                    status: payload.status,
                    statusInfo: payload.statusInfo,
                }),
            });
        }
    }

    /**
     * zurücksetzen des States
     *
     * @param {StateContext} ctx aktueller State Kontext
     */
    @Action([Dispose, UnLoadFinancing, UnLoadCustomer])
    public dispose({ setState }: StateContext<IFinancingMapStateModel>): void {
        setState({ ...FinancingMapState.defaultData });
    }

    /**
     * gibt aktuell selectierte FinancingMap zurück
     *
     * @param {IFinancingMapStateModel} state aktueller State
     * @returns {IFinancingMapModel} die aktuell selektierte Financing Map
     */
    @Selector()
    public static current(state: IFinancingMapStateModel): IFinancingMapModel | null {
        return state?.data ?? null;
    }

    /**
     * gibt die aktuell selectierte FinancingMap Id zurück
     *
     * @param {IFinancingMapStateModel} state aktueller State
     * @returns {string} die aktuell Financing Map Id
     */
    @Selector()
    public static currentId(state: IFinancingMapStateModel): string | undefined {
        return state?.data?.id;
    }

    /**
     * prüft ob das Elternobjekt des übergebenen Dokuments vorhanden ist
     *
     * @param {IFinancingMapStateModel} state aktueller State
     * @param {DebtorState} debtorState aktueller Debtor State
     * @param {HouseholdState} householdState aktueller HouseholdState State
     * @param {RealEstateState} realEstateState aktueller RealestateState State
     * @returns {() => boolean} Callback Suchfunktion
     */
    @Selector([DebtorState, HouseholdState, RealEstateState])
    public static existDocumentParent(
        state: IFinancingMapStateModel,
        debtorState: IDebtorStateModel,
        householdState: IHouseholdStateModel,
        realEstateState: IRealEstateStateModel,
    ): (document: IDocumentModel) => boolean {

        /**
         * Callback Suchfunktion
         * 
         * @param {IDocumentModel} document das Kind Objekt
         * @returns {boolean} Elternobjekt existiert
         */
        return (document: IDocumentModel): boolean => {
            if (!!document.financingMapId) {
                return FinancingMapState.currentId(state) === document.financingMapId;
            }
            else if (!!document.realEstateId) {
                return RealEstateState.currentIds(realEstateState).includes(document.realEstateId);
            }
            else if (!!document.householdId) {
                return HouseholdState.currentIds(householdState).includes(document.householdId);
            }
            else if (!!document.debtorId) {
                return DebtorState.currentIds(debtorState).includes(document.debtorId);
            }

            return false;
        };
    }

    // /**
    //  * gibt die aktuell selektierte FinancingMap in einer Form für den Precheck zurück
    //  *
    //  * @param  {IFinancingMapStateModel} state aktueller State
    //  * @param {DebtorState} debtorState aktueller Debtor State
    //  * @param {HouseholdState} householdState aktueller HouseholdState State
    //  * @param {RealEstateState} realEstateState aktueller RealestateState State
    //  * @param {LiabilityState} liabilityState aktueller LiabilityState State
    //  * @param {NewLiabilityState} newLiabilityState aktueller NewLiabilityState State
    //  * @returns {IExitValidationInput | undefined} Format für Precheck
    //  */
    // @Selector([DebtorState, HouseholdState, RealEstateState, LiabilityState, NewLiabilityState])
    // // eslint-disable-next-line complexity
    // public static asExitValidationInput(
    //     state: IFinancingMapStateModel,
    //     debtorState: IDebtorStateModel,
    //     householdState: IHouseholdStateModel,
    //     realEstateState: IRealEstateStateModel,
    //     liabilityState: ILiabilityStateModel,
    //     newLiabilityState: INewLiabilityStateModel,
    // ): IExitValidationInput | undefined {
    //     if (!state.data) {
    //         return undefined;
    //     }
    //     else {
    //         const financingMap = FinancingMapState.current(state);
    //         const financingMapCalculationService = CalculationFactoryService.financingMapCalculationService(HelperService.getValueOrUndefiend(financingMap?.calculationVersion));

    //         return {
    //             assumedDuration: state.data.assumedDuration ?? undefined,
    //             gracePeriod: state.data.gracePeriod ?? undefined,
    //             estimateChargesPercent: state.data.valuationFeeIsPercent ? state.data.valuationFeeInput ?? undefined : undefined,
    //             estimateChargesAmount: !state.data.valuationFeeIsPercent ? state.data.valuationFeeInput ?? undefined : undefined,
    //             processingChargesPercent: state.data.handlingFeeInput ?? undefined,
    //             registrationChargesPercent: state.data.registrationChargesPercent ?? undefined,
    //             otherOwnCapital: state.data.otherOwnCapital ?? undefined,
    //             funding: state.data.funding ?? undefined,
    //             cash: state.data.cash ?? undefined,
    //             salesRevenue: financingMapCalculationService.salesRevenue({
    //                 reducedSalesRevenue: financingMap?.salesRevenue ?? undefined,
    //                 realEstates: RealEstateState.current(realEstateState),
    //             }),
    //             redemptionInsurance: state.data.redemptionInsurance ?? undefined,
    //             bausparCreditBalance: state.data.bausparCreditBalance ?? undefined,
    //             realEstateTaxesFee: ModelFactoryService.realEstateTransferTaxFeeDefault,
    //             landregisterEntryFee: ModelFactoryService.rightOfOwnershipFeeDefault,
    //             notaryCostsAmount: state.data.notaryFeeIsPercent ? undefined : state.data.notaryFeeInput ?? undefined,
    //             notaryCostsFee: state.data.notaryFeeIsPercent ? state.data.notaryFeeInput ?? undefined : undefined,
    //             brokerageCostsAmount: state.data.brokerageFeeIsPercent ? undefined : state.data.brokerageFeeInput ?? undefined,
    //             brokerageCostsFee: state.data.brokerageFeeIsPercent ? state.data.brokerageFeeInput ?? undefined : undefined,
    //             constructionCreditAmount: state.data.constructionCreditAmount ?? undefined,
    //             debitors: DebtorState.asInputModel(debtorState),
    //             households: HouseholdState.asInputModel(householdState),
    //             realEstates: RealEstateState.current(realEstateState).map(re => RealEstateState.asInputModel(re)),
    //             liabilities: LiabilityState.asInputModel(liabilityState),
    //             newLiabilities: NewLiabilityState.asInputModel(newLiabilityState),
    //             calculationVersion: state.data.calculationVersion ?? undefined,
    //             interestMethod: state.data.interestMethod ?? undefined,
    //             requestedDebitRate: state.data.requestedDebitRate ?? undefined,
    //         }
    //     }
    // }

    //#region Berechungen

    //#region FinancingPlan

    /**
     * gibt alle Ergebnisse der aktuellen finanzierung
     *
     * @param {IFinancingMapStateModel} state aktueller State
     * @param {IRealEstateStateModel} realEstateState aktueller RealEstate State
     * @param {IHouseholdStateModel} householdState aktueller Household State
     * @param {ILiabilityStateModel} liabilityState aktueller Liability State
     * @param {IMasterDataStateModel} masterDataState aktueller MasterData State
     * @returns {ICalculationFinancingPlan} Berechnungsobjekt mit Ergebnissen
     */
    @Selector([RealEstateState, HouseholdState, LiabilityState, MasterDataState])
    public static calculations(
        state: IFinancingMapStateModel,
        realEstateState: IRealEstateStateModel,
        householdState: IHouseholdStateModel,
        liabilityState: ILiabilityStateModel,
        masterDataState: IMasterDataStateModel,
    ): ICalculationFinancingPlan {
        const financingMap = FinancingMapState.current(state);
        const realEstate = RealEstateState.currentFinancingObject(realEstateState);
        const forSaleRealestates = RealEstateState.currentForSaleObjects(realEstateState);
        const creditPurpose = HelperService.getValue(realEstate?.creditPurpose, CreditPurpose.Other);
        const householdIds = HouseholdState.currentIds(householdState);

        const result = {
            readonly: !FinancingMapState.staticIsFinancingMapEditable(financingMap?.status),
            realEstateTransferTax: RealEstateState.realEstateTransferTax(realEstateState)(HelperService.getValueOrUndefiend(financingMap?.calculationVersion)),
            rightOfOwnership: RealEstateState.rightOfOwnership(realEstateState)(HelperService.getValueOrUndefiend(financingMap?.calculationVersion)),
            projectCostSum: FinancingMapState.projectCostSum(state, realEstateState, householdState, liabilityState),
            notaryFeeValue: FinancingMapState.notaryFeeAmount(state, realEstateState),
            brokerageValue: FinancingMapState.brokerageAmount(state, realEstateState),
            purchasingAdditionalCostsSum: FinancingMapState.purchasingAdditionalCostsSum(state, realEstateState),
            shortFinancingRequirement: FinancingMapState.shortFinancingRequirementById(state, realEstateState),
            longFinancingRequirementNetto: FinancingMapState.longFinancingRequirementNetto(state, realEstateState, householdState, liabilityState),
            processingExpenses: FinancingMapState.processingExpenses(state, realEstateState, householdState, liabilityState, masterDataState),
            estimateCharges: FinancingMapState.estimateCharges(state, realEstateState, householdState, liabilityState, masterDataState),
            registrationChargesIsFree: FinancingMapState.registrationChargesAmountIsFree(state, realEstateState, householdState, liabilityState),
            calculatedRegistrationChargesAmount: FinancingMapState.calculatedRegistrationChargesAmount(state, realEstateState, householdState, liabilityState, masterDataState),
            coveragePriorCredit: LiabilityState.coveragePriorCreditByHouseHolds(liabilityState)(householdIds, creditPurpose, HelperService.getValueOrUndefiend(financingMap?.calculationVersion)),
            legalisationFee: FinancingMapState.legalisationFee(state, realEstateState, householdState, liabilityState, masterDataState),
            prePurchasingAdditionalCostsSum: RealEstateState.prePurchasingAdditionalCostsSum(realEstateState)(HelperService.getValueOrUndefiend(financingMap?.calculationVersion)),
            realestateMarketValues: forSaleRealestates.map(realestate => ({ label: realestate.realEstate.name ? `Objekt ${realestate.index + 1} (${realestate.realEstate.name})` : `Objekt ${realestate.index + 1}` , marketValue: realestate.realEstate.marketValue })),
            realestateValueTotal: RealEstateState.currentSumSalesValue(realEstateState) ?? 0,
            realEstateCount: RealEstateState.current(realEstateState).length,
        } as ICalculationFinancingPlan;

        return result;
    }

    /**
     * berechnet Summe der Projektkosten
     *
     * @param {IHouseholdStateModel} state aktueller State
     * @param {IRealEstateStateModel} realEstateState aktueller RealEstate State
     * @param {IHouseholdStateModel} householdState aktueller Household State
     * @param {IHouseholdStateModel} liabilityState aktueller Liability State
     * @returns {number} Summe der Projektkosten
     */
    @Selector([RealEstateState, HouseholdState, LiabilityState])
    public static projectCostSum(
        state: IFinancingMapStateModel,
        realEstateState: IRealEstateStateModel,
        householdState: IHouseholdStateModel,
        liabilityState: ILiabilityStateModel,
    ): number {
        const financingMap = FinancingMapState.current(state);
        const realEstate = RealEstateState.currentFinancingObject(realEstateState);
        const purchasingAdditionalCostsSum = FinancingMapState.purchasingAdditionalCostsSum(state, realEstateState);
        const houseHoldIds = HouseholdState.currentIds(householdState);
        const creditPurpose = HelperService.getValue(realEstate?.creditPurpose, CreditPurpose.Other);
        const currentCoveragePriorCredit = LiabilityState.coveragePriorCreditByHouseHolds(liabilityState)(houseHoldIds, creditPurpose, HelperService.getValueOrUndefiend(financingMap?.calculationVersion));

        const realEstateCalculationService = CalculationFactoryService.realEstateCalculationService(HelperService.getValueOrUndefiend(financingMap?.calculationVersion));
        return realEstateCalculationService.sumProjectCosts({
            creditPurpose,
            currentAmountCoveredLiabilites: currentCoveragePriorCredit,
            sumAdditionalCosts: purchasingAdditionalCostsSum,
            purchasePrice: HelperService.getValueOrUndefiend(realEstate?.purchasePrice),
            lotPrice: HelperService.getValueOrUndefiend(realEstate?.lotPrice),
            developmentCosts: HelperService.getValueOrUndefiend(realEstate?.developmentCosts),
            constructionCosts: HelperService.getValueOrUndefiend(realEstate?.constructionCosts),
            refurbishmentCosts: HelperService.getValueOrUndefiend(realEstate?.refurbishmentCosts),
            constructionCostsOverrun: HelperService.getValueOrUndefiend(realEstate?.constructionCostsOverrun),
            otherCosts: HelperService.getValueOrUndefiend(realEstate?.otherCosts),
        });
    }

    /**
     * berechnet Schätzgebühr anhand Langfristiger Finanzierungsbedarf
     *
     * @param {IFinancingMapStateModel} state aktueller State
     * @param {IRealEstateStateModel} realEstateState aktueller RealEstate State
     * @param {IHouseholdStateModel} householdState aktueller Household State
     * @param {ILiabilityStateModel} liabilityState aktueller Liability State
     * @param {IMasterDataStateModel} masterDataState aktueller MasterData State
     * @returns {number} Schätzgebühr
     */
    @Selector([RealEstateState, HouseholdState, LiabilityState, MasterDataState])
    public static estimateCharges(
        state: IFinancingMapStateModel,
        realEstateState: IRealEstateStateModel,
        householdState: IHouseholdStateModel,
        liabilityState: ILiabilityStateModel,
        masterDataState: IMasterDataStateModel,
    ): number {
        const financingMap = FinancingMapState.current(state);
        const calculationVersion = HelperService.getValueOrUndefiend(financingMap?.calculationVersion);
        const grossFinancingRequirement = FinancingMapState.longFinancingRequirementBrutto(state, realEstateState, householdState, liabilityState, masterDataState);
        const financingMapCalculationService = CalculationFactoryService.financingMapCalculationService(calculationVersion);

        return financingMapCalculationService.estimateCharges({
            grossFinancingRequirement,
            estimateChargesPercent: financingMap?.valuationFeeIsPercent ? financingMap?.valuationFeeInput ?? undefined : undefined,
            estimateChargesAmount: !financingMap?.valuationFeeIsPercent ? financingMap?.valuationFeeInput ?? undefined : undefined,
        });
    }

    /**
     * berechnet Summe der Kaufnebenkosten
     *
     * @param {IHouseholdStateModel} state aktueller State
     * @param {IRealEstateStateModel} realEstateState aktueller RealEstate State
     * @returns {number} Summe der Kaufnebenkosten
     */
    @Selector([RealEstateState])
    public static purchasingAdditionalCostsSum(
        state: IFinancingMapStateModel,
        realEstateState: IRealEstateStateModel,
    ): number {
        const financingMap = FinancingMapState.current(state);
        const notaryFeeValue = FinancingMapState.notaryFeeAmount(state, realEstateState);
        const brokerageFeeValue = FinancingMapState.brokerageAmount(state, realEstateState);
        const realEstateTransferTax = RealEstateState.realEstateTransferTax(realEstateState)(HelperService.getValueOrUndefiend(financingMap?.calculationVersion));
        const rightOfOwnership = RealEstateState.rightOfOwnership(realEstateState)(HelperService.getValueOrUndefiend(financingMap?.calculationVersion));

        const realEstateCalculationService = CalculationFactoryService.realEstateCalculationService(HelperService.getValueOrUndefiend(financingMap?.calculationVersion));
        return realEstateCalculationService.sumAdditionalCosts({
            realEstateTaxes: realEstateTransferTax,
            landregisterEntry: rightOfOwnership,
            notaryCosts: notaryFeeValue,
            brokerageCosts: brokerageFeeValue,
        });
    }

    /**
     * berechnet Kosten für Errichtung Kaufvertrag/Treuhand (Notar)
     *
     * @param {IHouseholdStateModel} state aktueller State
     * @param {IRealEstateStateModel} realEstateState aktueller RealEstate State
     * @returns {number} Kosten für Errichtung Kaufvertrag/Treuhand
     */
    @Selector([RealEstateState])
    public static notaryFeeAmount(
        state: IFinancingMapStateModel,
        realEstateState: IRealEstateStateModel,
    ): number {
        const financingMap = FinancingMapState.current(state);
        const prePurchasingAdditionalCostsSum = RealEstateState.prePurchasingAdditionalCostsSum(realEstateState)(HelperService.getValueOrUndefiend(financingMap?.calculationVersion));

        const realEstateCalculationService = CalculationFactoryService.realEstateCalculationService(HelperService.getValueOrUndefiend(financingMap?.calculationVersion));
        return HelperService.getValue(financingMap?.notaryFeeIsPercent, ModelFactoryService.globalBooleanDefault) ?
            realEstateCalculationService.notaryCosts({
                sumPricesRelevantForAdditionalCosts: prePurchasingAdditionalCostsSum,
                notaryCostsFee: HelperService.getValueOrUndefiend(financingMap?.notaryFeeInput),
            }) :
            realEstateCalculationService.notaryCosts({
                sumPricesRelevantForAdditionalCosts: prePurchasingAdditionalCostsSum,
                notaryCostsAmount: HelperService.getValueOrUndefiend(financingMap?.notaryFeeInput),
            });
    }

    /**
     * berechnet Maklergebühr
     *
     * @param {IHouseholdStateModel} state aktueller State
     * @param {IRealEstateStateModel} realEstateState aktueller RealEstate State
     * @returns {number} Maklergebühr
     */
    @Selector([RealEstateState])
    public static brokerageAmount(
        state: IFinancingMapStateModel,
        realEstateState: IRealEstateStateModel,
    ): number {
        const financingMap = FinancingMapState.current(state);
        const prePurchasingAdditionalCostsSum = RealEstateState.prePurchasingAdditionalCostsSum(realEstateState)(HelperService.getValueOrUndefiend(financingMap?.calculationVersion));

        const realEstateCalculationService = CalculationFactoryService.realEstateCalculationService(HelperService.getValueOrUndefiend(financingMap?.calculationVersion));
        return HelperService.getValue(financingMap?.brokerageFeeIsPercent, ModelFactoryService.globalBooleanDefault) ?
            realEstateCalculationService.brokerageCosts({
                sumPricesRelevantForAdditionalCosts: prePurchasingAdditionalCostsSum,
                brokerageCostsFee: HelperService.getValueOrUndefiend(financingMap?.brokerageFeeInput),
            }) :
            realEstateCalculationService.brokerageCosts({
                sumPricesRelevantForAdditionalCosts: prePurchasingAdditionalCostsSum,
                brokerageCostsAmount: HelperService.getValueOrUndefiend(financingMap?.brokerageFeeInput),
            });
    }

    /**
     * berechnet Summe der Eigenmittel
     *
     * @param {IHouseholdStateModel} state aktueller State
     * @param {IRealEstateStateModel} realEstateState aktueller RealEstate State
     * @returns {number} Summe der Eigenmittel
     */
    @Selector([RealEstateState])
    public static ownCapitalSum(
        state: IFinancingMapStateModel,
        realEstateState: IRealEstateStateModel,
    ): number {
        const financingMap = FinancingMapState.current(state);
        const financingMapCalculationService = CalculationFactoryService.financingMapCalculationService(HelperService.getValueOrUndefiend(financingMap?.calculationVersion));

        return financingMapCalculationService.sumOwnCapital({
            cash: HelperService.getValueOrUndefiend(financingMap?.cash),
            salesRevenue: financingMapCalculationService.salesRevenue({
                reducedSalesRevenue: financingMap?.salesRevenue ?? undefined,
                realEstates: RealEstateState.current(realEstateState),
            }),
            redemptionInsurance: HelperService.getValueOrUndefiend(financingMap?.redemptionInsurance),
            bausparCreditBalance: HelperService.getValueOrUndefiend(financingMap?.bausparCreditBalance),
        });
    }

    /**
     * berechnet Kurzfristiger Finanzierungsbedarf
     *
     * @param {IHouseholdStateModel} state aktueller State
     * @param {IRealEstateStateModel} realEstateState aktueller RealEstate State
     * @returns {number} Kurzfristiger Finanzierungsbedarf
     */
    @Selector([RealEstateState])
    public static shortFinancingRequirementById(
        state: IFinancingMapStateModel,
        realEstateState: IRealEstateStateModel,

    ): number {
        const financingMap = FinancingMapState.current(state);
        const financingMapCalculationService = CalculationFactoryService.financingMapCalculationService(HelperService.getValueOrUndefiend(financingMap?.calculationVersion));

        return financingMapCalculationService.sumPrefinancing({
            prefinancingSales: HelperService.getValueOrUndefiend(financingMap?.prefinancingSales),
            salesRevenue: financingMapCalculationService.salesRevenue({
                reducedSalesRevenue: financingMap?.salesRevenue ?? undefined,
                realEstates: RealEstateState.current(realEstateState),
            }),
            prefinancingInsurance: HelperService.getValueOrUndefiend(financingMap?.prefinancingInsurance),
            redemptionInsurance: HelperService.getValueOrUndefiend(financingMap?.redemptionInsurance),
            prefinancingBausparCreditBalance: HelperService.getValueOrUndefiend(financingMap?.prefinancingBausparCreditBalance),
            bausparCreditBalance: HelperService.getValueOrUndefiend(financingMap?.bausparCreditBalance),
            prefinancingFunding: HelperService.getValueOrUndefiend(financingMap?.prefinancingFunding),
            funding: HelperService.getValueOrUndefiend(financingMap?.funding),
            prefinancingOtherOwnCapital: HelperService.getValueOrUndefiend(financingMap?.prefinancingOtherOwnCapital),
            otherOwnCapital: HelperService.getValueOrUndefiend(financingMap?.otherOwnCapital),
        });
    }

    /**
     * berechnet Langfristiger Finanzierungsbedarf Netto, mind. 0
     *
     * @param {IHouseholdStateModel} state aktueller State
     * @param {IRealEstateStateModel} realEstateState aktueller RealEstate State
     * @param {IHouseholdStateModel} householdState aktueller Household State
     * @param {IHouseholdStateModel} liabilityState aktueller Liability State
     * @returns {number} Langfristiger Finanzierungsbedarf Netto
     */
    @Selector([RealEstateState, HouseholdState, LiabilityState])
    public static longFinancingRequirementNetto(
        state: IFinancingMapStateModel,
        realEstateState: IRealEstateStateModel,
        householdState: IHouseholdStateModel,
        liabilityState: ILiabilityStateModel,
    ): number {
        const financingMap = FinancingMapState.current(state);
        const projectCostSum = FinancingMapState.projectCostSum(state, realEstateState, householdState, liabilityState);
        const ownCapitalSum = FinancingMapState.ownCapitalSum(state, realEstateState);
        const financingMapCalculationService = CalculationFactoryService.financingMapCalculationService(HelperService.getValueOrUndefiend(financingMap?.calculationVersion));

        return financingMapCalculationService.netFinancingRequirement({
            sumProjectCosts: projectCostSum,
            sumOwnCapital: ownCapitalSum,
            funding: HelperService.getValueOrUndefiend(financingMap?.funding),
            otherOwnCapital: HelperService.getValueOrUndefiend(financingMap?.otherOwnCapital),
        });
    }

    /**
     * berechnet Langfristiger Finanzierungsbedarf Netto, negatives ergebnis erlaubt
     *
     * @param {IHouseholdStateModel} state aktueller State
     * @param {IRealEstateStateModel} realEstateState aktueller RealEstate State
     * @param {IHouseholdStateModel} householdState aktueller Household State
     * @param {IHouseholdStateModel} liabilityState aktueller Liability State
     * @returns {number} Langfristiger Finanzierungsbedarf Netto
     */
    @Selector([RealEstateState, HouseholdState, LiabilityState])
    public static longFinancingRequirementNettoAllowNegativ(
        state: IFinancingMapStateModel,
        realEstateState: IRealEstateStateModel,
        householdState: IHouseholdStateModel,
        liabilityState: ILiabilityStateModel,
    ): number {
        const financingMap = FinancingMapState.current(state);
        const projectCostSum = FinancingMapState.projectCostSum(state, realEstateState, householdState, liabilityState);
        const ownCapitalSum = FinancingMapState.ownCapitalSum(state, realEstateState);
        const financingMapCalculationService = CalculationFactoryService.financingMapCalculationService(HelperService.getValueOrUndefiend(financingMap?.calculationVersion));

        return financingMapCalculationService.netFinancingRequirement({
            sumProjectCosts: projectCostSum,
            sumOwnCapital: ownCapitalSum,
            funding: HelperService.getValueOrUndefiend(financingMap?.funding),
            otherOwnCapital: HelperService.getValueOrUndefiend(financingMap?.otherOwnCapital),
            negativeValuesForbidden: false,
        });
    }

    /**
     * berechnet Langfristiger Finanzierungsbedarf Brutto
     *
     * @param {IHouseholdStateModel} state aktueller State
     * @param {IRealEstateStateModel} realEstateState aktueller RealEstate State
     * @param {IHouseholdStateModel} householdState aktueller Household State
     * @param {IHouseholdStateModel} liabilityState aktueller Liability State
     * @param {IMasterDataStateModel} masterDataState aktueller MasterData State
     * @returns {number} Langfristiger Finanzierungsbedarf Netto
     */
    @Selector([RealEstateState, HouseholdState, LiabilityState, MasterDataState])
    public static longFinancingRequirementBrutto(
        state: IFinancingMapStateModel,
        realEstateState: IRealEstateStateModel,
        householdState: IHouseholdStateModel,
        liabilityState: ILiabilityStateModel,
        masterDataState: IMasterDataStateModel,
    ): number {
        const financingMap = FinancingMapState.current(state);
        const longFinancingRequirementNetto = FinancingMapState.longFinancingRequirementNetto(state, realEstateState, householdState, liabilityState);
        const isFree = FinancingMapState.registrationChargesAmountIsFree(state, realEstateState, householdState, liabilityState);
        const financingMapCalculationService = CalculationFactoryService.financingMapCalculationService(HelperService.getValueOrUndefiend(financingMap?.calculationVersion));
        const financingCfg = FinancingMapState.currentFinacingConfiguration(state, masterDataState);

        return !!financingCfg ? financingMapCalculationService.grossFinancingRequirement({
            netFinancingRequirementNegativeValuesAllowed: longFinancingRequirementNetto,
            legalisationFee: FinancingMapState.legalisationFee(state, realEstateState, householdState, liabilityState, masterDataState),
            estimateChargesPercent: financingMap?.valuationFeeIsPercent ? financingMap?.valuationFeeInput ?? undefined : undefined,
            estimateChargesAmount: !financingMap?.valuationFeeIsPercent ? financingMap?.valuationFeeInput ?? undefined : undefined,
            processingChargesPercent: HelperService.getValueOrUndefiend(financingMap?.handlingFeeInput),
            registrationChargesPercent: isFree ? 0 : ModelFactoryService.registrationFeeLienValue,
            landRegisterRequest: financingCfg.landRegisterRequest,
            landRegisterExtract: financingCfg.landRegisterExtract,
        }) : 0;
    }

    /**
     * berechnet Bearbeitungsspesen
     *
     * @param {IHouseholdStateModel} state aktueller State
     * @param {IRealEstateStateModel} realEstateState aktueller RealEstate State
     * @param {IHouseholdStateModel} householdState aktueller Household State
     * @param {IHouseholdStateModel} liabilityState aktueller Liability State
     * @param {IMasterDataStateModel} masterDataState aktueller MasterData State
     * @returns {number} Bearbeitungsspesen
     */
    @Selector([RealEstateState, HouseholdState, LiabilityState, MasterDataState])
    public static processingExpenses(
        state: IFinancingMapStateModel,
        realEstateState: IRealEstateStateModel,
        householdState: IHouseholdStateModel,
        liabilityState: ILiabilityStateModel,
        masterDataState: IMasterDataStateModel,
    ): number {
        const financingMap = FinancingMapState.current(state);
        const grossFinancingRequirement = FinancingMapState.longFinancingRequirementBrutto(state, realEstateState, householdState, liabilityState, masterDataState);
        const financingMapCalculationService = CalculationFactoryService.financingMapCalculationService(HelperService.getValueOrUndefiend(financingMap?.calculationVersion));

        return financingMapCalculationService.processingCharges({
            grossFinancingRequirement,
            processingChargesPercent: HelperService.getValueOrUndefiend(financingMap?.handlingFeeInput),
        });
    }

    /**
     * ist Gebührenbefreiung Pfandrecht aktiviert
     *
     * @param {IHouseholdStateModel} state aktueller State
     * @param {IRealEstateStateModel} realEstateState aktueller RealEstate State
     * @param {IHouseholdStateModel} householdState aktueller Household State
     * @param {IHouseholdStateModel} liabilityState aktueller Liability State
     * @returns {boolean} Gebührenbefreiung Pfandrecht ist aktiviert
     */
    @Selector([RealEstateState, HouseholdState, LiabilityState])
    public static registrationChargesAmountIsFree(
        state: IFinancingMapStateModel,
        realEstateState: IRealEstateStateModel,
        householdState: IHouseholdStateModel,
        liabilityState: ILiabilityStateModel,
    ): boolean {
        const financingMap = FinancingMapState.current(state);
        const netto = FinancingMapState.longFinancingRequirementNetto(state, realEstateState, householdState, liabilityState);

        // explizites prüfen auf 0 um zu prüfen ob überhaupt ein wert gesetzt ist
        return financingMap?.registrationChargesPercent === 0 && netto > 0;
    }

    /**
     * berechnet Eintragungsgebühr
     *
     * @param {IHouseholdStateModel} state aktueller State
     * @param {IRealEstateStateModel} realEstateState aktueller RealEstate State
     * @param {IHouseholdStateModel} householdState aktueller Household State
     * @param {IHouseholdStateModel} liabilityState aktueller Liability State
     * @param {IMasterDataStateModel} masterDataState aktueller MasterData State
     * @returns {boolean} berechntete Eintragungsgebühr
     */
    @Selector([RealEstateState, HouseholdState, LiabilityState, MasterDataState])
    public static calculatedRegistrationChargesAmount(
        state: IFinancingMapStateModel,
        realEstateState: IRealEstateStateModel,
        householdState: IHouseholdStateModel,
        liabilityState: ILiabilityStateModel,
        masterDataState: IMasterDataStateModel,
    ): number {
        const financingMap = FinancingMapState.current(state);
        const grossFinancingRequirement = FinancingMapState.longFinancingRequirementBrutto(state, realEstateState, householdState, liabilityState, masterDataState);
        const financingMapCalculationService = CalculationFactoryService.financingMapCalculationService(HelperService.getValueOrUndefiend(financingMap?.calculationVersion));

        return financingMapCalculationService.registrationCharges({
            grossFinancingRequirement,
            registrationChargesPercent: HelperService.getValue(financingMap?.registrationChargesPercent, ModelFactoryService.registrationFeeLienValue),
        });
    }

    /**
     * berechnet Legalisierungsgebühr
     *
     * @param {IHouseholdStateModel} state aktueller State
     * @param {IRealEstateStateModel} realEstateState aktueller RealEstate State
     * @param {IHouseholdStateModel} householdState aktueller Household State
     * @param {IHouseholdStateModel} liabilityState aktueller Liability State
     * @param {IMasterDataStateModel} masterDataState aktueller MasterData State
     * @returns {number} Legalisierungsgebühr
     */
    @Selector([RealEstateState, HouseholdState, LiabilityState, MasterDataState])
    public static legalisationFee(
        state: IFinancingMapStateModel,
        realEstateState: IRealEstateStateModel,
        householdState: IHouseholdStateModel,
        liabilityState: ILiabilityStateModel,
        masterDataState: IMasterDataStateModel,
    ): number {
        const financingMap = FinancingMapState.current(state);
        const financingMapCalculationService = CalculationFactoryService.financingMapCalculationService(HelperService.getValueOrUndefiend(financingMap?.calculationVersion));
        const financingCfg = FinancingMapState.currentFinacingConfiguration(state, masterDataState);

        return !!financingCfg ? financingMapCalculationService.legalisationFee({
            legalisationFeeBases: MasterDataState.legalisationFeeBases(masterDataState),
            netFinancingRequirementNegativeValuesAllowed: FinancingMapState.longFinancingRequirementNettoAllowNegativ(state, realEstateState, householdState, liabilityState),
            estimateChargesPercent: financingMap?.valuationFeeIsPercent ? financingMap?.valuationFeeInput ?? undefined : undefined,
            estimateChargesAmount: !financingMap?.valuationFeeIsPercent ? financingMap?.valuationFeeInput ?? undefined : undefined,
            processingChargesPercent: HelperService.getValueOrUndefiend(financingMap?.handlingFeeInput),
            registrationChargesPercent: HelperService.getValueOrUndefiend(financingMap?.registrationChargesPercent),
            landRegisterRequest: financingCfg.landRegisterRequest,
            landRegisterExtract: financingCfg.landRegisterExtract,
        }) : 0;
    }

    //#endregion

    //#region Calculator Allgemein

    /**
     * gibt die Financing Configuration der aktuellen Finanzierung zurück
     *
     * @param {IFinancingMapStateModel} state aktueller State
     * @param {IMasterDataStateModel} masterDataState aktueller Masterdata State
     * @returns {IFinancingMapModel} die aktuell selektierte Financing Map
     */
    @Selector([MasterDataState])
    public static currentFinacingConfiguration(
        state: IFinancingMapStateModel,
        masterDataState: IMasterDataStateModel,
    ): IFinancingConfiguration | undefined {
        const financingMap = FinancingMapState.current(state);
        const financingConfigurationId = HelperService.getValueOrUndefiend(financingMap?.financingConfigurationId);
        return MasterDataState.determineFinancingConfiguration(masterDataState)(financingConfigurationId);
    }

    /**
     * gibt alle ergebnisse des Produktrechners zurück
     *
     * @param {IFinancingMapStateModel} state aktueller State
     * @param {IMasterDataStateModel} masterDataState aktueller MasterData State
     * @param {IRealEstateStateModel} realEstateState aktueller RealEstate State
     * @param {IHouseholdStateModel} householdState aktueller Household State
     * @param {ILiabilityStateModel} liabilityState aktueller Liability State
     * @param {INewLiabilityStateModel} newLiabilityState aktueller Liability State
     * @param {IDebtorStateModel} debtorState aktueller Debtor State
     * @returns {ICalculationCalculator} die berechnungen des Produktrechners
     */
    @Selector([MasterDataState, RealEstateState, HouseholdState, LiabilityState, NewLiabilityState, DebtorState])
    public static productCalculatorCalculations(
        state: IFinancingMapStateModel,
        masterDataState: IMasterDataStateModel,
        realEstateState: IRealEstateStateModel,
        householdState: IHouseholdStateModel,
        liabilityState: ILiabilityStateModel,
        newLiabilityState: INewLiabilityStateModel,
        debtorState: IDebtorStateModel,
    ): ICalculationCalculator {
        const financingMap = FinancingMapState.current(state);
        const calculationVersion = HelperService.getValueOrUndefiend(financingMap?.calculationVersion);
        const configuration = FinancingMapState.currentFinacingConfiguration(state, masterDataState);

        if (!!financingMap) {
            return ({
                readonly: !FinancingMapState.staticIsFinancingMapEditable(financingMap?.status),
                defaultDebitRate: !HelperService.hasValue(financingMap?.requestedDebitRate) ? configuration?.requestedDebitRateDefault : undefined,
                anyDebitorInRetirementWithoutNote: FinancingMapState.inRetirementWithoutNotes(state, debtorState),
                referenceInterest: FinancingMapState.referenceInterest(state, masterDataState, realEstateState, householdState, liabilityState, debtorState),
                realEstateType: RealEstateState.currentFinancingObject(realEstateState)?.type,
                mustSplit: FinancingMapState.mustUseSplitting(state, masterDataState, realEstateState, householdState, liabilityState, newLiabilityState, debtorState),
                creditAmount: FinancingMapState.creditAmount(state, masterDataState, realEstateState, householdState, liabilityState, newLiabilityState, debtorState),
                creditPlusAmount: FinancingMapState.creditPlusAmount(state, masterDataState, realEstateState, householdState, liabilityState, newLiabilityState, debtorState),
                handlingFeeAmount: FinancingMapState.handlingFeeAmount(state, masterDataState, realEstateState, householdState, liabilityState, newLiabilityState, debtorState),
                valuationFeeAmount: FinancingMapState.valuationFeeAmount(state, masterDataState, realEstateState, householdState, liabilityState, newLiabilityState, debtorState),
                registrationChargesAmount: FinancingMapState.registrationChargesAmount(state, masterDataState, realEstateState, householdState, liabilityState, newLiabilityState, debtorState),
                legalisationFeeAmount: FinancingMapState.legalisationFeeAmount(state, masterDataState, realEstateState, householdState, liabilityState, newLiabilityState, debtorState),
                reasonableLoanRate: HouseholdState.reasonableLoanRateArray(householdState, debtorState, liabilityState, newLiabilityState)(calculationVersion).reduce((acc, curr) => acc + curr, 0),
                payoutAmount: FinancingMapState.payoutAmount(state, masterDataState, realEstateState, householdState, liabilityState, newLiabilityState, debtorState),
                handlingFeePlusAmount: FinancingMapState.handlingFeePlusAmount(state, masterDataState, realEstateState, householdState, liabilityState, newLiabilityState, debtorState),
                payoutPlusAmount: FinancingMapState.payoutPlusAmount(state, masterDataState, realEstateState, householdState, liabilityState, newLiabilityState, debtorState),
                monthlyRateConfort: FinancingMapState.monthlyRateConfort(state, masterDataState, realEstateState, householdState, liabilityState, newLiabilityState, debtorState),
                interestRateConfort: FinancingMapState.interestRateConfort(state, masterDataState, realEstateState, householdState, liabilityState, newLiabilityState, debtorState),
                monthlyRateConfortPlus: FinancingMapState.monthlyRateConfortPlus(state, masterDataState, realEstateState, householdState, liabilityState, newLiabilityState, debtorState),
                totalRateTo10: FinancingMapState.totalRateTo10(state, masterDataState, realEstateState, householdState, liabilityState, newLiabilityState, debtorState),
                totalRateFrom11: FinancingMapState.totalRateFrom11(state, masterDataState, realEstateState, householdState, liabilityState, newLiabilityState, debtorState),
                totalCreditAmount: FinancingMapState.totalCreditAmount(state, masterDataState, realEstateState, householdState, liabilityState, newLiabilityState, debtorState),
                totalPayoutAmount: FinancingMapState.totalPayoutAmount(state, masterDataState, realEstateState, householdState, liabilityState, newLiabilityState, debtorState),
                fictitiousRate: FinancingMapState.fictitiousRate(state, masterDataState),
                fictitiousRateAmount: FinancingMapState.fictitiousRateAmount(state, masterDataState, realEstateState, householdState, liabilityState, newLiabilityState, debtorState),
                fictitiousRatePlus: FinancingMapState.fictitiousRatePlus(state, masterDataState, realEstateState, householdState, liabilityState, newLiabilityState, debtorState),
                fictitiousRatePlusAmount: FinancingMapState.fictitiousRatePlusAmount(state, masterDataState, realEstateState, householdState, liabilityState, newLiabilityState, debtorState),
                totalFictitiousRate: FinancingMapState.totalFictitiousRate(state, masterDataState, realEstateState, householdState, liabilityState, newLiabilityState, debtorState),
                fictitiousRateCoveredByAllHousehold: FinancingMapState.fictitiousRateCoveredByAllHousehold(state, masterDataState, realEstateState, householdState, liabilityState, newLiabilityState, debtorState),
            }) as ICalculationCalculator;
        }
        else {
            return {} as ICalculationCalculator;
        }
        // return {} as ICalculationCalculator;
    }

    /**
     * gibt alle zwischenergebnisse raus
     *
     * @param {IFinancingMapStateModel} state aktueller State
     * @param {IMasterDataStateModel} masterDataState aktueller MasterData State
     * @param {IRealEstateStateModel} realEstateState aktueller RealEstate State
     * @param {IHouseholdStateModel} householdState aktueller Household State
     * @param {ILiabilityStateModel} liabilityState aktueller Liability State
     * @param {INewLiabilityStateModel} newLiabilityState aktueller Liability State
     * @param {IDebtorStateModel} debtorState aktueller Debtor State
     * @returns {ICalculationCalculator} die berechnungen des Produktrechners
     */
    @Selector([MasterDataState, RealEstateState, HouseholdState, LiabilityState, NewLiabilityState, DebtorState])
    public static interimResults(
        state: IFinancingMapStateModel,
        masterDataState: IMasterDataStateModel,
        realEstateState: IRealEstateStateModel,
        householdState: IHouseholdStateModel,
        liabilityState: ILiabilityStateModel,
        newLiabilityState: INewLiabilityStateModel,
        debtorState: IDebtorStateModel,
    ): IInterimResults {
        const financingMap = FinancingMapState.current(state);
        const financingConfig = FinancingMapState.currentFinacingConfiguration(state, masterDataState);

        if (!!financingMap) {
            const calculationVersion = HelperService.getValueOrUndefiend(financingMap.calculationVersion);
            const comfortProductCalculationService = CalculationFactoryService.comfortProductCalculationService(calculationVersion);
            const realEstateCalculationService = CalculationFactoryService.realEstateCalculationService(calculationVersion);

            const realEstate = RealEstateState.currentFinancingObject(realEstateState);
            const householdIds = HouseholdState.currentIds(householdState);
            const realEstateModels = RealEstateState.currentWithMorixRating(realEstateState, masterDataState)(calculationVersion);

            const marketValue = !!realEstate ? RealEstateState.marketValueById(realEstateState)(realEstate.id, calculationVersion) : undefined;
            const marketValueSum = RealEstateState.marketValueSumForLTV(realEstateState)(calculationVersion);
            const morixRating = realEstateCalculationService.getMorixRatingFromCollateralizationNewFinancingWithHighestMarketValue({ realEstates: realEstateModels });

            const sumIncome = HouseholdState.commonIncomeSum(householdState, debtorState)(calculationVersion);
            const debitorsCount = DebtorState.current(debtorState).length;
            const ltvFactor = comfortProductCalculationService.ltvFactor({
                splittingRules: !!financingConfig ? financingConfig.splittingRules : [],
                debitorsCount,
                netIncome: sumIncome,
                morixRating,
            });

            const ltvCalculation = FinancingMapState.ltvCalculation(state, masterDataState, realEstateState, householdState, liabilityState, newLiabilityState);

            const amountL = LiabilityState.liabilityAmountOfLandRegister(liabilityState)(calculationVersion);
            const amountNL = NewLiabilityState.newLiabilityAmountOfLandRegister(newLiabilityState)(calculationVersion);

            const grossFinancingRequirement = FinancingMapState.longFinancingRequirementBrutto(state, realEstateState, householdState, liabilityState, masterDataState);
            const purchasingAdditionalCosts = FinancingMapState.purchasingAdditionalCostsSum(state, realEstateState);
            const ownCapitalSum = FinancingMapState.ownCapitalSum(state, realEstateState);
            const mustSplit = FinancingMapState.mustUseSplitting(state, masterDataState, realEstateState, householdState, liabilityState, newLiabilityState, debtorState);

            const comfortCredit1 = comfortProductCalculationService.comfortCredit1({
                currentAmountSecuredByLandRegisterNotCoveredLiabilites: amountL,
                amountSecuredByLandRegisterNewLiabilites: amountNL,
                marketValue: marketValueSum,
                ltvFactor,
            });

            const comfortCredit2 = comfortProductCalculationService.comfortCredit2({
                grossFinancingRequirement,
                sumAdditionalCosts: purchasingAdditionalCosts,
                sumOwnCapital: ownCapitalSum,
                otherCosts: HelperService.getValueOrUndefiend(realEstate?.otherCosts),
                funding: HelperService.getValueOrUndefiend(financingMap?.funding),
                otherOwnCapital: HelperService.getValueOrUndefiend(financingMap?.otherOwnCapital),
            });

            const currentCreditAmount = FinancingMapState.creditAmount(state, masterDataState, realEstateState, householdState, liabilityState, newLiabilityState, debtorState);

            const creditPlusAmount = comfortProductCalculationService.comfortCreditPlus({
                splitting: mustSplit,
                grossFinancingRequirement,
                comfortCredit: currentCreditAmount,
            });

            return {
                ltvFactor,
                mustSplit,
                comfortCredit1,
                comfortCredit2,
                grossFinancingRequirement,
                currentCreditAmount,
                creditPlusAmount,
                ownCapitalSum,
                purchasingAdditionalCosts,
                sumIncomePerHousehold: householdIds.map(householdId => DebtorState.commonIncomeSumByHouseHoldId(debtorState)(householdId, calculationVersion)),
                sumIncome,
                marketValue: marketValue,
                marketValueOriginal: realEstate?.marketValue,
                marketValueSumForLTV: marketValueSum,
                morixRating,
                amountLiabilities: amountL,
                amountNewLiabilities: amountNL,
                ltvCalculation,
            };
        }
        else {
            return {} as IInterimResults;
        }
    }

    /**
     * prüft ob es irgendeinen Kreditnehmer gibt der das Renteneintritsalter bei Laufzeitende erreichen würde,
     * welcher noch nicht die Anmerkung befüllt hat
     *
     * @param {IFinancingMapStateModel} state aktueller State
     * @param {IHouseholdStateModel} debtorState aktueller Debtor State
     * @returns {boolean} gibt es Pensionisten ohne Notiz
     */
    @Selector([HouseholdState, DebtorState])
    public static inRetirementWithoutNotes(
        state: IFinancingMapStateModel,
        debtorState: IDebtorStateModel,
    ): boolean {
        const financingMap = FinancingMapState.current(state);
        const debtors = DebtorState.current(debtorState);
        return !!financingMap && debtors.some(({ birthday, creditDurationInRetirement }) => {
            const age = !!birthday ? debCalculation.ageDurationEnd({
                birthday: new Date(birthday),
                assumedDuration: HelperService.getValueOrUndefiend(financingMap.assumedDuration),
            }) : undefined;

            return HelperService.hasValue(age) && age >= GlobalSettings.retirementAge &&
                HelperService.isNullOrWhitespaces(creditDurationInRetirement);
        });
    }

    /**
     * Berechnet den aktuellen LTV
     *
     * @param {IFinancingMapStateModel} state aktueller State
     * @param {IMasterDataStateModel} masterDataState aktueller MasterData State
     * @param {IRealEstateStateModel} realEstateState aktueller RealEstate State
     * @param {IHouseholdStateModel} householdState aktueller Household State
     * @param {ILiabilityStateModel} liabilityState aktueller Liability State
     * @param {INewLiabilityStateModel} newLiabilityState aktueller NewLiability State
     * @returns {number} aktueller LTV
     */
    @Selector([MasterDataState, RealEstateState, HouseholdState, LiabilityState, NewLiabilityState])
    public static ltvCalculation(
        state: IFinancingMapStateModel,
        masterDataState: IMasterDataStateModel,
        realEstateState: IRealEstateStateModel,
        householdState: IHouseholdStateModel,
        liabilityState: ILiabilityStateModel,
        newLiabilityState: INewLiabilityStateModel,
    ): number {
        const financingMap = FinancingMapState.current(state);
        const realestates = RealEstateState.current(realEstateState);
        const calculationVersion = HelperService.getValueOrUndefiend(financingMap?.calculationVersion);

        if (RealEstateState.marketValueSumForLTV(realEstateState)(calculationVersion) > 0) {
            const grossFinancingRequirement = FinancingMapState.longFinancingRequirementBrutto(state, realEstateState, householdState, liabilityState, masterDataState);
            const comfortProductCalculationService = CalculationFactoryService.comfortProductCalculationService(calculationVersion);
            return comfortProductCalculationService.ltv({
                grossFinancingRequirement,
                liabilities: LiabilityState.asInputModel(liabilityState),
                newLiabilities: NewLiabilityState.asInputModel(newLiabilityState).map(nl => ({ ...nl, amount: nl.amount ?? 0 })),
                realEstates: realestates.map(re => RealEstateState.asInputModel(re)),
            });
        }
        else {
            return 0;
        }
    }

    /**
     * Prüft ob der Kredit in Komfortkredit und Komfortkredit Plus gesplittet werden muss
     *
     * @param {IFinancingMapStateModel} state aktueller State
     * @param {IMasterDataStateModel} masterDataState aktueller MasterData State
     * @param {IRealEstateStateModel} realEstateState aktueller RealEstate State
     * @param {IHouseholdStateModel} householdState aktueller Household State
     * @param {ILiabilityStateModel} liabilityState aktueller Liability State
     * @param {INewLiabilityStateModel} newLiabilityState aktueller NewLiability State
     * @param {IDebtorStateModel} debtorState aktueller Debtor State
     * @returns {boolean} muss gesplittet werden
     */
    @Selector([MasterDataState, RealEstateState, HouseholdState, LiabilityState, NewLiabilityState, DebtorState])
    public static mustUseSplitting(
        state: IFinancingMapStateModel,
        masterDataState: IMasterDataStateModel,
        realEstateState: IRealEstateStateModel,
        householdState: IHouseholdStateModel,
        liabilityState: ILiabilityStateModel,
        newLiabilityState: INewLiabilityStateModel,
        debtorState: IDebtorStateModel,
    ): boolean {
        const financingMap = FinancingMapState.current(state);
        const calculationVersion = HelperService.getValueOrUndefiend(financingMap?.calculationVersion);
        const financingConfig = FinancingMapState.currentFinacingConfiguration(state, masterDataState);
        const realEstateModels = RealEstateState.currentWithMorixRating(realEstateState, masterDataState)(calculationVersion);

        const comfortProductCalculationService = CalculationFactoryService.comfortProductCalculationService(calculationVersion);
        const realEstateCalculationService = CalculationFactoryService.realEstateCalculationService(calculationVersion);

        const debitorsCount = DebtorState.current(debtorState).length;
        const ltv = FinancingMapState.ltvCalculation(state, masterDataState, realEstateState, householdState, liabilityState, newLiabilityState);
        const sumIncome = HouseholdState.commonIncomeSum(householdState, debtorState)(calculationVersion);
        const morixRating = realEstateCalculationService.getMorixRatingFromCollateralizationNewFinancingWithHighestMarketValue({ realEstates: realEstateModels });

        const useLtvSplitting = comfortProductCalculationService.useLtvSplitting({
            splittingRules: !!financingConfig ? financingConfig.splittingRules : [],
            debitorsCount,
            ltv,
            netIncome: sumIncome,
            morixRating,
        });

        const grossFinancingRequirement = FinancingMapState.longFinancingRequirementBrutto(state, realEstateState, householdState, liabilityState, masterDataState);
        const currentCredit = FinancingMapState.creditBaseAmount(state, masterDataState, realEstateState, householdState, liabilityState, newLiabilityState, debtorState)();

        return comfortProductCalculationService.useProductSplitting({
            grossFinancingRequirement,
            comfortCredit: currentCredit,
            useLtvSplitting,
            useRatioSplitting: false, // wird nicht mehr benötigt #2189
        });
    }

    //#endregion

    //#region Calculator Card ComfortCredit

    /**
     * Berechnet den KomfortKredit unter berücksichtigung des übergebenen splittings
     *
     * @param {IFinancingMapStateModel} state aktueller State
     * @param {IMasterDataStateModel} masterDataState aktueller MasterData State
     * @param {IRealEstateStateModel} realEstateState aktueller RealEstate State
     * @param {IHouseholdStateModel} householdState aktueller Household State
     * @param {ILiabilityStateModel} liabilityState aktueller Liability State
     * @param {INewLiabilityStateModel} newLiabilityState aktueller NewLiability State
     * @param {IDebtorStateModel} debtorState aktueller Debtor State
     * @returns {(splitting?: boolean) => number} callback für berechnung
     */
    @Selector([MasterDataState, RealEstateState, HouseholdState, LiabilityState, NewLiabilityState, DebtorState])
    public static creditBaseAmount(
        state: IFinancingMapStateModel,
        masterDataState: IMasterDataStateModel,
        realEstateState: IRealEstateStateModel,
        householdState: IHouseholdStateModel,
        liabilityState: ILiabilityStateModel,
        newLiabilityState: INewLiabilityStateModel,
        debtorState: IDebtorStateModel,
    ): (splitting?: boolean) => number {

        /**
         * Callbackfunktion zur Berechnung des KomfortKredit unter berücksichtigung des übergebenen splittings
         *
         * @param {boolean} splitting soll splitting angewendet werden
         * @returns {number} berechneter KomfortKredit
         */
        return (splitting?: boolean): number => {
            const financingMap = FinancingMapState.current(state);
            const realEstate = RealEstateState.currentFinancingObject(realEstateState);
            const calculationVersion = HelperService.getValueOrUndefiend(financingMap?.calculationVersion);
            const financingConfig = FinancingMapState.currentFinacingConfiguration(state, masterDataState);
            const realEstateModels = RealEstateState.currentWithMorixRating(realEstateState, masterDataState)(calculationVersion);

            const comfortProductCalculationService = CalculationFactoryService.comfortProductCalculationService(calculationVersion);
            const realEstateCalculationService = CalculationFactoryService.realEstateCalculationService(calculationVersion);
            const morixRating = realEstateCalculationService.getMorixRatingFromCollateralizationNewFinancingWithHighestMarketValue({ realEstates: realEstateModels });

            const marketValueSum = RealEstateState.marketValueSumForLTV(realEstateState)(calculationVersion);

            if (marketValueSum !== undefined && marketValueSum > 0) {
                const sumIncome = HouseholdState.commonIncomeSum(householdState, debtorState)(calculationVersion);
                const debitorsCount = DebtorState.current(debtorState).length;
                const ltvFactor = comfortProductCalculationService.ltvFactor({
                    splittingRules: !!financingConfig ? financingConfig.splittingRules : [],
                    debitorsCount,
                    netIncome: sumIncome,
                    morixRating,
                });

                const grossFinancingRequirement = FinancingMapState.longFinancingRequirementBrutto(state, realEstateState, householdState, liabilityState, masterDataState);
                const purchasingAdditionalCosts = FinancingMapState.purchasingAdditionalCostsSum(state, realEstateState);
                const ownCapitalSum = FinancingMapState.ownCapitalSum(state, realEstateState);

                const amountL = LiabilityState.liabilityAmountOfLandRegister(liabilityState)(calculationVersion);
                const amountNL = NewLiabilityState.newLiabilityAmountOfLandRegister(newLiabilityState)(calculationVersion);

                const comfortCredit1 = comfortProductCalculationService.comfortCredit1({
                    currentAmountSecuredByLandRegisterNotCoveredLiabilites: amountL,
                    amountSecuredByLandRegisterNewLiabilites: amountNL,
                    marketValue: marketValueSum,
                    ltvFactor,
                });

                const comfortCredit2 = comfortProductCalculationService.comfortCredit2({
                    grossFinancingRequirement,
                    sumAdditionalCosts: purchasingAdditionalCosts,
                    sumOwnCapital: ownCapitalSum,
                    otherCosts: HelperService.getValueOrUndefiend(realEstate?.otherCosts),
                    funding: HelperService.getValueOrUndefiend(financingMap?.funding),
                    otherOwnCapital: HelperService.getValueOrUndefiend(financingMap?.otherOwnCapital),
                });

                return comfortProductCalculationService.comfortCredit({
                    comfortCredit1,
                    comfortCredit2,
                    grossFinancingRequirement,
                    splitting,
                });
            }
            else {
                return 0;
            }
        };
    }

    /**
     * Berechnet den KomfortKredit
     *
     * @param {IFinancingMapStateModel} state aktueller State
     * @param {IMasterDataStateModel} masterDataState aktueller MasterData State
     * @param {IRealEstateStateModel} realEstateState aktueller RealEstate State
     * @param {IHouseholdStateModel} householdState aktueller Household State
     * @param {ILiabilityStateModel} liabilityState aktueller Liability State
     * @param {INewLiabilityStateModel} newLiabilityState aktueller NewLiability State
     * @param {IDebtorStateModel} debtorState aktueller Debtor State
     * @returns {number} der KomfortKredit
     */
    @Selector([MasterDataState, RealEstateState, HouseholdState, LiabilityState, NewLiabilityState, DebtorState])
    public static creditAmount(
        state: IFinancingMapStateModel,
        masterDataState: IMasterDataStateModel,
        realEstateState: IRealEstateStateModel,
        householdState: IHouseholdStateModel,
        liabilityState: ILiabilityStateModel,
        newLiabilityState: INewLiabilityStateModel,
        debtorState: IDebtorStateModel,
    ): number {
        const mustSplit = FinancingMapState.mustUseSplitting(state, masterDataState, realEstateState, householdState, liabilityState, newLiabilityState, debtorState);
        return FinancingMapState.creditBaseAmount(state, masterDataState, realEstateState, householdState, liabilityState, newLiabilityState, debtorState)(mustSplit);
    }

    /**
     * berechnet Bearbeitungsspesen des KomfortKredit
     *
     * @param {IFinancingMapStateModel} state aktueller State
     * @param {IMasterDataStateModel} masterDataState aktueller MasterData State
     * @param {IRealEstateStateModel} realEstateState aktueller RealEstate State
     * @param {IHouseholdStateModel} householdState aktueller Household State
     * @param {ILiabilityStateModel} liabilityState aktueller Liability State
     * @param {INewLiabilityStateModel} newLiabilityState aktueller NewLiability State
     * @param {IDebtorStateModel} debtorState aktueller Debtor State
     * @returns {number} Bearbeitungsspesen
     */
    @Selector([MasterDataState, RealEstateState, HouseholdState, LiabilityState, NewLiabilityState, DebtorState])
    public static handlingFeeAmount(
        state: IFinancingMapStateModel,
        masterDataState: IMasterDataStateModel,
        realEstateState: IRealEstateStateModel,
        householdState: IHouseholdStateModel,
        liabilityState: ILiabilityStateModel,
        newLiabilityState: INewLiabilityStateModel,
        debtorState: IDebtorStateModel,
    ): number {
        const financingMap = FinancingMapState.current(state);
        const calculationVersion = HelperService.getValueOrUndefiend(financingMap?.calculationVersion);
        const currentCreditAmount = FinancingMapState.creditAmount(state, masterDataState, realEstateState, householdState, liabilityState, newLiabilityState, debtorState);
        const comfortProductCalculationService = CalculationFactoryService.comfortProductCalculationService(calculationVersion);
        return comfortProductCalculationService.comfortCreditProcessingCharges({
            grossFinancingRequirement: 0, // wird intern nicht verwendet wenn processingChargesPercent gesetzt ist
            comfortCredit: currentCreditAmount,
            processingChargesGrossFinancingRequirement: financingMap?.handlingFeeIsPercent === false ? financingMap.handlingFeeInput ?? undefined : undefined,
            processingChargesPercent: financingMap?.handlingFeeIsPercent ? financingMap.handlingFeeInput ?? undefined : undefined,
        });
    }

    /**
     * berechnet Schätzgebühr KomfortKredit Ergebnis mind. 300€
     *
     * @param {IFinancingMapStateModel} state aktueller State
     * @param {IMasterDataStateModel} masterDataState aktueller MasterData State
     * @param {IRealEstateStateModel} realEstateState aktueller RealEstate State
     * @param {IHouseholdStateModel} householdState aktueller Household State
     * @param {ILiabilityStateModel} liabilityState aktueller Liability State
     * @param {INewLiabilityStateModel} newLiabilityState aktueller NewLiability State
     * @param {IDebtorStateModel} debtorState aktueller Debtor State
     * @returns {number} Schätzgebühr
     */
    @Selector([MasterDataState, RealEstateState, HouseholdState, LiabilityState, NewLiabilityState, DebtorState])
    public static valuationFeeAmount(
        state: IFinancingMapStateModel,
        masterDataState: IMasterDataStateModel,
        realEstateState: IRealEstateStateModel,
        householdState: IHouseholdStateModel,
        liabilityState: ILiabilityStateModel,
        newLiabilityState: INewLiabilityStateModel,
        debtorState: IDebtorStateModel,
    ): number {
        const financingMap = FinancingMapState.current(state);
        const calculationVersion = HelperService.getValueOrUndefiend(financingMap?.calculationVersion);
        const currentCreditAmount = FinancingMapState.creditAmount(state, masterDataState, realEstateState, householdState, liabilityState, newLiabilityState, debtorState);
        const comfortProductCalculationService = CalculationFactoryService.comfortProductCalculationService(calculationVersion);
        return comfortProductCalculationService.comfortCreditEstimateCharges({
            grossFinancingRequirement: 0, // wird intern nicht verwendet wenn estimateChargesPercent gesetzt ist
            comfortCredit: currentCreditAmount,
            estimateChargesPercent: financingMap?.valuationFeeIsPercent ? financingMap?.valuationFeeInput ?? undefined : undefined,
            estimateChargesGrossFinancingRequirement: !financingMap?.valuationFeeIsPercent ? financingMap?.valuationFeeInput ?? undefined : undefined,
        });
    }

    /**
     * berechnet Eintragungsgebühr KomfortKredit
     *
     * @param {IFinancingMapStateModel} state aktueller State
     * @param {IMasterDataStateModel} masterDataState aktueller MasterData State
     * @param {IRealEstateStateModel} realEstateState aktueller RealEstate State
     * @param {IHouseholdStateModel} householdState aktueller Household State
     * @param {ILiabilityStateModel} liabilityState aktueller Liability State
     * @param {INewLiabilityStateModel} newLiabilityState aktueller NewLiability State
     * @param {IDebtorStateModel} debtorState aktueller Debtor State
     * @returns {number} Eintragungsgebühr
     */
    @Selector([MasterDataState, RealEstateState, HouseholdState, LiabilityState, NewLiabilityState, DebtorState])
    public static registrationChargesAmount(
        state: IFinancingMapStateModel,
        masterDataState: IMasterDataStateModel,
        realEstateState: IRealEstateStateModel,
        householdState: IHouseholdStateModel,
        liabilityState: ILiabilityStateModel,
        newLiabilityState: INewLiabilityStateModel,
        debtorState: IDebtorStateModel,
    ): number {
        if (!FinancingMapState.registrationChargesAmountIsFree(state, realEstateState, householdState, liabilityState)) {
            const financingMap = FinancingMapState.current(state);
            const calculationVersion = HelperService.getValueOrUndefiend(financingMap?.calculationVersion);
            const currentCreditAmount = FinancingMapState.creditAmount(state, masterDataState, realEstateState, householdState, liabilityState, newLiabilityState, debtorState);
            const comfortProductCalculationService = CalculationFactoryService.comfortProductCalculationService(calculationVersion);
            return comfortProductCalculationService.comfortCreditRegistrationCharges({
                grossFinancingRequirement: 0, // wird intern nicht verwendet wenn registrationChargesPercent gesetzt ist
                comfortCredit: currentCreditAmount,
                registrationChargesPercent: financingMap?.registrationChargesPercent ?? ModelFactoryService.registrationFeeLienValue,
            });
        }
        else {
            return 0;
        }
    }

    /**
     * berechnet Legalisierungsgebühr
     *
     * @param {IFinancingMapStateModel} state aktueller State
     * @param {IMasterDataStateModel} masterDataState aktueller MasterData State
     * @param {IRealEstateStateModel} realEstateState aktueller RealEstate State
     * @param {IHouseholdStateModel} householdState aktueller Household State
     * @param {ILiabilityStateModel} liabilityState aktueller Liability State
     * @param {INewLiabilityStateModel} newLiabilityState aktueller NewLiability State
     * @param {IDebtorStateModel} debtorState aktueller Debtor State
     * @returns {number} Legalisierungsgebühr
     */
    @Selector([MasterDataState, RealEstateState, HouseholdState, LiabilityState, NewLiabilityState, DebtorState])
    public static legalisationFeeAmount(
        state: IFinancingMapStateModel,
        masterDataState: IMasterDataStateModel,
        realEstateState: IRealEstateStateModel,
        householdState: IHouseholdStateModel,
        liabilityState: ILiabilityStateModel,
        newLiabilityState: INewLiabilityStateModel,
        debtorState: IDebtorStateModel,
    ): number {
        const financingMap = FinancingMapState.current(state);
        const calculationVersion = HelperService.getValueOrUndefiend(financingMap?.calculationVersion);
        const comfortProductCalculationService = CalculationFactoryService.comfortProductCalculationService(calculationVersion);
        const financingCfg = FinancingMapState.currentFinacingConfiguration(state, masterDataState);
        const currentCreditAmount = FinancingMapState.creditAmount(state, masterDataState, realEstateState, householdState, liabilityState, newLiabilityState, debtorState);

        return !!financingCfg ? comfortProductCalculationService.comfortCreditLegalisationFee({
            comfortCredit: currentCreditAmount,
            legalisationFeeBases: MasterDataState.legalisationFeeBases(masterDataState),
        }) ?? 0 : 0;
    }

    /**
     * berechnet Auszahlungsbetrag KomfortKredit
     *
     * @param {IFinancingMapStateModel} state aktueller State
     * @param {IMasterDataStateModel} masterDataState aktueller MasterData State
     * @param {IRealEstateStateModel} realEstateState aktueller RealEstate State
     * @param {IHouseholdStateModel} householdState aktueller Household State
     * @param {ILiabilityStateModel} liabilityState aktueller Liability State
     * @param {INewLiabilityStateModel} newLiabilityState aktueller NewLiability State
     * @param {IDebtorStateModel} debtorState aktueller Debtor State
     * @returns {number} Auszahlungsbetrag
     */
    @Selector([MasterDataState, RealEstateState, HouseholdState, LiabilityState, NewLiabilityState, DebtorState])
    public static payoutAmount(
        state: IFinancingMapStateModel,
        masterDataState: IMasterDataStateModel,
        realEstateState: IRealEstateStateModel,
        householdState: IHouseholdStateModel,
        liabilityState: ILiabilityStateModel,
        newLiabilityState: INewLiabilityStateModel,
        debtorState: IDebtorStateModel,
    ): number {
        const financingMap = FinancingMapState.current(state);
        const calculationVersion = HelperService.getValueOrUndefiend(financingMap?.calculationVersion);

        const currentCreditAmount = FinancingMapState.creditAmount(state, masterDataState, realEstateState, householdState, liabilityState, newLiabilityState, debtorState);
        const handlingFeeAmount = FinancingMapState.handlingFeeAmount(state, masterDataState, realEstateState, householdState, liabilityState, newLiabilityState, debtorState);
        const valuationFeeAmount = FinancingMapState.valuationFeeAmount(state, masterDataState, realEstateState, householdState, liabilityState, newLiabilityState, debtorState);
        const registrationChargesAmount = FinancingMapState.registrationChargesAmount(state, masterDataState, realEstateState, householdState, liabilityState, newLiabilityState, debtorState);
        const legalisationFeeAmount = FinancingMapState.legalisationFeeAmount(state, masterDataState, realEstateState, householdState, liabilityState, newLiabilityState, debtorState);
        const configuration = FinancingMapState.currentFinacingConfiguration(state, masterDataState);

        const comfortProductCalculationService = CalculationFactoryService.comfortProductCalculationService(calculationVersion);
        return !!configuration ? comfortProductCalculationService.comfortCreditPayout({
            comfortCredit: currentCreditAmount,
            comfortCreditProcessingCharges: handlingFeeAmount,
            comfortCreditEstimateCharges: valuationFeeAmount,
            comfortCreditRegistrationCharges: registrationChargesAmount,
            legalisationFee: legalisationFeeAmount,
            landRegisterRequest: configuration.landRegisterRequest,
            landRegisterExtract: configuration.landRegisterExtract,
        }) : 0;
    }

    /**
     * berechnet und gibt die aktuelle Zinsindekation (Zinsvorschlag) zurück
     *
     * @param {IFinancingMapStateModel} state aktueller State
     * @param {IMasterDataStateModel} masterDataState aktueller MasterData State
     * @param {IRealEstateStateModel} realEstateState aktueller RealEstate State
     * @param {IHouseholdStateModel} householdState aktueller Household State
     * @param {ILiabilityStateModel} liabilityState aktueller Liability State
     * @param {IDebtorStateModel} debtorState aktueller Debtor State
     * @returns {number} aktuelle Zinsindekation
     */
    @Selector([MasterDataState, RealEstateState, HouseholdState, LiabilityState, DebtorState])
    public static referenceInterest(
        state: IFinancingMapStateModel,
        masterDataState: IMasterDataStateModel,
        realEstateState: IRealEstateStateModel,
        householdState: IHouseholdStateModel,
        liabilityState: ILiabilityStateModel,
        debtorState: IDebtorStateModel,
    ): number {
        const financingMap = FinancingMapState.current(state);
        const realEstate = RealEstateState.currentFinancingObject(realEstateState);
        const grossFinancingRequirement = FinancingMapState.longFinancingRequirementBrutto(state, realEstateState, householdState, liabilityState, masterDataState);
        const anyDebitorsBA = DebtorState.anyDebitorsBA(debtorState);
        const debitRates = MasterDataState.debitRates(masterDataState);
        const debitRateAdaptions = MasterDataState.debitRateAdaptions(masterDataState);
        const calculationVersion = HelperService.getValueOrUndefiend(financingMap?.calculationVersion);

        const marketValue = !!realEstate ? RealEstateState.marketValueById(realEstateState)(realEstate.id, calculationVersion) : undefined;

        if (!realEstate || !financingMap || !marketValue) { return 0; }

        const volume = debitRateAdaptions.find(dra => dra.parameter === DebitRateAdaptionParameter.Volume);
        const marge = debitRateAdaptions.find(dra => dra.parameter === DebitRateAdaptionParameter.Marge);
        const ltvParameter = anyDebitorsBA ? DebitRateAdaptionParameter.LTV3 : DebitRateAdaptionParameter.LTV5;
        const debitRate = debitRates.find(dr => dr.interestMethod === financingMap.interestMethod);

        if (debitRate === undefined || marge === undefined || volume === undefined) {
            return 0;
        }

        const maxValue = anyDebitorsBA ? debitRate.worstDebitRate3 : debitRate.worstDebitRate5;
        const ltv = grossFinancingRequirement / marketValue * 100;

        const ltvValue = debitRateAdaptions
            .filter(dra => dra.parameter === ltvParameter)
            // eslint-disable-next-line complexity
            .reduce<IDebitRateAdaptionModel | null>((acc, curr) => {
                if (curr.comparison === ComparisonType.GreaterThan) {
                    return (ltv > curr.value && (!acc || acc.value < curr.value)) ? curr : acc;
                }
                else if (curr.comparison === ComparisonType.GreaterThanEqual) {
                    return (ltv >= curr.value && (!acc || acc.value < curr.value)) ? curr : acc;
                }
                else if (curr.comparison === ComparisonType.LessThan) {
                    return (ltv < curr.value && (!acc || acc.value > curr.value)) ? curr : acc;
                }
                else if (curr.comparison === ComparisonType.LessThanEqual) {
                    return (ltv <= curr.value && (!acc || acc.value > curr.value)) ? curr : acc;
                }
                else {
                    return acc;
                }
            }, null);

        return Math.min(
            debitRate.rating4 +
            marge.adaption +
            (grossFinancingRequirement < volume.value ? volume.adaption : 0) +
            (!!ltvValue ? ltvValue.adaption : 0),
            maxValue);
    }

    /**
     * check if the assumedDuration is set, the adress is valid and the brutto requirement > 0
     *
     * @param {IFinancingMapStateModel} state aktueller State
     * @param {IMasterDataStateModel} masterDataState aktueller MasterData State
     * @param {IRealEstateStateModel} realEstateState aktueller RealEstate State
     * @param {IHouseholdStateModel} householdState aktueller Household State
     * @param {ILiabilityStateModel} liabilityState aktueller Liability State
     * @returns {boolean} aktuelle assumedDuration is set
     */
    @Selector([MasterDataState, RealEstateState, HouseholdState, LiabilityState])
    public static calculatorBaseValuesAreSet(
        state: IFinancingMapStateModel,
        masterDataState: IMasterDataStateModel,
        realEstateState: IRealEstateStateModel,
        householdState: IHouseholdStateModel,
        liabilityState: ILiabilityStateModel,
    ): boolean {
        {
            const financingMap = FinancingMapState.current(state);
            const realEstate = RealEstateState.currentFinancingObject(realEstateState);
            return !!financingMap && !!realEstate ? (
                // HelperService.getValue(financingMap.assumedDuration, ModelFactoryService.globalCurrencyDefault) > 0 && // Laufzeit angegeben
                HelperService.hasValue(realEstate.latitude) && HelperService.hasValue(realEstate.longitude) && // Adresse Validiert
                FinancingMapState.longFinancingRequirementBrutto(state, realEstateState, householdState, liabilityState, masterDataState) > 0 // brutto Bedarf > 0
            ) : false;
        }
    }
    //#endregion

    //#region Calculator Card ComfortCredit Plus

    /**
     * berechnet KomfortKredit Plus
     *
     * @param {IFinancingMapStateModel} state aktueller State
     * @param {IMasterDataStateModel} masterDataState aktueller MasterData State
     * @param {IRealEstateStateModel} realEstateState aktueller RealEstate State
     * @param {IHouseholdStateModel} householdState aktueller Household State
     * @param {ILiabilityStateModel} liabilityState aktueller Liability State
     * @param {INewLiabilityStateModel} newLiabilityState aktueller Liability State
     * @param {IDebtorStateModel} debtorState aktueller Debtor State
     * @returns {number} KomfortKredit Plus
     */
    @Selector([MasterDataState, RealEstateState, HouseholdState, LiabilityState, NewLiabilityState, DebtorState])
    public static creditPlusAmount(
        state: IFinancingMapStateModel,
        masterDataState: IMasterDataStateModel,
        realEstateState: IRealEstateStateModel,
        householdState: IHouseholdStateModel,
        liabilityState: ILiabilityStateModel,
        newLiabilityState: INewLiabilityStateModel,
        debtorState: IDebtorStateModel,
    ): number {

        const financingMap = FinancingMapState.current(state);
        const calculationVersion = HelperService.getValueOrUndefiend(financingMap?.calculationVersion);
        const mustSplit = FinancingMapState.mustUseSplitting(state, masterDataState, realEstateState, householdState, liabilityState, newLiabilityState, debtorState);

        const grossFinancingRequirement = FinancingMapState.longFinancingRequirementBrutto(state, realEstateState, householdState, liabilityState, masterDataState);
        const currentCreditAmount = FinancingMapState.creditAmount(state, masterDataState, realEstateState, householdState, liabilityState, newLiabilityState, debtorState);

        const comfortProductCalculationService = CalculationFactoryService.comfortProductCalculationService(calculationVersion);
        return comfortProductCalculationService.comfortCreditPlus({
            splitting: mustSplit,
            grossFinancingRequirement,
            comfortCredit: currentCreditAmount,
        });
    }

    /**
     * berechnet Bearbeitungsspesen KomfortKredit Plus
     *
     * @param {IFinancingMapStateModel} state aktueller State
     * @param {IMasterDataStateModel} masterDataState aktueller MasterData State
     * @param {IRealEstateStateModel} realEstateState aktueller RealEstate State
     * @param {IHouseholdStateModel} householdState aktueller Household State
     * @param {ILiabilityStateModel} liabilityState aktueller Liability State
     * @param {INewLiabilityStateModel} newLiabilityState aktueller Liability State
     * @param {IDebtorStateModel} debtorState aktueller Debtor State
     * @returns {number} Bearbeitungsspesen KomfortKredit Plus
     */
    @Selector([MasterDataState, RealEstateState, HouseholdState, LiabilityState, NewLiabilityState, DebtorState])
    public static handlingFeePlusAmount(
        state: IFinancingMapStateModel,
        masterDataState: IMasterDataStateModel,
        realEstateState: IRealEstateStateModel,
        householdState: IHouseholdStateModel,
        liabilityState: ILiabilityStateModel,
        newLiabilityState: INewLiabilityStateModel,
        debtorState: IDebtorStateModel,
    ): number {
        const financingMap = FinancingMapState.current(state);
        const calculationVersion = HelperService.getValueOrUndefiend(financingMap?.calculationVersion);
        const mustSplit = FinancingMapState.mustUseSplitting(state, masterDataState, realEstateState, householdState, liabilityState, newLiabilityState, debtorState);
        const currentCreditAmount = FinancingMapState.creditPlusAmount(state, masterDataState, realEstateState, householdState, liabilityState, newLiabilityState, debtorState);

        const comfortProductCalculationService = CalculationFactoryService.comfortProductCalculationService(calculationVersion);
        return comfortProductCalculationService.comfortCreditPlusProcessingCharges({
            splitting: mustSplit,
            grossFinancingRequirement: 0, // wird intern nicht verwendet wenn processingChargesPercent gesetzt ist
            comfortCreditPlus: currentCreditAmount,
            processingChargesPercent: financingMap?.handlingFeeIsPercent ? financingMap.handlingFeeInput ?? undefined : undefined,
            processingChargesGrossFinancingRequirement: financingMap?.handlingFeeIsPercent === false ? financingMap.handlingFeeInput ?? undefined : undefined,
        });
    }

    /**
     * berechnet Auszahlungsbetrag KomfortKredit Plus
     *
     * @param {IFinancingMapStateModel} state aktueller State
     * @param {IMasterDataStateModel} masterDataState aktueller MasterData State
     * @param {IRealEstateStateModel} realEstateState aktueller RealEstate State
     * @param {IHouseholdStateModel} householdState aktueller Household State
     * @param {ILiabilityStateModel} liabilityState aktueller Liability State
     * @param {INewLiabilityStateModel} newLiabilityState aktueller Liability State
     * @param {IDebtorStateModel} debtorState aktueller Debtor State
     * @returns {number} Auszahlungsbetrag KomfortKredit Plus
     */
    @Selector([MasterDataState, RealEstateState, HouseholdState, LiabilityState, NewLiabilityState, DebtorState])
    public static payoutPlusAmount(
        state: IFinancingMapStateModel,
        masterDataState: IMasterDataStateModel,
        realEstateState: IRealEstateStateModel,
        householdState: IHouseholdStateModel,
        liabilityState: ILiabilityStateModel,
        newLiabilityState: INewLiabilityStateModel,
        debtorState: IDebtorStateModel,
    ): number {

        const financingMap = FinancingMapState.current(state);
        const calculationVersion = HelperService.getValueOrUndefiend(financingMap?.calculationVersion);
        const mustSplit = FinancingMapState.mustUseSplitting(state, masterDataState, realEstateState, householdState, liabilityState, newLiabilityState, debtorState);
        const currentCreditAmount = FinancingMapState.creditPlusAmount(state, masterDataState, realEstateState, householdState, liabilityState, newLiabilityState, debtorState);
        const handlingFeeAmount = FinancingMapState.handlingFeePlusAmount(state, masterDataState, realEstateState, householdState, liabilityState, newLiabilityState, debtorState);

        const comfortProductCalculationService = CalculationFactoryService.comfortProductCalculationService(calculationVersion);
        return comfortProductCalculationService.comfortCreditPlusPayout({
            splitting: mustSplit,
            comfortCreditPlus: currentCreditAmount,
            comfortCreditPlusProcessingCharges: handlingFeeAmount,
        });
    }

    //#endregion

    //#region Calculator Card Total Rate

    /**
     * berechnet Monatliche Rate KomfortKredit
     *
     * @param {IFinancingMapStateModel} state aktueller State
     * @param {IMasterDataStateModel} masterDataState aktueller MasterData State
     * @param {IRealEstateStateModel} realEstateState aktueller RealEstate State
     * @param {IHouseholdStateModel} householdState aktueller Household State
     * @param {ILiabilityStateModel} liabilityState aktueller Liability State
     * @param {INewLiabilityStateModel} newLiabilityState aktueller NewLiability State
     * @param {IDebtorStateModel} debtorState aktueller Debtor State
     * @returns {number} Monatliche Rate KomfortKredit
     */
    @Selector([MasterDataState, RealEstateState, HouseholdState, LiabilityState, NewLiabilityState, DebtorState])
    public static monthlyRateConfort(
        state: IFinancingMapStateModel,
        masterDataState: IMasterDataStateModel,
        realEstateState: IRealEstateStateModel,
        householdState: IHouseholdStateModel,
        liabilityState: ILiabilityStateModel,
        newLiabilityState: INewLiabilityStateModel,
        debtorState: IDebtorStateModel,
    ): number {
        const financingMap = FinancingMapState.current(state);
        const calculationVersion = HelperService.getValueOrUndefiend(financingMap?.calculationVersion);
        const currentCreditAmount = FinancingMapState.creditAmount(state, masterDataState, realEstateState, householdState, liabilityState, newLiabilityState, debtorState);
        const configuration = FinancingMapState.currentFinacingConfiguration(state, masterDataState);
        const requestedDebitRate = HelperService.getValue(financingMap?.requestedDebitRate, configuration?.requestedDebitRateDefault ?? 0);

        const comfortProductCalculationService = CalculationFactoryService.comfortProductCalculationService(calculationVersion);
        return !!configuration && HelperService.hasValue(configuration.bankAccountFee) ? comfortProductCalculationService.comfortCreditMonthlyDebitRate({
            comfortCredit: currentCreditAmount,
            requestedDebitRate: requestedDebitRate > 0 ? requestedDebitRate : undefined,
            assumedDuration: HelperService.getValueOrUndefiend(financingMap?.assumedDuration),
            gracePeriod: HelperService.getValueOrUndefiend(financingMap?.gracePeriod),
            bankAccountFee: configuration.bankAccountFee,
        }) : 0;
    }

    /**
     * berechnet Zinsrate KomfortKredit
     *
     * @param {IFinancingMapStateModel} state aktueller State
     * @param {IMasterDataStateModel} masterDataState aktueller MasterData State
     * @param {IRealEstateStateModel} realEstateState aktueller RealEstate State
     * @param {IHouseholdStateModel} householdState aktueller Household State
     * @param {ILiabilityStateModel} liabilityState aktueller Liability State
     * @param {INewLiabilityStateModel} newLiabilityState aktueller NewLiability State
     * @param {IDebtorStateModel} debtorState aktueller Debtor State
     * @returns {number} Zinsrate KomfortKredit
     */
    @Selector([MasterDataState, RealEstateState, HouseholdState, LiabilityState, NewLiabilityState, DebtorState])
    public static interestRateConfort(
        state: IFinancingMapStateModel,
        masterDataState: IMasterDataStateModel,
        realEstateState: IRealEstateStateModel,
        householdState: IHouseholdStateModel,
        liabilityState: ILiabilityStateModel,
        newLiabilityState: INewLiabilityStateModel,
        debtorState: IDebtorStateModel,
    ): number {

        const financingMap = FinancingMapState.current(state);
        const calculationVersion = HelperService.getValueOrUndefiend(financingMap?.calculationVersion);
        const currentCreditAmount = FinancingMapState.creditAmount(state, masterDataState, realEstateState, householdState, liabilityState, newLiabilityState, debtorState);
        const configuration = FinancingMapState.currentFinacingConfiguration(state, masterDataState);
        const requestedDebitRate = HelperService.getValue(financingMap?.requestedDebitRate, configuration?.requestedDebitRateDefault ?? 0);

        const comfortProductCalculationService = CalculationFactoryService.comfortProductCalculationService(calculationVersion);
        return !!configuration && HelperService.hasValue(configuration.bankAccountFee) ? comfortProductCalculationService.comfortCreditMonthlyInterestRate({
            comfortCredit: currentCreditAmount,
            requestedDebitRate: requestedDebitRate > 0 ? requestedDebitRate : undefined,
            bankAccountFee: configuration.bankAccountFee,
        }) : 0;
    }

    /**
     * berechnet Monatliche Rate KomfortKredit Plus
     *
     * @param {IFinancingMapStateModel} state aktueller State
     * @param {IMasterDataStateModel} masterDataState aktueller MasterData State
     * @param {IRealEstateStateModel} realEstateState aktueller RealEstate State
     * @param {IHouseholdStateModel} householdState aktueller Household State
     * @param {ILiabilityStateModel} liabilityState aktueller Liability State
     * @param {INewLiabilityStateModel} newLiabilityState aktueller NewLiability State
     * @param {IDebtorStateModel} debtorState aktueller Debtor State
     * @returns {number} Monatliche Rate KomfortKredit Plus
     */
    @Selector([MasterDataState, RealEstateState, HouseholdState, LiabilityState, NewLiabilityState, DebtorState])
    public static monthlyRateConfortPlus(
        state: IFinancingMapStateModel,
        masterDataState: IMasterDataStateModel,
        realEstateState: IRealEstateStateModel,
        householdState: IHouseholdStateModel,
        liabilityState: ILiabilityStateModel,
        newLiabilityState: INewLiabilityStateModel,
        debtorState: IDebtorStateModel,
    ): number {
        const financingMap = FinancingMapState.current(state);
        const calculationVersion = HelperService.getValueOrUndefiend(financingMap?.calculationVersion);
        const mustSplit = FinancingMapState.mustUseSplitting(state, masterDataState, realEstateState, householdState, liabilityState, newLiabilityState, debtorState);
        const currentCreditAmount = FinancingMapState.creditPlusAmount(state, masterDataState, realEstateState, householdState, liabilityState, newLiabilityState, debtorState);
        const configuration = FinancingMapState.currentFinacingConfiguration(state, masterDataState);
        const requestedDebitRate = HelperService.getValue(financingMap?.requestedDebitRate, configuration?.requestedDebitRateDefault ?? 0);

        const comfortProductCalculationService = CalculationFactoryService.comfortProductCalculationService(calculationVersion);
        return comfortProductCalculationService.comfortCreditPlusMonthlyDebitRate({
            splitting: mustSplit,
            comfortCreditPlus: currentCreditAmount,
            requestedDebitRate: requestedDebitRate > 0 ? requestedDebitRate : undefined,
            duration: ModelFactoryService.creditPlusAmountDuration,
        });
    }

    /**
     * berechnet Gesamtrate(1. - 10. Jahr)
     *
     * @param {IFinancingMapStateModel} state aktueller State
     * @param {IMasterDataStateModel} masterDataState aktueller MasterData State
     * @param {IRealEstateStateModel} realEstateState aktueller RealEstate State
     * @param {IHouseholdStateModel} householdState aktueller Household State
     * @param {ILiabilityStateModel} liabilityState aktueller Liability State
     * @param {INewLiabilityStateModel} newLiabilityState aktueller NewLiability State
     * @param {IDebtorStateModel} debtorState aktueller Debtor State
     * @returns {number} Gesamtrate(1. - 10. Jahr)
     */
    @Selector([MasterDataState, RealEstateState, HouseholdState, LiabilityState, NewLiabilityState, DebtorState])
    public static totalRateTo10(
        state: IFinancingMapStateModel,
        masterDataState: IMasterDataStateModel,
        realEstateState: IRealEstateStateModel,
        householdState: IHouseholdStateModel,
        liabilityState: ILiabilityStateModel,
        newLiabilityState: INewLiabilityStateModel,
        debtorState: IDebtorStateModel,
    ): number {
        const financingMap = FinancingMapState.current(state);
        const calculationVersion = HelperService.getValueOrUndefiend(financingMap?.calculationVersion);
        const mustSplit = FinancingMapState.mustUseSplitting(state, masterDataState, realEstateState, householdState, liabilityState, newLiabilityState, debtorState);
        const monthlyRate = FinancingMapState.monthlyRateConfort(state, masterDataState, realEstateState, householdState, liabilityState, newLiabilityState, debtorState);
        const monthlyRatePlus = FinancingMapState.monthlyRateConfortPlus(state, masterDataState, realEstateState, householdState, liabilityState, newLiabilityState, debtorState);

        const comfortProductCalculationService = CalculationFactoryService.comfortProductCalculationService(calculationVersion);
        return comfortProductCalculationService.comfortCreditTotalMonthlyDebitRateToYearTen({
            splitting: mustSplit,
            comfortCreditMonthlyDebitRate: monthlyRate,
            comfortCreditPlusMonthlyDebitRate: monthlyRatePlus,
        });
    }

    /**
     * berechnet Gesamtrate(ab 11. Jahr)
     *
     * @param {IFinancingMapStateModel} state aktueller State
     * @param {IMasterDataStateModel} masterDataState aktueller MasterData State
     * @param {IRealEstateStateModel} realEstateState aktueller RealEstate State
     * @param {IHouseholdStateModel} householdState aktueller Household State
     * @param {ILiabilityStateModel} liabilityState aktueller Liability State
     * @param {INewLiabilityStateModel} newLiabilityState aktueller NewLiability State
     * @param {IDebtorStateModel} debtorState aktueller Debtor State
     * @returns {number} Gesamtrate(ab 11. Jahr)
     */
    @Selector([MasterDataState, RealEstateState, HouseholdState, LiabilityState, NewLiabilityState, DebtorState])
    public static totalRateFrom11(
        state: IFinancingMapStateModel,
        masterDataState: IMasterDataStateModel,
        realEstateState: IRealEstateStateModel,
        householdState: IHouseholdStateModel,
        liabilityState: ILiabilityStateModel,
        newLiabilityState: INewLiabilityStateModel,
        debtorState: IDebtorStateModel,
    ): number {
        const financingMap = FinancingMapState.current(state);
        const calculationVersion = HelperService.getValueOrUndefiend(financingMap?.calculationVersion);
        const monthlyRate = FinancingMapState.monthlyRateConfort(state, masterDataState, realEstateState, householdState, liabilityState, newLiabilityState, debtorState);

        const comfortProductCalculationService = CalculationFactoryService.comfortProductCalculationService(calculationVersion);
        return comfortProductCalculationService.comfortCreditTotalMonthlyDebitRateFromYearEleven({
            comfortCreditMonthlyDebitRate: monthlyRate,
            assumedDuration: HelperService.getValueOrUndefiend(financingMap?.assumedDuration),
            comfortCreditPlusDuration: ModelFactoryService.creditPlusAmountDuration,
        });
    }

    /**
     * berechnet Gesamtkreditbetrag
     *
     * @param {IFinancingMapStateModel} state aktueller State
     * @param {IMasterDataStateModel} masterDataState aktueller MasterData State
     * @param {IRealEstateStateModel} realEstateState aktueller RealEstate State
     * @param {IHouseholdStateModel} householdState aktueller Household State
     * @param {ILiabilityStateModel} liabilityState aktueller Liability State
     * @param {INewLiabilityStateModel} newLiabilityState aktueller NewLiability State
     * @param {IDebtorStateModel} debtorState aktueller Debtor State
     * @returns {number} Gesamtkreditbetrag
     */
    @Selector([MasterDataState, RealEstateState, HouseholdState, LiabilityState, NewLiabilityState, DebtorState])
    public static totalCreditAmount(
        state: IFinancingMapStateModel,
        masterDataState: IMasterDataStateModel,
        realEstateState: IRealEstateStateModel,
        householdState: IHouseholdStateModel,
        liabilityState: ILiabilityStateModel,
        newLiabilityState: INewLiabilityStateModel,
        debtorState: IDebtorStateModel,
    ): number {
        const financingMap = FinancingMapState.current(state);
        const calculationVersion = HelperService.getValueOrUndefiend(financingMap?.calculationVersion);
        const mustSplit = FinancingMapState.mustUseSplitting(state, masterDataState, realEstateState, householdState, liabilityState, newLiabilityState, debtorState);

        const currentCreditAmount = FinancingMapState.creditAmount(state, masterDataState, realEstateState, householdState, liabilityState, newLiabilityState, debtorState);
        const currentCreditPlusAmount = FinancingMapState.creditPlusAmount(state, masterDataState, realEstateState, householdState, liabilityState, newLiabilityState, debtorState);

        const comfortProductCalculationService = CalculationFactoryService.comfortProductCalculationService(calculationVersion);
        return comfortProductCalculationService.comfortCreditTotalAmount({
            splitting: mustSplit,
            comfortCredit: currentCreditAmount,
            comfortCreditPlus: currentCreditPlusAmount,
        });
    }

    /**
     * berechnet Gesamtauszahlungsbetrag
     *
     * @param {IFinancingMapStateModel} state aktueller State
     * @param {IMasterDataStateModel} masterDataState aktueller MasterData State
     * @param {IRealEstateStateModel} realEstateState aktueller RealEstate State
     * @param {IHouseholdStateModel} householdState aktueller Household State
     * @param {ILiabilityStateModel} liabilityState aktueller Liability State
     * @param {INewLiabilityStateModel} newLiabilityState aktueller NewLiability State
     * @param {IDebtorStateModel} debtorState aktueller Debtor State
     * @returns {number} Gesamtauszahlungsbetrag
     */
    @Selector([MasterDataState, RealEstateState, HouseholdState, LiabilityState, NewLiabilityState, DebtorState])
    public static totalPayoutAmount(
        state: IFinancingMapStateModel,
        masterDataState: IMasterDataStateModel,
        realEstateState: IRealEstateStateModel,
        householdState: IHouseholdStateModel,
        liabilityState: ILiabilityStateModel,
        newLiabilityState: INewLiabilityStateModel,
        debtorState: IDebtorStateModel,
    ): number {
        const financingMap = FinancingMapState.current(state);
        const calculationVersion = HelperService.getValueOrUndefiend(financingMap?.calculationVersion);
        const mustSplit = FinancingMapState.mustUseSplitting(state, masterDataState, realEstateState, householdState, liabilityState, newLiabilityState, debtorState);

        const currentPayoutAmountNov2019 = FinancingMapState.payoutAmount(state, masterDataState, realEstateState, householdState, liabilityState, newLiabilityState, debtorState);
        const currentPayoutPlusAmountNov2019 = FinancingMapState.payoutPlusAmount(state, masterDataState, realEstateState, householdState, liabilityState, newLiabilityState, debtorState);

        const comfortProductCalculationService = CalculationFactoryService.comfortProductCalculationService(calculationVersion);
        return comfortProductCalculationService.comfortCreditTotalPayout({
            splitting: mustSplit,
            comfortCreditPayout: currentPayoutAmountNov2019,
            comfortCreditPlusPayout: currentPayoutPlusAmountNov2019,
        });
    }

    //#endregion

    //#region Calculator Card Affordability


    /**
     * berechnet fiktiven Zinssatz für den KomfortKredit
     *
     * @param {IFinancingMapStateModel} state aktueller State
     * @param {IMasterDataStateModel} masterDataState aktueller MasterData State
     * @returns {number} fiktiven Zinssatz KomfortKredit
     */
    @Selector([MasterDataState])
    public static fictitiousRate(
        state: IFinancingMapStateModel,
        masterDataState: IMasterDataStateModel,
    ): number {
        const financingMap = FinancingMapState.current(state);
        const calculationVersion = HelperService.getValueOrUndefiend(financingMap?.calculationVersion);
        const configuration = FinancingMapState.currentFinacingConfiguration(state, masterDataState);
        const requestedDebitRate = HelperService.getValue(financingMap?.requestedDebitRate, configuration?.requestedDebitRateDefault ?? 0);

        if (!configuration) {
            return 0;
        }

        const comfortProductCalculationService = CalculationFactoryService.comfortProductCalculationService(calculationVersion);

        return comfortProductCalculationService.comfortCreditFictionalRate({
            configuration,
            interestMethod: HelperService.getValueOrUndefiend(financingMap?.interestMethod),
            assumedDuration: HelperService.getValueOrUndefiend(financingMap?.assumedDuration),
            requestedDebitRate: requestedDebitRate > 0 ? requestedDebitRate : undefined,
        }) ?? 0;
    }

    /**
     * berechnet Monatliche fiktive Rate KomfortKredit
     *
     * @param {IFinancingMapStateModel} state aktueller State
     * @param {IMasterDataStateModel} masterDataState aktueller MasterData State
     * @param {IRealEstateStateModel} realEstateState aktueller RealEstate State
     * @param {IHouseholdStateModel} householdState aktueller Household State
     * @param {ILiabilityStateModel} liabilityState aktueller Liability State
     * @param {INewLiabilityStateModel} newLiabilityState aktueller NewLiability State
     * @param {IDebtorStateModel} debtorState aktueller Debtor State
     * @returns {number} fiktive Rate KomfortKredit
     */
    @Selector([MasterDataState, RealEstateState, HouseholdState, LiabilityState, NewLiabilityState, DebtorState])
    public static fictitiousRateAmount(
        state: IFinancingMapStateModel,
        masterDataState: IMasterDataStateModel,
        realEstateState: IRealEstateStateModel,
        householdState: IHouseholdStateModel,
        liabilityState: ILiabilityStateModel,
        newLiabilityState: INewLiabilityStateModel,
        debtorState: IDebtorStateModel,
    ): number {
        const financingMap = FinancingMapState.current(state);
        const calculationVersion = HelperService.getValueOrUndefiend(financingMap?.calculationVersion);
        const fictionalRate = FinancingMapState.fictitiousRate(state, masterDataState);
        const currentCreditAmount = FinancingMapState.creditAmount(state, masterDataState, realEstateState, householdState, liabilityState, newLiabilityState, debtorState);
        const financingCfg = FinancingMapState.currentFinacingConfiguration(state, masterDataState);

        const comfortProductCalculationService = CalculationFactoryService.comfortProductCalculationService(calculationVersion);
        return !!financingCfg && fictionalRate > 0 && HelperService.hasValue(financingCfg.bankAccountFee) ? comfortProductCalculationService.comfortCreditFictionalAmount({
            fictionalRate,
            comfortCredit: currentCreditAmount,
            assumedDuration: HelperService.getValueOrUndefiend(financingMap?.assumedDuration),
            gracePeriod: HelperService.getValueOrUndefiend(financingMap?.gracePeriod),
            bankAccountFee: financingCfg.bankAccountFee,
        }) : 0;
    }

    /**
     * Berechnet fiktiven Zinssatz für den KomfortKredit Plus
     *
     * @param {IFinancingMapStateModel} state aktueller State
     * @param {IMasterDataStateModel} masterDataState aktueller MasterData State
     * @param {IRealEstateStateModel} realEstateState aktueller RealEstate State
     * @param {IHouseholdStateModel} householdState aktueller Household State
     * @param {ILiabilityStateModel} liabilityState aktueller Liability State
     * @param {INewLiabilityStateModel} newLiabilityState aktueller NewLiability State
     * @param {IDebtorStateModel} debtorState aktueller Debtor State
     * @returns {number} fiktiver Zinssatz KomfortKredit Plus
     */
    @Selector([MasterDataState, RealEstateState, HouseholdState, LiabilityState, NewLiabilityState, DebtorState])
    public static fictitiousRatePlus(
        state: IFinancingMapStateModel,
        masterDataState: IMasterDataStateModel,
        realEstateState: IRealEstateStateModel,
        householdState: IHouseholdStateModel,
        liabilityState: ILiabilityStateModel,
        newLiabilityState: INewLiabilityStateModel,
        debtorState: IDebtorStateModel,
    ): number {
        const financingMap = FinancingMapState.current(state);
        const calculationVersion = HelperService.getValueOrUndefiend(financingMap?.calculationVersion);
        const mustSplit = FinancingMapState.mustUseSplitting(state, masterDataState, realEstateState, householdState, liabilityState, newLiabilityState, debtorState);
        const configuration = FinancingMapState.currentFinacingConfiguration(state, masterDataState);
        const requestedDebitRate = HelperService.getValue(financingMap?.requestedDebitRate, configuration?.requestedDebitRateDefault ?? 0);

        if (!configuration) {
            return 0;
        }

        const comfortProductCalculationService = CalculationFactoryService.comfortProductCalculationService(calculationVersion);
        return comfortProductCalculationService.comfortCreditPlusFictionalRate({
            configuration,
            splitting: mustSplit,
            interestMethod: HelperService.getValueOrUndefiend(financingMap?.interestMethod),
            duration: ModelFactoryService.creditPlusAmountDuration,
            requestedDebitRate: requestedDebitRate > 0 ? requestedDebitRate : undefined,
        });
    }

    /**
     * berechnet Monatliche fiktive Rate KomfortKredit Plus
     *
     * @param {IFinancingMapStateModel} state aktueller State
     * @param {IMasterDataStateModel} masterDataState aktueller MasterData State
     * @param {IRealEstateStateModel} realEstateState aktueller RealEstate State
     * @param {IHouseholdStateModel} householdState aktueller Household State
     * @param {ILiabilityStateModel} liabilityState aktueller Liability State
     * @param {INewLiabilityStateModel} newLiabilityState aktueller NewLiability State
     * @param {IDebtorStateModel} debtorState aktueller Debtor State
     * @returns {number} fiktive Rate KomfortKredit Plus
     */
    @Selector([MasterDataState, RealEstateState, HouseholdState, LiabilityState, NewLiabilityState, DebtorState])
    public static fictitiousRatePlusAmount(
        state: IFinancingMapStateModel,
        masterDataState: IMasterDataStateModel,
        realEstateState: IRealEstateStateModel,
        householdState: IHouseholdStateModel,
        liabilityState: ILiabilityStateModel,
        newLiabilityState: INewLiabilityStateModel,
        debtorState: IDebtorStateModel,
    ): number {
        const financingMap = FinancingMapState.current(state);
        const calculationVersion = HelperService.getValueOrUndefiend(financingMap?.calculationVersion);
        const fictionalRate = FinancingMapState.fictitiousRatePlus(state, masterDataState, realEstateState, householdState, liabilityState, newLiabilityState, debtorState);
        const mustSplit = FinancingMapState.mustUseSplitting(state, masterDataState, realEstateState, householdState, liabilityState, newLiabilityState, debtorState);
        const currentCreditAmount = FinancingMapState.creditPlusAmount(state, masterDataState, realEstateState, householdState, liabilityState, newLiabilityState, debtorState);

        const comfortProductCalculationService = CalculationFactoryService.comfortProductCalculationService(calculationVersion);
        return comfortProductCalculationService.comfortCreditPlusFictionalAmount({
            fictionalRate,
            splitting: mustSplit,
            comfortCreditPlus: currentCreditAmount,
            duration: ModelFactoryService.creditPlusAmountDuration,
        });
    }

    /**
     * berechnet Monatliche fiktive Rate Gesamt
     *
     * @param {IFinancingMapStateModel} state aktueller State
     * @param {IMasterDataStateModel} masterDataState aktueller MasterData State
     * @param {IRealEstateStateModel} realEstateState aktueller RealEstate State
     * @param {IHouseholdStateModel} householdState aktueller Household State
     * @param {ILiabilityStateModel} liabilityState aktueller Liability State
     * @param {INewLiabilityStateModel} newLiabilityState aktueller NewLiability State
     * @param {IDebtorStateModel} debtorState aktueller Debtor State
     * @returns {number} fiktive Rate Gesamt
     */
    @Selector([MasterDataState, RealEstateState, HouseholdState, LiabilityState, NewLiabilityState, DebtorState])
    public static totalFictitiousRate(
        state: IFinancingMapStateModel,
        masterDataState: IMasterDataStateModel,
        realEstateState: IRealEstateStateModel,
        householdState: IHouseholdStateModel,
        liabilityState: ILiabilityStateModel,
        newLiabilityState: INewLiabilityStateModel,
        debtorState: IDebtorStateModel,
    ): number {
        const financingMap = FinancingMapState.current(state);
        const calculationVersion = HelperService.getValueOrUndefiend(financingMap?.calculationVersion);
        const mustSplit = FinancingMapState.mustUseSplitting(state, masterDataState, realEstateState, householdState, liabilityState, newLiabilityState, debtorState);
        const currentFictitiousRateNov2019 = FinancingMapState.fictitiousRateAmount(state, masterDataState, realEstateState, householdState, liabilityState, newLiabilityState, debtorState);
        const currentFictitiousRatePlusNov2019 = FinancingMapState.fictitiousRatePlusAmount(state, masterDataState, realEstateState, householdState, liabilityState, newLiabilityState, debtorState);

        const comfortProductCalculationService = CalculationFactoryService.comfortProductCalculationService(calculationVersion);
        return comfortProductCalculationService.comfortCreditTotalFictionalAmount({
            splitting: mustSplit,
            comfortCreditFictionalAmount: currentFictitiousRateNov2019,
            comfortCreditPlusFictionalAmount: currentFictitiousRatePlusNov2019,
        });
    }

    /**
     * Ermittelt ob die Fiktive Rate abgedeckt ist
     *
     * @param {IFinancingMapStateModel} state aktueller State
     * @param {IMasterDataStateModel} masterDataState aktueller MasterData State
     * @param {IRealEstateStateModel} realEstateState aktueller RealEstate State
     * @param {IHouseholdStateModel} householdState aktueller Household State
     * @param {ILiabilityStateModel} liabilityState aktueller Liability State
     * @param {INewLiabilityStateModel} newLiabilityState aktueller NewLiability State
     * @param {IDebtorStateModel} debtorState aktueller Debtor State
     * @returns {number} ist rate abgedeckt
     */
    @Selector([MasterDataState, RealEstateState, HouseholdState, LiabilityState, NewLiabilityState, DebtorState])
    public static fictitiousRateCoveredByAllHousehold(
        state: IFinancingMapStateModel,
        masterDataState: IMasterDataStateModel,
        realEstateState: IRealEstateStateModel,
        householdState: IHouseholdStateModel,
        liabilityState: ILiabilityStateModel,
        newLiabilityState: INewLiabilityStateModel,
        debtorState: IDebtorStateModel,
    ): boolean {
        const financingMap = FinancingMapState.current(state);
        const calculationVersion = HelperService.getValueOrUndefiend(financingMap?.calculationVersion);
        const fictitiousRate = FinancingMapState.fictitiousRateAmount(state, masterDataState, realEstateState, householdState, liabilityState, newLiabilityState, debtorState);
        const fictitiousRatePlus = FinancingMapState.fictitiousRatePlusAmount(state, masterDataState, realEstateState, householdState, liabilityState, newLiabilityState, debtorState);
        const reasonableLoanRateArray = HouseholdState.reasonableLoanRateArray(householdState, debtorState, liabilityState, newLiabilityState)(calculationVersion);
        const reasonableLoanRateSum = reasonableLoanRateArray.reduce((acc, curr) => acc + curr, 0);
        return fictitiousRate + fictitiousRatePlus <= reasonableLoanRateSum;
    }


    //#endregion

    //#endregion Berechungen

    /**
     * prüfen, ob die gegebene FinancingMap editierbar ist
     *
     * @param {FinancingMapStatus} status FinancingMapStatus
     * @returns {boolean} true=editierbar | false= nicht editierbar
     */
    public static staticIsFinancingMapEditable(status?: FinancingMapStatus): boolean {
        return (
            status === FinancingMapStatus.Open
        );
    }

    /**
     * prüft, ob der Chat einer gegebenen Finanzierungsmappe nicht mehr beschreibbar ist
     * unter folgenden Status ist der Chat nurnoch lesbar:
     * -storniert und Kundenchat aktiv
     * -automatisch zurückgestellt
     * 
     * @param {boolean} isInternal true wenn der Interne Chat Aktiviert ist
     * @param {boolean} isAutomaticRejected true wenn die Finanzierung automatisch zurückgestellt wurde
     * @param {FinancingMapStatus} status FinancingMapStatus
     * @returns {boolean} true= Chat ist nurnoch lesbar | false= Chat ist beschreibbar
     */
    public static staticIsChatForFinancingMapOnlyReadable(isInternal: boolean, isAutomaticRejected?: boolean, status?: FinancingMapStatus): boolean {

        return (
            (status === FinancingMapStatus.VpcCanceled && !isInternal) || (!!isAutomaticRejected)
        );
    }

    /**
     * prüfen, ob die gegebene FinancingMap abgeschlossen, zurückgestellt oder storniert ist
     *
     * @param {FinancingMapStatus} status FinancingMapStatus
     * @returns {boolean} true=abgeschlossen, zurückgestellt oder storniert | false= nicht abgeschlossen, zurückgestellt oder storniert
     */
    public static staticIsFinancingMapClosed(status?: FinancingMapStatus): boolean {
        return (
            status === FinancingMapStatus.VpcCanceled ||
            status === FinancingMapStatus.VpcCompleted ||
            status === FinancingMapStatus.VpcRejected
        );
    }

    /**
     * prüfen, ob die gegebene FinancingMap im Status HouseholdCalculationExist ist
     *
     * @param {FinancingMapStatus} status FinancingMapStatus
     * @returns {boolean} true=editierbar | false= nicht editierbar
     */
    public static staticIsFinancingMapInStatusHouseholdCalculationExist(status?: FinancingMapStatus): boolean {
        return (
            status === FinancingMapStatus.HouseholdCalculationExist
        );
    }

    /**
     * die erforderlichen Dokumenttypen zurückgeben
     *
     * @param {IFinancingMapStateModel} state aktueller State
     * @returns {DocumentType[]} erforderlichen Dokumenten
     */
    @Selector()
    public static requiredDocumentTypes(state: IFinancingMapStateModel): DocumentType[] {

        const financingMap = FinancingMapState.current(state);
        return !!financingMap && Array.isArray(financingMap.requiredDocuments) ? financingMap.requiredDocuments : [];
    }

    /**
     * die fehlenden erforderlichen Dokumenttypen zurückgeben
     *
     * @param {IFinancingMapStateModel} state aktueller State
     * @param {IDocumentStateModel} documentState aktueller Dokumenten State
     * @param {IFileStateModel} fileState aktueller File State
     * @returns {DocumentType[]} fehlende erforderliche Dokumenten
     */
    @Selector([DocumentState, FileState])
    public static missingRequiredDocuments(
        state: IFinancingMapStateModel,
        documentState: IDocumentStateModel,
        fileState: IFileStateModel,
    ): DocumentType[] {
        const requiredDocuments = FinancingMapState.requiredDocumentTypes(state);
        const existingDocuments = DocumentState.current(documentState);
        const existingDocumentTypes = existingDocuments.map(({ type }) => type);

        // Nachzureichende Unterlagen, welche auch nicht unterschrieben vorkommen können
        const unsignedTypes: DocumentType[] = [DocumentType.EmployerApproval, DocumentType.PurchaseAgreement];

        return requiredDocuments.reduce<DocumentType[]>((accumulator, currentValue) => {

            if (unsignedTypes.includes(currentValue)) {

                // Prüfung ob es mindestens ein Unterschriebenes File gibt

                const docs = existingDocuments.filter(({ type }) => type === currentValue);

                if (Array.isArray(docs) && docs.length > 0) {

                    const exist = docs.some(doc => {
                        const files = FileState.filesByDocumentIds(fileState)([doc.id]);
                        return files.every(({ isSigned }) => isSigned);
                    });

                    return exist ? accumulator : [...accumulator, currentValue];
                }
                else {
                    return [...accumulator, currentValue];
                }
            }
            else {
                return existingDocumentTypes.includes(currentValue) ? accumulator : [...accumulator, currentValue];
            }
        }, []);
    }

    /**
     * has the financingmap file wich must send to VPC
     *
     * @param {IFinancingMapStateModel} state aktueller State
     * @param {IDocumentStateModel} documentState aktueller Dokumenten State
     * @param {IFileStateModel} fileState aktueller File State
     * @returns {boolean} true= es gibt Files/Dokumente welche an VPC gesent werden müssen | false= es gibt keine Files/Dokumente welche an VPC gesent werden müssen
     */
    @Selector([DocumentState, FileState])
    public static hasSentFileToVPC(
        state: IFinancingMapStateModel,
        documentState: IDocumentStateModel,
        fileState: IFileStateModel,
    ): boolean {
        const financingMap = FinancingMapState.current(state);

        if (!!financingMap && !FinancingMapState.staticIsFinancingMapEditable(financingMap.status) && !FinancingMapState.staticIsFinancingMapClosed(financingMap.status)) {
            let allfiles = DocumentState.allUserFiles(documentState, fileState);

            // Im neuen Prozess sollen die Haushaltsrechnungen über den Button Haushhaltsdokumente bestätigen gesendet werden und nicht über den nachreichen Button
            // ansonsten soll es immer möglich sein Haushhaltsdokumente nachzureichen
            if (financingMap.status === FinancingMapStatus.HouseholdCalculationExist) {
                // Haushaltsdokumente sollen nicht mit gesendet werden über den Dokumente nachreichen Button
                const ignoreHouseholdCalculations = DocumentState.allHousholdDocumentFiles(documentState, fileState);
                for (const file of ignoreHouseholdCalculations) {
                    allfiles = allfiles.filter(x => x.id !== file.id);
                }
            }

            return allfiles.some(({ sentToVPC }) => !sentToVPC);
        }
        else {
            return false;
        }
    }

    /**
     * gibt eine Liste der Fianzierungs Document Sections zurück
     *
     * @param {IFinancingMapStateModel} state aktueller State
     * @param {IRealEstateStateModel} realEstateState aktueller RealEstate State
     * @param {IHouseholdStateModel} householdState aktueller Household State
     * @param {IDebtorStateModel} debtorState aktueller Dokumenten State
     * @param {IDocumentStateModel} documentState aktueller Dokumenten State
     * @param {IFileStateModel} fileState aktueller File State
     * @param {ILiabilityStateModel} liabilityState aktueller LiabilityState State
     * @returns {IDocumentSectionArea[]} Liste an Document Sections
     */
    @Selector([RealEstateState, HouseholdState, DebtorState, DocumentState, FileState, LiabilityState])
    public static financingmapDocumentSections(
        state: IFinancingMapStateModel,
        realEstateState: IRealEstateStateModel,
        householdState: IHouseholdStateModel,
        debtorState: IDebtorStateModel,
        documentState: IDocumentStateModel,
        fileState: IFileStateModel,
        liabilityState: ILiabilityStateModel,
    ): IDocumentSectionArea[] {
        const currentfinancingMapId = FinancingMapState.currentId(state);
        const realEstates = RealEstateState.current(realEstateState);
        const householdIds = HouseholdState.currentIds(householdState);
        const households = HouseholdState.current(householdState);
        const debtors = DebtorState.currentByHousholdIds(debtorState)(householdIds);
        const documents = DocumentState.current(documentState);
        const liabilities = LiabilityState.current(liabilityState);

        const result: IDocumentSectionArea[] = [];
        let accordionListAreas: IDocumentSectionArea[] = [];

        // Debtoren
        const debtorValidationMap = DOCUMENT_CARD_VALIDATION_MAP['debtor'];

        for (const [i, debtor] of debtors.entries()) {
            const allDocumentsOfDebtor = documents.filter(({ debtorId }) => debtorId === debtor.id);
            const allFilesOfDebtor = FileState.filesByDocumentIds(fileState)(allDocumentsOfDebtor.map(({ id }) => id));

            const areaFileItems = debtorValidationMap.reduce<IDocumentFileItem[]>((fileItemsPerArea, validation) => {
                const documentsOfType = allDocumentsOfDebtor
                    .filter(({ debtorId, type }) => debtorId === debtor.id && type === validation.type);

                const fileItemsPerDocument = this.fileItemsFromDocuments(
                    documentsOfType, allFilesOfDebtor, validation, debtor.id, '', debtor, allDocumentsOfDebtor,
                );

                return fileItemsPerArea.concat(fileItemsPerDocument);

            }, []);

            result.push({
                name: HelperService.calcDebitorLabel(debtor, i, 'Kreditnehmer'),
                items: areaFileItems,
                hasContent: areaFileItems.length > 0, // inkl Platzhalter
                fileCount: areaFileItems.filter(({ fileId }) => !!fileId).length, // exklusive Platzhalter
                hasRequired: areaFileItems.some(({ isRequired }) => isRequired),
                areaId: HelperService.toHTMLid(debtor.id),
                area: DocumentArea.Debtor,
                referenceObject: {
                    'debtorId': debtor.id,
                },
            });
        }

        // Household
        const householdValidationMap = DOCUMENT_CARD_VALIDATION_MAP['household'];
        const householdLabel = 'Haushalt / Einkommen';

        for (const [i, household] of households.entries()) {
            const debtorsOfHousehold = debtors.filter(({ householdId }) => householdId === household.id);

            const areaFileItems = householdValidationMap.reduce<IDocumentFileItem[]>((fileItemsPerArea, validation) => {

                if (validation.useForAnyDebtor) {
                    const preFix = validation.type === DocumentType.SalaryStatement ? '3x ' : '';

                    const fileItemsPerDebtor = debtorsOfHousehold.reduce<IDocumentFileItem[]>((resultItemsPerDebtor, debtor) => {
                        const documentsOfType = documents.filter(({ debtorId, type }) => debtorId === debtor.id && type === validation.type);
                        const filesOfDebtor = FileState.filesByDocumentIds(fileState)(documentsOfType.map(({ id }) => id));

                        // index über alle Kreditnehmer, damit es mit der Anzeige im Personen bereich übereinstimmt
                        const index = debtors.findIndex(({ id }) => id === debtor.id);
                        const debtorLabel = HelperService.calcDebitorLabel(debtor, index, 'Kreditnehmer', true);

                        const fileItemsPerDocument = this.fileItemsFromDocuments(
                            documentsOfType, filesOfDebtor, validation, debtor.id, `${debtorLabel}: ${preFix}`, debtor,
                        );

                        return resultItemsPerDebtor.concat(fileItemsPerDocument);
                    }, []);

                    return fileItemsPerArea.concat(fileItemsPerDebtor);
                }
                else {
                    const documentsOfType = documents.filter(({ householdId, type }) => householdId === household.id && type === validation.type);
                    const filesOfHousehold = FileState.filesByDocumentIds(fileState)(documentsOfType.map(({ id }) => id));

                    const fileItemsPerDocument = this.fileItemsFromDocuments(
                        documentsOfType, filesOfHousehold, validation, household.id, '',
                    );

                    return fileItemsPerArea.concat(fileItemsPerDocument);
                }
            }, []);

            result.push({
                name: `${i + 1}. ${householdLabel}`,
                items: areaFileItems,
                hasContent: areaFileItems.length > 0, // inkl Platzhalter
                fileCount: areaFileItems.filter(({ fileId }) => !!fileId).length, // exklusive Platzhalter
                hasRequired: areaFileItems.some(({ isRequired }) => isRequired),
                areaId: HelperService.toHTMLid(household.id),
                area: DocumentArea.HouseHold,
                referenceObject: {
                    'debtorId': debtorsOfHousehold.map(({ id }) => id),
                    'householdId': household.id,
                },
            });
        }

        // Financingplan
        if (!!currentfinancingMapId) {
            const financingplanValidationMap = DOCUMENT_CARD_VALIDATION_MAP['financingplan'];
            const areaFileItems = financingplanValidationMap.reduce<IDocumentFileItem[]>((fileItemsPerArea, validation) => {

                if (validation.useForAnyDebtor) {
                    const fileItemsPerDebtor = debtors.reduce<IDocumentFileItem[]>((resultItemsPerDebtor, debtor, index) => {
                        const documentsOfType = documents.filter(({ debtorId, type }) => debtorId === debtor.id && type === validation.type);
                        const filesOfDebtor = FileState.filesByDocumentIds(fileState)(documentsOfType.map(({ id }) => id));

                        const debtorLabel = HelperService.calcDebitorLabel(debtor, index, 'Kreditnehmer)', true);

                        const fileItemsPerDocument = this.fileItemsFromDocuments(
                            documentsOfType, filesOfDebtor, validation, debtor.id, `${debtorLabel}: `, debtor,
                        );

                        return resultItemsPerDebtor.concat(fileItemsPerDocument);
                    }, []);

                    return fileItemsPerArea.concat(fileItemsPerDebtor);
                }
                else if (!!currentfinancingMapId && validation.referenceProperty === 'householdId') {
                    const documentsOfType = documents.filter(({ householdId, type }) => !!householdId && householdIds.includes(householdId) && type === validation.type);
                    const filesOfHousehold = FileState.filesByDocumentIds(fileState)(documentsOfType.map(({ id }) => id));

                    // TODO: eigentlich müsste die jeweilige householdId und nicht currentId reingegeben werde.
                    // Da aber die beiden householdId Dokumente kein Pflicht sind, wird es erstmal ignoriert
                    const fileItemsPerDocument = this.fileItemsFromDocuments(
                        documentsOfType, filesOfHousehold, validation, currentfinancingMapId, '', liabilities,
                    );

                    return fileItemsPerArea.concat(fileItemsPerDocument);
                }
                else if (!!currentfinancingMapId && validation.referenceProperty === 'financingMapId') {
                    const documentsOfType = documents.filter(({ financingMapId, type }) => financingMapId === currentfinancingMapId && type === validation.type);
                    const filesOfFinancing = FileState.filesByDocumentIds(fileState)(documentsOfType.map(({ id }) => id));

                    const fileItemsPerDocument = this.fileItemsFromDocuments(
                        documentsOfType, filesOfFinancing, validation, currentfinancingMapId, '',
                    );

                    return fileItemsPerArea.concat(fileItemsPerDocument);
                }
                else {
                    return fileItemsPerArea;
                }
            }, []);

            const name = 'Finanzplan';
            result.push({
                name,
                items: areaFileItems,
                hasContent: areaFileItems.length > 0, // inkl Platzhalter
                fileCount: areaFileItems.filter(({ fileId }) => !!fileId).length, // exklusive Platzhalter
                hasRequired: areaFileItems.some(({ isRequired }) => isRequired),
                areaId: HelperService.toHTMLid(currentfinancingMapId) + name,
                area: DocumentArea.FinancingPlan,
                referenceObject: {
                    'debtorId': debtors.map(({ id }) => id),
                    'householdId': householdIds,
                },
            });
        }


        // RealEstate
        const realEstateValidationMap = DOCUMENT_CARD_VALIDATION_MAP['realestate'];

        for (const [i, realEstate] of realEstates.entries()) {
            const condition = RealEstateState.visibleMap(realEstate);

            const areaFileItems = realEstateValidationMap.reduce<IDocumentFileItem[]>((fileItemsPerArea, validation) => {

                const documentsOfType = documents.filter(({ type, realEstateId }) => type === validation.type && realEstateId === realEstate.id);
                // Unterschriebene Files werden nur im nachzureichenden Bereich dargestellt
                const filesOfRealEstate = FileState.filesByDocumentIds(fileState)(documentsOfType.map(({ id }) => id)).filter(({ isSigned }) => !isSigned);

                const fileItemsPerDocument = this.fileItemsFromDocuments(
                    documentsOfType, filesOfRealEstate, validation, realEstate.id, '', condition,
                );

                return fileItemsPerArea.concat(fileItemsPerDocument);

            }, []);

            const name = realEstate.objectPurpose === ObjectPurposeType.Finance ?
                'Zu finanzierendes Objekt' : realEstate.name ?
                    `Objekt ${i + 1} (${realEstate.name})` : `Objekt ${i + 1}`;


            result.push({
                name,
                items: areaFileItems,
                hasContent: areaFileItems.length > 0, // inkl Platzhalter
            fileCount: areaFileItems.filter(({ fileId }) => !!fileId).length, // exklusive Platzhalter
            hasRequired: areaFileItems.some(({ isRequired }) => isRequired),
                areaId: HelperService.toHTMLid(realEstate.id),
                area: DocumentArea.RealEstate,
                referenceObject: {
                    'realEstateId': realEstate.id,
                },
            });
        }


        // Sonstige
        const items = documents.reduce<IDocumentFileItem[]>((acc, curr) => {
            if (curr.type === DocumentType.Other) {
                const documentName = curr.name ?? DocumentType.translate(DocumentType.Other) ?? '';
                const files = FileState.filesByDocumentIds(fileState)([curr.id]).map<IDocumentFileItem>(file => ({
                    name: `${documentName} (${file.name}${file.extension})`,
                    documentName,
                    documentType: curr.type,
                    documentId: curr.id,
                    fileId: file.id,
                    isRenameable: true,
                    disableActionButtons: !!file.sentToVPC,
                }));

                return acc.concat(files);
            }
            else {
                return acc;
            }
        }, []);

        const name = 'Sonstige';

        result.push({
            name,
            items,
            hasContent: items.length > 0,
            fileCount: items.length,
            areaId: HelperService.toHTMLid(currentfinancingMapId) + name,
            area: DocumentArea.Other,
            referenceObject: {
                'financingMapId': currentfinancingMapId,
            },
        });


        if (!!result && (!Array.isArray(result) || result.length > 0)) {
            const documentSectionList = result.reduce<IDocumentSectionArea[]>((acc, curr) =>
                (Array.isArray(curr) ? [...acc, ...curr] : [...acc, curr]), [])

            for (const documentSectionArea of documentSectionList) {
                documentSectionArea.items = sort(documentSectionArea.items).by([
                    { desc: item => item.isRequired },
                    { asc: item => (item.name) },
                ]);
            }

            if (Array.isArray(accordionListAreas) && accordionListAreas.length > 0) {
                if (accordionListAreas.length !== documentSectionList.length) {
                    // Wenn es neue Bereiche gibt, wird der komplette bereich neu gesetzt
                    accordionListAreas = documentSectionList;
                }
            }
            else {
                accordionListAreas = documentSectionList;
            }
        }

        return accordionListAreas;
    }

    /**
     * gibt eine Liste der Document Sections zurück
     *
     * @param {IFinancingMapStateModel} state aktueller State
     * @param {IHouseholdStateModel} householdState aktueller Household State
     * @param {IDebtorStateModel} debtorState aktueller Dokumenten State
     * @param {IDocumentStateModel} documentState aktueller Dokumenten State
     * @param {IFileStateModel} fileState aktueller File State
     * @param {ISignatureStateModel} signatureState aktueller SignatureState State
     * @returns {ISignatureSectionArea[]} Liste an Document Sections
     */
    @Selector([HouseholdState, DebtorState, DocumentState, FileState, SignatureState])
    public static signatureDoucmentSections(
        state: IFinancingMapStateModel,
        householdState: IHouseholdStateModel,
        debtorState: IDebtorStateModel,
        documentState: IDocumentStateModel,
        fileState: IFileStateModel,
        signatureState: ISignatureStateModel,
    ): ISignatureSectionArea[] {
        const financingMap = FinancingMapState.current(state);
        const currentfinancingMapId = FinancingMapState.currentId(state);
        const householdIds = HouseholdState.currentIds(householdState);
        const debtors = DebtorState.currentByHousholdIds(debtorState)(householdIds);
        const documents = DocumentState.current(documentState);
        const signatures = SignatureState.current(signatureState);

        const signatureValidationMap = DOCUMENT_CARD_VALIDATION_MAP['signature'];
        const householdDocumentsValidationMap = DOCUMENT_CARD_VALIDATION_MAP['householdDocuments'];

        // reguläre Unterschriftsdokumente welche digital unterschrieben werden können
        const regularSignatures = signatureValidationMap.reduce<ISignatureSectionArea[]>((resultAreas, validation) => {

            if (!!currentfinancingMapId) {
                const documentsOfType = documents.filter(({ financingMapId, type }) => financingMapId === currentfinancingMapId && type === validation.type);
                const filesOfFinancing = FileState.filesByDocumentIds(fileState)(documentsOfType.map(({ id }) => id));

                const fileItemsPerDocument = documentsOfType.reduce<IDocumentFileItem[]>((resultFiles, document) => {
                    const filesOfDocument = filesOfFinancing.filter(({ documentId }) => documentId === document.id);
                    const documentLabel = DocumentType.translate(validation.type) ?? '';

                    const fileItems = filesOfDocument.map<IDocumentFileItem>(file => ({
                        name: `${documentLabel} (${file.name}${file.extension})`,
                        documentType: document.type,
                        documentName: document.name ?? '',
                        documentId: document.id,
                        fileId: file.id,
                        isRequired: validation.required(),
                        disableActionButtons: !!file.sentToVPC,
                    }));

                    return resultFiles.concat(fileItems);
                }, []);

                const signatureOfType = signatures.filter(({ financingMapId, type }) => !!financingMapId && type === validation.type && financingMapId === currentfinancingMapId);

                const resultArea: ISignatureSectionArea = {
                    name: DocumentType.translate(validation.type) ?? '',
                    items: fileItemsPerDocument,
                    hasContent: fileItemsPerDocument.length > 0, // inkl Platzhalter
                    fileCount: fileItemsPerDocument.filter(({ fileId }) => !!fileId).length, // exklusive Platzhalter
                    hasRequired: fileItemsPerDocument.some(({ isRequired }) => isRequired),
                    areaId: HelperService.toHTMLid(currentfinancingMapId) + validation.type,
                    area: DocumentArea.Signatures,
                    referenceObject: {
                        'financingMapId': currentfinancingMapId,
                    },
                    isValid: (fileItemsPerDocument.length > 0 || signatureOfType.length >= debtors.length),
                    documentType: validation.type,
                    referenceId: currentfinancingMapId,
                    pageName: !!validation.pageName ? validation.pageName() : undefined,
                };

                return [...resultAreas, resultArea];
            }
            else {
                return resultAreas;
            }

        }, []);

        // Haushalt
        let householdSignatures: ISignatureSectionArea[] = [];

        // Unterschriftsdokumente der Haushaltsrechnung welche mit dem Rechenbeispiel unterschrieben werden
        if (financingMap?.status !== FinancingMapStatus.Open) {
            const signDocuments = documents.filter(({ type }) => GlobalSettings.documentTypesHousehold.includes(type));

            // Für jeden Haushalt, Kreditnehmer und Typ wird eine Area angelegt
            for (const doc of signDocuments) {
                const validation = householdDocumentsValidationMap.find(({ type }) => type === doc.type);
                const documentRefId = !!validation ? doc[validation.referenceProperty] : undefined;

                if (!!validation && !!documentRefId) {
                    const filesOfDocument = FileState.filesByDocumentIds(fileState)([doc.id], true);

                    const fileItemsPerDocument = filesOfDocument.map<IDocumentFileItem>(file => ({
                        name: `${file.name}${file.extension}`,
                        documentType: doc.type,
                        documentName: doc.name ?? '',
                        documentId: doc.id,
                        fileId: file.id,
                        isRequired: validation.required(),
                        disableActionButtons: !!file.sentToVPC,
                    }));

                    const existingArea = householdSignatures
                        .find(({ referenceId: refId, documentType }) => refId === documentRefId && documentType === doc.type);

                    if (!existingArea) {
                        const referenceObject: Record<string, string> = {};
                        referenceObject[validation.referenceProperty] = documentRefId;

                        const resultArea: ISignatureSectionArea = {
                            name: DocumentType.translate(validation.type) ?? '',
                            items: fileItemsPerDocument,
                            hasContent: fileItemsPerDocument.length > 0, // inkl Platzhalter
                            fileCount: fileItemsPerDocument.filter(({ fileId }) => !!fileId).length, // exklusive Platzhalter
                            hasRequired: fileItemsPerDocument.some(({ isRequired }) => isRequired),
                            areaId: HelperService.toHTMLid(documentRefId) + validation.type,
                            area: DocumentArea.HouseholdDocuments,
                            referenceObject,
                            isValid: fileItemsPerDocument.length > 0,
                            documentType: validation.type,
                            referenceId: documentRefId,
                        };

                        householdSignatures.push(resultArea);
                    }
                    else {
                        existingArea.items = existingArea.items.concat(fileItemsPerDocument);
                        existingArea.hasContent = existingArea.items.length > 0; // inkl Platzhalter
                        existingArea.fileCount = existingArea.items.filter(({ fileId }) => !!fileId).length; // exklusive Platzhalter
                        existingArea.hasRequired = existingArea.items.some(({ isRequired }) => isRequired);
                        existingArea.isValid = existingArea.items.length > 0;
                    }
                }
            }
        }

        // sortierung der Haushalte wie in der Finanzierung
        const result: ISignatureSectionArea[] = [];

        for (let i = 0; i < householdIds.length; i++) {
            const houseHoldItems = householdSignatures.filter(({ referenceId }) => referenceId === householdIds[i]);

            const balanceSection = houseHoldItems.find(({ documentType }) => documentType === DocumentType.HouseholdBalanceSignature);
            if (!!balanceSection) {
                balanceSection.name = `${i + 1}. Haushalt: ${balanceSection.name}`
                result.push(balanceSection);
            }

            const alimenteSection = houseHoldItems.find(({ documentType }) => documentType === DocumentType.CompositionOfOtherIncomeSignature);
            if (!!alimenteSection) {
                alimenteSection.name = `${i + 1}. Haushalt: ${alimenteSection.name}`
                result.push(alimenteSection);
            }

            const selfDisclosureSection = houseHoldItems.find(({ documentType }) => documentType === DocumentType.SelfDisclosure);
            if (!!selfDisclosureSection) {
                selfDisclosureSection.name = `${i + 1}. Haushalt: ${selfDisclosureSection.name}`
                result.push(selfDisclosureSection);
            }

            const additionalSheetSection = houseHoldItems.find(({ documentType }) => documentType === DocumentType.AdditionalSheetSignature);
            if (!!additionalSheetSection) {
                additionalSheetSection.name = `${i + 1}. Haushalt: ${additionalSheetSection.name}`
                result.push(additionalSheetSection);
            }

            const debtorsOfHousehold = debtors.filter(({ householdId }) => householdId === householdIds[i]);
            const debtorItems = householdSignatures.filter(({ referenceId, documentType }) =>
                documentType === DocumentType.SelfDisclosure && debtorsOfHousehold.some(({ id }) => id === referenceId));

            for (const debtorItem of debtorItems) {
                const debIdx = debtors.findIndex(({ id }) => id === debtorItem.referenceId);
                const debtorLabel = HelperService.calcDebitorLabel(debtors[debIdx], debIdx, 'Kreditnehmer');
                debtorItem.name = `${debtorLabel}: ${debtorItem.name}`;
                result.push(debtorItem);
            }
        }

        householdSignatures = result;

        return [
            ...regularSignatures,
            ...householdSignatures,
        ];
    }


    /**
     * gibt eine Liste der Nachzureichenden Unterlagen Sections zurück
     *
     * @param {IFinancingMapStateModel} state aktueller State
     * @param {IRealEstateStateModel} realEstateState aktueller RealEstate State
     * @param {IDocumentStateModel} documentState aktueller Dokumenten State
     * @param {IFileStateModel} fileState aktueller File State
     * @returns {IRequiredSectionArea[]} Liste an Document Sections
     */
    @Selector([RealEstateState, DocumentState, FileState])
    public static requiredDoucmentSections(
        state: IFinancingMapStateModel,
        realEstateState: IRealEstateStateModel,
        documentState: IDocumentStateModel,
        fileState: IFileStateModel,
    ): IRequiredSectionArea[] {
        const financingMap = FinancingMapState.current(state);
        const documents = DocumentState.current(documentState);
        const realEstate = RealEstateState.currentFinancingObject(realEstateState);

        const validationMap = DOCUMENT_CARD_VALIDATION_MAP['requiredDocuments'];

        return validationMap.reduce<IRequiredSectionArea[]>((resultAreas, validation) => {

            if (!!financingMap && !!realEstate && Array.isArray(financingMap.requiredDocuments) && financingMap.requiredDocuments.includes(validation.type)) {
                const referenceObject: Record<string, string> = {
                    'financingMapId': financingMap.id,
                    'realEstateId': realEstate.id,
                };

                const documentsOfType = documents.filter(({ financingMapId, realEstateId, type }) => type === validation.type && (financingMapId === financingMap.id || realEstateId === realEstate.id));
                const filesOfFinancing = FileState.filesByDocumentIds(fileState)(documentsOfType.map(({ id }) => id));

                const fileItemsPerDocument = documentsOfType.reduce<IDocumentFileItem[]>((resultFiles, document) => {
                    // nur Unterschriebene Files werden im nachzureichenden Bereich dargestellt
                    const filesOfDocument = filesOfFinancing.filter(({ documentId, isSigned }) => documentId === document.id && isSigned);
                    const documentLabel = DocumentType.translate(validation.type) ?? '';

                    const fileItems = filesOfDocument.map<IDocumentFileItem>(file => ({
                        name: `${documentLabel} (${file.name}${file.extension})`,
                        documentType: document.type,
                        documentName: document.name ?? '',
                        documentId: document.id,
                        fileId: file.id,
                        isRequired: validation.required(financingMap),
                        disableActionButtons: !!file.sentToVPC,
                    }));

                    return resultFiles.concat(fileItems);
                }, []);


                const resultArea: IRequiredSectionArea = {
                    name: DocumentType.translate(validation.type) ?? '',
                    items: fileItemsPerDocument,
                    hasContent: fileItemsPerDocument.length > 0, // inkl Platzhalter
                    fileCount: fileItemsPerDocument.filter(({ fileId }) => !!fileId).length, // exklusive Platzhalter
                    hasRequired: fileItemsPerDocument.some(({ isRequired }) => isRequired),
                    areaId: HelperService.toHTMLid(financingMap.id) + validation.type,
                    area: DocumentArea.RequiredDocuments,
                    referenceObject,
                    documentType: validation.type,
                    referenceId: referenceObject[validation.referenceProperty],
                };

                return [...resultAreas, resultArea];
            }
            else {
                return resultAreas;
            }

        }, []);
    }


    /**
     * generiert aus eine Dokumenten und Fileliste ein File Item Array
     * 
     * @param {IDocumentModel[]} documets IDocumentModel Array
     * @param {IFileModel[]} files File Array
     * @param {IDocumentValidation} validation validierung für das Dokument
     * @param {string} itemid id der reference Entität
     * @param {string} namePrefix prefix für den angezeigten namen
     * @param {any[]} requiredParams optionale Parameter für den required check 
     * @returns {IDocumentFileItem[]} liste aus File Items
     */
    private static fileItemsFromDocuments(
        documets: IDocumentModel[],
        files: IFileModel[],
        validation: IDocumentValidation,
        itemid: string,
        namePrefix: string,
        ...requiredParams: unknown[]
    ): IDocumentFileItem[] {
        const documentLabel = DocumentType.translate(validation.type) ?? '';
        const fileItemsPerDocument = documets.reduce<IDocumentFileItem[]>((resultFiles, document) => {
            const filesOfDocument = files.filter(({ documentId }) => documentId === document.id);
            const fileItems = filesOfDocument.map<IDocumentFileItem>(file => ({
                name: `${namePrefix} ${documentLabel} (${file.name}${file.extension})`,
                documentType: document.type,
                documentName: document.name ?? '',
                documentId: document.id,
                fileId: file.id,
                isRequired: validation.required(...requiredParams),
                disableActionButtons: !!file.sentToVPC,
            }));

            return resultFiles.concat(fileItems);
        }, []);

        if (validation.required(...requiredParams) && fileItemsPerDocument.length === 0) {
            // Wenn Pflichtdokument noch nicht gesetzt, wird ein Platzhalter eingefügt
            fileItemsPerDocument.push({
                documentType: validation.type,
                documentName: '',
                name: `${namePrefix} ${documentLabel}`,
                referenceId: itemid,
                key: HelperService.toHTMLid(itemid) + validation.type,
                isRequired: true,
            });
        }

        return fileItemsPerDocument;
    }
}

