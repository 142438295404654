import { Validators } from '@angular/forms';
import { EmployeeStatus } from '@ntag-ef/finprocess-enums/finadvisory';

import { IValidationMap } from '../interfaces/validation-map.interface';
import { IDebtorModel } from '../models';
import { GlobalSettings } from '../utils';

import { DateValidation } from './date.validation';
import { IbanValidation } from './iban.validations';
import { NotEqualValidation } from './not-equal.validation';
import { PhoneNumberValidation } from './phone.validation';


/**
 * Prüft ob die Anstellungsfelder Pflichtfelder sind
 *
 * @param {IDebtorModel} debtor zu prüfender Kreditnehmer
 * @returns {boolean} sind Pflicht
 */
export const debtorProfessionFieldsRequired = (debtor: IDebtorModel): boolean => (
    debtor.employeeStatus === EmployeeStatus.Employed ||
    debtor.employeeStatus === EmployeeStatus.Worker ||
    debtor.employeeStatus === EmployeeStatus.PublicServant ||
    debtor.employeeStatus === EmployeeStatus.Probation ||
    debtor.employeeStatus === EmployeeStatus.UnpaidLeave
);

export const DEBTOR_FORM_VALIDATION_MAP: IValidationMap = {
    debtorModel: {
        id: {
            required: () => true,
            baseValidator: () => [],
        },
        householdId: {
            required: () => true,
            baseValidator: () => [],
        },
        gender: {
            required: () => true,
            excludeFromPrecheck: () => true,
            baseValidator: () => [],
        },
        title: {
            required: () => false,
            excludeFromPrecheck: () => true,
            baseValidator: () => [],
        },
        lastName: {
            required: () => true,
            excludeFromPrecheck: () => false,
            baseValidator: () => [Validators.minLength(2), Validators.maxLength(250)],
        },
        customerNumber: {
            required: () => true,
            excludeFromPrecheck: () => false,
            baseValidator: () => [Validators.maxLength(GlobalSettings.maxCustomerNumberLength), Validators.pattern(/^\d+$/)],
        },
        firstName: {
            required: () => true,
            excludeFromPrecheck: () => false,
            baseValidator: () => [Validators.minLength(2), Validators.maxLength(60)],
        },
        homeStreet: {
            required: () => true,
            excludeFromPrecheck: () => false,
            baseValidator: () => [Validators.minLength(3), Validators.maxLength(60)],
        },
        homeCity: {
            required: () => true,
            excludeFromPrecheck: () => false,
            baseValidator: () => [Validators.minLength(3), Validators.maxLength(60)],
        },
        homeZip: {
            required: () => true,
            excludeFromPrecheck: () => false,
            baseValidator: () => [Validators.minLength(4), Validators.maxLength(5), Validators.pattern(/^\d*$/)],
        },
        homeStreetNumber: {
            required: () => true,
            excludeFromPrecheck: () => false,
            baseValidator: () => [Validators.minLength(1), Validators.maxLength(10)],
        },
        homeStairway: {
            required: () => false,
            excludeFromPrecheck: () => true,
            baseValidator: () => [Validators.maxLength(80)],
        },
        homeTop: {
            required: () => false,
            excludeFromPrecheck: () => true,
            baseValidator: () => [Validators.maxLength(80)],
        },
        homeCountry: {
            required: () => true,
            excludeFromPrecheck: () => false,
            baseValidator: () => [Validators.maxLength(80)],
        },
        phoneNumber: {
            required: () => false,
            excludeFromPrecheck: () => true,
            baseValidator: () => [Validators.minLength(5), Validators.maxLength(60), PhoneNumberValidation.validPhoneFormat],
        },
        email: {
            required: () => true,
            excludeFromPrecheck: () => true,
            baseValidator: () => [Validators.minLength(3), Validators.maxLength(60), Validators.email],
        },
        mobileNumber: {
            required: () => true,
            excludeFromPrecheck: () => true,
            baseValidator: () => [Validators.minLength(5), Validators.maxLength(60), PhoneNumberValidation.validPhoneFormat],
        },
        birthday: {
            required: () => true,
            excludeFromPrecheck: () => false,
            baseValidator: () => [ DateValidation.legalAge],
        },
        birthcountry: {
            required: () => true,
            excludeFromPrecheck: () => true,
            baseValidator: () => [Validators.minLength(3), Validators.maxLength(60)],
        },
        birthcountryOther: {
            required: () => true,
            customIsSet: (debtor: IDebtorModel) => !debtor.birthcountry || debtor.birthcountry !== 'Sonstiges', // TODO
            excludeFromPrecheck: () => true,
            baseValidator: () => [Validators.minLength(3), Validators.maxLength(60)],
        },
        birthPlace: {
            required: () => true,
            excludeFromPrecheck: () => true,
            baseValidator: () => [Validators.minLength(3), Validators.maxLength(60)],
        },
        // Staatsbürgerschaft
        citizenship: {
            required: () => true,
            excludeFromPrecheck: () => true,
            baseValidator: (debtor: IDebtorModel & { additionalCitizenship: string }) => {
                const result = [
                    Validators.minLength(3),
                    Validators.maxLength(60),
                ];
                if (debtor.additionalCitizenship !== 'Sonstiges') {
                    result.push(NotEqualValidation.notEqual('weitere Staatsbürgerschaft', debtor.additionalCitizenship));
                }
                return result;
            },
        },
        // sonstige Staatsbürgerschaft
        citizenshipOther: {
            required: () => true,
            customIsSet: (debtor: IDebtorModel) => !debtor.citizenship || debtor.citizenship !== 'Sonstiges', // TODO
            excludeFromPrecheck: () => true,
            baseValidator: (debtor: IDebtorModel & { additionalCitizenship: string }) =>
                [
                    Validators.minLength(3),
                    Validators.maxLength(60),
                    NotEqualValidation.notEqual('sonstige weitere Staatsbürgerschaft', debtor.additionalCitizenship),
                ],
        },
        // weitere Staatsbürgerschaft
        additionalCitizenship: {
            required: () => false,
            excludeFromPrecheck: () => true,
            baseValidator: (debtor: IDebtorModel & { citizenship: string }) => {
                const result = [
                    Validators.minLength(3),
                    Validators.maxLength(60),
                ];
                if (debtor.citizenship !== 'Sonstiges') {
                    result.push(NotEqualValidation.notEqual('Staatsbürgerschaft', debtor.citizenship));
                }
                return result;
            },
        },
        // sonstige weitere Staatsbürgerschaft
        additionalCitizenshipOther: {
            required: (debtor: IDebtorModel) => !!debtor.additionalCitizenship || debtor.additionalCitizenship === 'Sonstiges', // TODO
            customIsSet: (debtor: IDebtorModel) => !debtor.additionalCitizenship || debtor.additionalCitizenship !== 'Sonstiges', // TODO
            excludeFromPrecheck: () => true,
            baseValidator: (debtor: IDebtorModel & { citizenship: string }) =>
                [
                    Validators.minLength(3),
                    Validators.maxLength(60),
                    NotEqualValidation.notEqual('sonstige Staatsbürgerschaft', debtor.citizenship),
                ],
        },
        tinNumber: {
            required: (debtor: IDebtorModel) => !!debtor.additionalCitizenship,
            excludeFromPrecheck: () => true,
            baseValidator: () => [Validators.pattern(/^\w*$/)],
        },
        housingType: {
            required: () => true,
            excludeFromPrecheck: () => true,
            baseValidator: () => [],
        },
        livingAtHomeAddressSince: {
            required: () => true,
            excludeFromPrecheck: () => true,
            baseValidator: () => [ DateValidation.dateInFuture],
        },
        taxResidence: {
            required: () => true,
            excludeFromPrecheck: () => true,
            baseValidator: () => [Validators.minLength(3), Validators.maxLength(60)],
        },
        taxResidenceOther: {
            required: () => true,
            customIsSet: (debtor: IDebtorModel) => !debtor.taxResidence || debtor.taxResidence !== 'Sonstiges', // TODO
            excludeFromPrecheck: () => true,
            baseValidator: () => [Validators.minLength(3), Validators.maxLength(60)],
        },
        maritalStatus: {
            required: () => true,
            excludeFromPrecheck: () => true,
            baseValidator: () => [],
        },
        childrenCount: {
            required: () => true,
            excludeFromPrecheck: () => true,
            baseValidator: () => [Validators.min(0), Validators.max(99), Validators.pattern(/^\d+$/)],
        },
        dependentPersonsCount: {
            required: () => true,
            excludeFromPrecheck: () => true,
            baseValidator: () => [Validators.min(0), Validators.max(9), Validators.pattern(/^\d+$/)],
        },
        levelOfTraining: {
            required: () => true,
            excludeFromPrecheck: () => true,
            baseValidator: () => [],
        },
        employeeStatus: {
            required: () => true,
            excludeFromPrecheck: () => true,
            baseValidator: () => [],
        },
        profession: {
            required: debtorProfessionFieldsRequired,
            excludeFromPrecheck: () => true,
            baseValidator: () => [Validators.minLength(3), Validators.maxLength(60)],
        },
        employerName: {
            required: debtorProfessionFieldsRequired,
            excludeFromPrecheck: () => true,
            baseValidator: () => [Validators.maxLength(60)],
        },
        employedSince: {
            required: debtorProfessionFieldsRequired,
            excludeFromPrecheck: () => true,
            baseValidator: () => [ DateValidation.dateInFuture],
        },
        noCareers: {
            required: () => false,
            baseValidator: () => [],
        },
        vehicleCount: {
            required: () => true,
            baseValidator: () => [Validators.min(0), Validators.max(1000), Validators.pattern(/^\d+$/)],
        },
        // lineType: {
        //     required: (debtor: IDebtorModel) => HelperService.getValue(debtor.vehicleCount, 0) > 0,
        //     baseValidator: () => [],
        // },
        bankAccount: {
            required: () => false,
            excludeFromPrecheck: () => true,
            baseValidator: () => [Validators.minLength(3), Validators.maxLength(60)],
        },
        bankAccountOther: {
            required: () => true,
            customIsSet: (debtor: IDebtorModel) => !debtor.bankAccount || debtor.bankAccount !== 'Sonstiges', // TODO
            excludeFromPrecheck: () => true,
            baseValidator: () => [Validators.minLength(3), Validators.maxLength(60)],
        },
        iban: {
            required: () => false,
            baseValidator: () => [IbanValidation.valid],
        },
        bankAustriaStatus: {
            required: () => false,
            baseValidator: () => [],
        },
        notesPerson: {
            required: () => false,
            baseValidator: () => [Validators.minLength(1), Validators.maxLength(4000)],
        },
        careers: {
            required: () => false,
            baseValidator: () => [],
        },
    },
    // careerModel: {
    //     id: {
    //         required: () => true,
    //         baseValidator: () => [],
    //     },
    //     debtorId: {
    //         required: () => true,
    //         excludeFromPrecheck: () => true,
    //         baseValidator: () => [],
    //     },
    //     from: {
    //         required: () => true,
    //         excludeFromPrecheck: () => true,
    //         baseValidator: (career: CareerModel) => {
    //             const result = [DateValidation.format];
    //             if (!!career.to) {
    //                 const regex: RegExp = /\d{2}\.\d{2}\.\d{4}/;
    //                 // "to" date is in Format DD.MM.YYYY if its from formular
    //                 if (regex.test(career.to)) {
    //                     result.push(DateValidation.maxDate(HelperService.parseDatepickerDateGermanPattern(career.to)));
    //                 }
    //                 else {
    //                     result.push(DateValidation.maxDate(new Date(career.to)));
    //                 }
    //             }
    //             return result;
    //         },
    //     },
    //     to: {
    //         required: () => false,
    //         baseValidator: (career: CareerModel) => {
    //             const result = [DateValidation.format];
    //             if (!!career.from) {
    //                 const regex: RegExp = /\d{2}\.\d{2}\.\d{4}/;
    //                 // "from" date is in Format DD.MM.YYYY if its from formular
    //                 if (regex.test(career.from)) {
    //                     result.push(DateValidation.minDate(HelperService.parseDatepickerDateGermanPattern(career.from)));
    //                 }
    //                 else {
    //                     result.push(DateValidation.minDate(new Date(career.from)));
    //                 }
    //             }
    //             return result;
    //         },
    //     },
    //     work: {
    //         required: () => true,
    //         excludeFromPrecheck: () => true,
    //         baseValidator: () => [],
    //     },
    //     branch: {
    //         required: () => true,
    //         excludeFromPrecheck: () => true,
    //         baseValidator: () => [],
    //     },
    //     notes: {
    //         required: () => false,
    //         baseValidator: () => [],
    //     },
    // },
};
